import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {combineReducers, createStore, applyMiddleware} from "redux";
import {Provider, useSelector} from "react-redux";
import ReduxThunk from 'redux-thunk';

import './css/App.css';

import MainReducer from './store/reducers/reducer';
import Login from "./pages/Login";
import Home from "./pages/Home";
import CreateMenuPreview from "./pages/CreateMenuPreview";
import BarsList from "./pages/BarsList";
import ProductsList from "./pages/ProductsList";
import MenusList from "./pages/MenusList";
import Notifications from "./pages/Notifications";
import MenuSuccessfullyCreated from "./pages/MenuSuccessfullyCreated";
import ImportUsers from "./pages/ImportUsers";
import CreateComboOffer from "./pages/CreateComboOffer"
import Product from "./pages/Product";
import Bar from "./pages/Bar";
import CreateMenu from "./pages/CreateMenu";
import CreateMenuChooseBackground from "./pages/CreateMenuChooseBackground";
import CreateMenuAddCategories from "./pages/CreateMenuAddCategories";
import EditPublishedMenu from "./pages/EditPublishedMenu";
import CreateMenuAddProducts from "./pages/CreateMenuAddProducts";
import CreateMenuAddComboOffers from "./pages/CreateMenuAddComboOffers";
import LiveDigitalMenu from "./pages/LiveDigitalMenu";
import CreateAd from "./pages/CreateAd";
import AdsList from "./pages/AdsList";
import Ad from "./pages/Ad";
import MenusListLocal from "./pages/MenusListLocal";
import UsersList from "./pages/UsersList";
import User from "./pages/User";
import CategoriesList from "./pages/CategoriesList";
import Category from "./pages/Category";
import LiveDigitalMenuVote from "./pages/LiveDigitalMenuVote";
import Statistics from "./pages/Statistics";
import StatisticsAds from "./pages/StatisticsAds";
import StatisticsProducts from "./pages/StatisticsProducts";


export const PAGES = {
    LOGIN: '/prijava',
    HOME: '/domov',
    CREATE_MENU: '/ustvari-cenik',
    CREATE_MENU_CHOOSE_BACKGROUND: '/ustvari-cenik-izbira-ozadja',
    CREATE_MENU_CHOOSE_CATEGORIES: '/ustvari-cenik-izbira-kategorij',
    CREATE_MENU_ADD_PRODUCTS: '/ustvari-cenik-izbira-ponudbe',
    CREATE_MENU_CHOOSE_COMBO_OFFER: '/ustvari-cenik-izbira-posebne-ponudbe',
    CREATE_MENU_PREVIEW: '/ustvari-cenik-predogled',
    CREATE_MENU_SUCCESSFUL: '/ustvari-cenik-poslano',
    USERS_LIST: '/pregled-uporabnikov',
    BARS_LIST: '/pregled-lokalov',
    PRODUCTS_LIST: '/pregled-izdelkov',
    CATEGORIES_LIST: '/pregled-kategorij',
    MENUS_LIST: '/pregled-cenikov',
    MENUS_LIST_LOCAL: '/neoddani-ceniki',
    NOTIFICATIONS: '/obvestila',
    IMPORT_FILE: '/uvozi-datoteko',
    CREATE_COMBO_OFFER: '/ustvari-combo-ponudbo',
    EDIT_PRODUCT: '/izdelek',
    EDIT_CATEGORY: '/kategorija',
    EDIT_BAR: '/lokal',
    EDIT_USER: '/uporabnik',
    EDIT_PUBLISHED_MENU: '/uredi-cenik',
    CREATE_AD: '/ustvari-oglas',
    ADS_LIST: '/pregled-oglasov',
    EDIT_AD: '/oglas',
    STATS: '/statistika',
    STATS_ADS: '/statistika-oglasov',
    STATS_PRODUCTS: '/statistika-izdelkov',
    ONLINE_MENU: '/cenik',
    ONLINE_MENU_VOTE: '/glasuj',
}

function App() {

    const rootReducer = combineReducers({
        reducer: MainReducer
    });
    const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

    return (
        <>
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Route path={PAGES.ONLINE_MENU + '/:id'} component={LiveDigitalMenu}/>
                        <Route path={'/'} exact component={Login}/>
                        <Route path={PAGES.ONLINE_MENU_VOTE + '/:id'} component={LiveDigitalMenuVote}/>
                        <Route path={PAGES.LOGIN} exact component={Login}/>
                        <Route path={PAGES.HOME} component={Home}/>
                        <Route path={PAGES.CREATE_MENU} component={CreateMenu}/>
                        <Route path={PAGES.CREATE_MENU_CHOOSE_BACKGROUND} component={CreateMenuChooseBackground}/>
                        <Route path={PAGES.CREATE_MENU_CHOOSE_CATEGORIES} component={CreateMenuAddCategories}/>
                        <Route path={PAGES.CREATE_MENU_ADD_PRODUCTS} component={CreateMenuAddProducts}/>
                        <Route path={PAGES.CREATE_MENU_CHOOSE_COMBO_OFFER} component={CreateMenuAddComboOffers}/>
                        <Route path={PAGES.CREATE_MENU_PREVIEW} component={CreateMenuPreview}/>
                        <Route path={PAGES.CREATE_MENU_SUCCESSFUL} component={MenuSuccessfullyCreated}/>
                        <Route path={PAGES.BARS_LIST} component={BarsList}/>
                        <Route path={PAGES.PRODUCTS_LIST} component={ProductsList}/>
                        <Route path={PAGES.CATEGORIES_LIST} component={CategoriesList}/>
                        <Route path={PAGES.USERS_LIST} component={UsersList}/>
                        <Route path={PAGES.MENUS_LIST} component={MenusList}/>
                        <Route path={PAGES.MENUS_LIST_LOCAL} component={MenusListLocal}/>
                        <Route path={PAGES.ADS_LIST} component={AdsList}/>
                        <Route path={PAGES.NOTIFICATIONS} component={Notifications}/>
                        <Route path={PAGES.IMPORT_FILE} component={ImportUsers}/>
                        <Route path={PAGES.CREATE_COMBO_OFFER} component={CreateComboOffer}/>
                        <Route path={PAGES.CREATE_AD} component={CreateAd}/>
                        <Route path={PAGES.EDIT_PRODUCT} component={Product}/>
                        <Route path={PAGES.EDIT_CATEGORY} component={Category}/>
                        <Route path={PAGES.EDIT_BAR} component={Bar}/>
                        <Route path={PAGES.EDIT_USER} component={User}/>
                        <Route path={PAGES.EDIT_PUBLISHED_MENU} component={EditPublishedMenu}/>
                        <Route path={PAGES.EDIT_AD} component={Ad}/>
                        <Route path={PAGES.STATS} component={Statistics}/>
                        <Route path={PAGES.STATS_ADS} component={StatisticsAds}/>
                        <Route path={PAGES.STATS_PRODUCTS} component={StatisticsProducts}/>
                    </Switch>
                </Router>
            </Provider>
        </>
    );
}

export default App;
