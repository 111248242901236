import React, {useState, useEffect, createRef, useCallback} from "react";
import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import FileUpload from "../components/js/FileUpload";
import Input from "../components/js/Input";

import "../css/ProductBarEdit.css"
import "../css/Ads.css"
import * as CgIcons from "react-icons/cg";

import {useDispatch, useSelector} from "react-redux";
import {displayAlert, hasItem, isSessionValid, mapCaretChars, parseMapCaretChars} from "../helpers/helpers";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import {
    addAllBarsToAd,
    addBarToAd,
    createNewAd,
    loadAds,
    loadBarsForAd, removeAllBarsFromAd,
    removeBarFromAd,
    setCreateMenuActive, setSidebarWidth,
    updateAd,
    updateAdActiveStatus,

} from "../store/actions/actions";
import {useHistory} from "react-router-dom";
import Dropdown from "../components/js/Dropdown";
import Masonry from "react-responsive-masonry";
import * as AiIcons from "react-icons/ai";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";

// in pixels as integer
export const BANNER_WIDTH = 350;

function Ad(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let selectedAd = useSelector(state => state.reducer.selectedAd);
    let addedBars = useSelector(state => state.reducer.selectedAdAddedBars);
    let bars = useSelector(state => state.reducer.bars);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    const [image, setImage] = useState(selectedAd.image);
    const [name, setName] = useState(null);
    const [link, setLink] = useState(null);

    const [addBar, setAddBar] = useState(null);
    const [removeBar, setRemoveBar] = useState(null);

    const [addAllBars, setAddAllBars] = useState(null);
    const [removeAllBars, setRemoveAllBars] = useState(null);

    useEffect(() => {
        if (addAllBars) {
            const asyncEffect = async () => {
                let postData = [];
                for (let i = 0; i < bars.length; i++) {
                    postData.push({ad_id: selectedAd.ad_id, bar_id: bars[i].bar_id});
                }
                await dispatch(addAllBarsToAd(postData), () => {
                    console.log("All bars added to ad!");
                });
            }
            asyncEffect().then(() => setAddAllBars(false));
        }
    }, [addAllBars]);

    useEffect(() => {
        if (removeAllBars) {
            dispatch(removeAllBarsFromAd(selectedAd.ad_id), () => {
                console.log("All bars removed from ad:");
            });
            setRemoveAllBars(false);
        }

    }, [removeAllBars]);

    useEffect(() => {
        if (addBar) {
            let postData = {ad_id: selectedAd.ad_id, bar_id: addBar.bar_id}
            dispatch(addBarToAd(postData), () => {
                console.log("Bar added to ad:", addBar);
            });
        }
    }, [addBar]);

    useEffect(() => {
        if (removeBar) {
            let postData = {ad_id: selectedAd.ad_id, bar_id: removeBar.bar_id}
            dispatch(removeBarFromAd(postData), () => {
                console.log("Bar removed from ad:", removeBar);
            });
        }
    }, [removeBar]);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    const onDeactivateAd = () => {
        console.log("DEACTIVATE AD!");
        selectedAd.active = false;
        dispatch(updateAdActiveStatus(selectedAd, () => {
            displayAlert("Oglas uspešno deaktiviran!")
            onNavigateTo(PAGES.ADS_LIST);
            console.log("Ad successfully deactivated!");
        }, () => {
            console.warn("Error deactivating ad..")
        }));
    };

    useEffect(() => {
        dispatch(loadBarsForAd(selectedAd.ad_id));
        dispatch(setCreateMenuActive(null));
    }, []);

    useEffect(() => {
        if (selectedAd && image) {
            if (image === 'REMOVE') {
                selectedAd.image = null;
                setImage(null);
            } else {
                selectedAd.image = image;
            }
            console.log("AD DATA LOADED..:", selectedAd);
            updateAdDispatch(selectedAd);
        }
    }, [image]);

    const updateAdDispatch = (ad) => {
        dispatch(updateAd(ad, () => {
            console.log("Ad successfully updated!");
        }, () => {
            console.warn("Error updating ad..")
        }));
    }

    const handleBannerUpload = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.addEventListener("load", function () {
                console.log("load");
                setImage(reader.result);
            }, false);
            reader.readAsDataURL(file);
        }
    };

    const previewImage = (base64Data) => {
        return <div className={'img-banner-container'}>
            <img className={'img-banner-upload'} src={`${base64Data}`} alt="banner"
                 width={`${BANNER_WIDTH}px`}/>
            <CgIcons.CgCloseO onClick={() => {
                setImage("REMOVE");
            }} className={'icon-close'}/>
        </div>
    };

    const renderFileUpload = (title) => {
        return <><p className={'p-title-small'}>{title}</p>
            <FileUpload comboOffer button={'Izberi in naloži sliko'}
                        handleFile={(file) => handleBannerUpload(file)}/>
            {image && previewImage(image)}
        </>
    };

    let inputStyle = {marginBottom: '48px'};

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page relative ad className={'main'} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            {/*<Sidebar/>*/}
            {/*<div className={'page ad relative'}>*/}
                <div className={'body'} style={{paddingLeft: `${sidebarWidth + 50}px`}}>
                    <Header left={`${sidebarWidth + 50}px`} title={`PREGLED OGLASOV / ${name}`} interactive delete button={'Deaktiviraj oglas'}
                            action={onDeactivateAd}/>
                    <div className={'container-column-2'}>
                        <div className={'column-2'}>
                            <Input white shadow loadAd name onChange={setName} placeholder={''}
                                   disabled
                                   label={'Ime oglasa'}
                                   style={inputStyle}/>
                            <Input white loadAd link onChange={setLink} placeholder={''} label={'Link (url)'}
                                   style={inputStyle}
                                   onBlur={(e) => {
                                       console.log('Triggered because this input lost focus', e.target.value);
                                       if (selectedAd && link) {
                                           selectedAd.link = link;
                                           updateAdDispatch(selectedAd);
                                       }
                                   }}/>
                            {renderFileUpload('Slika oglasa')}

                        </div>
                        <div className={'column-2'} style={{paddingRight: '48px'}}>
                            <Dropdown loadBarsAd label={'Seznam lokalov'} onChange={setAddBar}
                                      placeholder={'Dodelite lokale ustvarjenemu oglasu'}/>
                            <div className={'add-bars-to-ad-container'}>
                                <a className={'add-bars'} onClick={() => setAddAllBars(true)}>dodaj vse</a>
                                <a className={'remove-bars'} onClick={() => setRemoveAllBars(true)}>odstrani vse</a>
                            </div>
                            <div className={'ad-bars-list'}>
                                {addedBars.map((bar, b) => (
                                    <div key={`${b}_${bar}`}
                                         className={'bar-list-item'}
                                         onDoubleClick={() => console.log("remove item", bar)}>
                                        <p className={'p-title-product'}>{parseMapCaretChars(bar.name)}</p>
                                        <div className={'container-icon-selected'} style={{marginLeft: '0px'}}>
                                            <div className={'remove-bar-icon'} onClick={() => {
                                                setRemoveBar(bar)
                                            }} style={{cursor: 'pointer'}}>
                                                <AiIcons.AiFillDelete/>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            {/*</div>*/}
        </Page>
        </div>
}

export default Ad;