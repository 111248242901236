import React, {useState, useEffect} from "react";

import '../css/Home.css';
import '../css/Notifications.css'

import {useDispatch, useSelector} from "react-redux";

import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import Footer from "../components/js/Footer";
import Notification from "../components/js/Notification";
import {ReactComponent as IconNotification} from "../assets/svg/Icon material-notifications-none.svg";
import {ReactComponent as IconList} from "../assets/svg/Icon awesome-list-ul.svg";
import {ReactComponent as IconEdit} from "../assets/svg/Icon feather-edit.svg";
import {
    loadAdminAllNotifications,
    loadNotifications,
    loadProducts, previewMenu, previewMenuParseOnBE,
    setCreateMenuActive, setSidebarWidth
} from "../store/actions/actions";
import {
    displayAlert,
    isSessionValid,
    isUserAdmin,
    isUserBarRepresentative,
    isUserCaretaker, isUserOwner,
    parseUserRole
} from "../helpers/helpers";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import * as ReactDOM from "react-dom";

export const NotificationType = {
    INTERACTIVE: 0,
    DISPLAY_ONLY: 1
}

function Notifications(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    const user = useSelector(state => state.reducer.user);
    const userCaretaker = useSelector(state => state.reducer.userCaretaker);
    const notifications = useSelector(state => state.reducer.notifications);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);


    const [loadData, setLoadData] = useState(false);

    const onLogout = () => {
    };

    useEffect(() => {
       // document.documentElement.scrollTop = document.body.scrollTop = 1080;
        //document.documentElement.scrollHeight = 1000;
        if (!loadData) {
          //  document.documentElement.scrollHeight = 0;
            setLoadData(true);
        }

        dispatch(setCreateMenuActive(null));
    }, []);

    console.log("sasa", userCaretaker);

    useEffect(() => {
        console.log(user)
        console.log(userCaretaker)
        if (userCaretaker) {

            if (isUserAdmin(user)) {
                dispatch(loadAdminAllNotifications(userCaretaker['caretaker_id'], () => {
                }, () => displayAlert('Napaka pri pridobivanju seznama obvestil. Prosimo poskusite znova..')))
            } else if (isUserCaretaker(user) || isUserBarRepresentative(user) || isUserOwner(user)) {
                dispatch(loadNotifications(userCaretaker['caretaker_id'], () => {
                }, () => displayAlert('Napaka pri pridobivanju seznama obvestil. Prosimo poskusite znova..')))
            }
        }
    }, [userCaretaker]);

    useEffect(() => {
        console.log("Notifications:", notifications)
    }, [notifications]);

    function renderNotifications(items) {
        if (!items) {
            return;
        }
        return (
            <>
                <ul className={'notifications-list'} style={{left: `${sidebarWidth + 50}px`}}>
                    {items.map((item, index) => <Notification key={index} value={items[items.length - 1 - index]}/>)}
                </ul>
            </>
        );
    }
    const [mainHeight, setMainHeight] = useState(null);
    useEffect(() => {
        setTimeout(() => {
            let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
            setMainHeight(documentSize.y + 600);
        }, 350)
    }, [])

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page className={'main'} style={{height: `${mainHeight}px`}} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            {/*<Sidebar/>*/}
            {/*<div className={'page notifications'}>*/}
                <Header left={`${sidebarWidth + 50}px`} title={'OBVESTILA'}/>
                <div className={'body'}>
                    {renderNotifications(notifications)}
                </div>
            </Page>
        </div>
}

export default Notifications;

