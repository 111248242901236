import React, {useState, useEffect, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

import '../css/Notification.css';
import Button from "./Button";
import {
    displayAlert,
    displayDate,
    isUserAdmin, isUserBarRepresentative,
    isUserCaretaker, isUserOwner,
    parseMapCaretChars,
    parseUserRole
} from "../../helpers/helpers";
import {FORMAT, NOTIFICATION_STATUS, USER_ROLES} from "../../constants/enums";
import {
    getBarByBarId, getMenuByMenuId, loadBarByMenuId,
    loadCaretaker, loadMenu,
    loadNotifications, loadUser,
    previewMenuParseOnBE,
    respondToNotification,
    setActiveBar, setActiveBarOwner, setActiveBarRepresentative
} from "../../store/actions/actions";
import Input from "./Input";
import {useHistory} from "react-router-dom";
import {PAGES} from "../../App";

function Notification(props) {
    const item = props.value;
    let dispatch = useDispatch();

    const user = useSelector(state => state.reducer.user);
    const userCaretaker = useSelector(state => state.reducer.userCaretaker);

    const [barData, setBarData] = useState(null);
    const [comment, setComment] = useState();
    const [triggerPreviewMenu, setTriggerPreviewMenu] = useState(false);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    useEffect(() => {
        if (userCaretaker) {
            console.log(userCaretaker);
        }
    }, [userCaretaker])

    useEffect(() => {
        if (props.value && !barData) {
            dispatch(loadBarByMenuId(item.menu_id, (bar) => {
                setBarData(bar)
            }, (err) => {
                console.log(err);
            }))
        }
    }, [props.value])

    useEffect(() => {
        let done = false;
        const asyncEffect = async () => {
            if (triggerPreviewMenu) {
                dispatch(onPreviewMenu);
                setTriggerPreviewMenu(false);
            }
        }
        asyncEffect().then(() => console.log(done && "done loading pdf menu."));
    }, [triggerPreviewMenu]);

    /*
    const onInputChange = (value) => {
        const {onChange} = props;

        onChange(value);
    }
     */


    const onPreviewMenu = async () => {
        let menuPostData = {menu_id: item.menu_id}
        console.log("PREVIEW MENU with menu_id:", menuPostData);

        let prevMenu = null;
        dispatch(getMenuByMenuId(item.menu_id, async (menu) => {
            console.log(menu);
            prevMenu = menu;

            if (prevMenu.beer_cider_menu) {
                let format = "a4";
                await dispatch(previewMenuParseOnBE(menuPostData, format, () => {
                    console.log("Menu successfully generated!")
                }, (e) => {
                    throw new Error("Error previewing menu! " + e);
                }));
            } else {
                for (let i = 0; i < FORMAT.length; i++) {
                    // skip a4 beer&cider card from normal menus preview
                    let format = FORMAT[i];
                    if (format === "a4") {
                        continue;
                    }
                    await dispatch(previewMenuParseOnBE(menuPostData, format, () => {
                        console.log("Menu successfully generated!")
                    }, (e) => {
                        throw new Error("Error previewing menu! " + e);
                    }));
                }
            }
        }));
    };

    console.log("USER", user)

    const onSuccessAction = (message) => {
        dispatch(loadNotifications(userCaretaker.caretaker_id, () => {
            displayAlert(message)
        }, () => {
            displayAlert("Napaka pri komuniciranju s strežnikom.. Prosimo osvežite stran in poskusite znova..")
        }));
    };

    const onConfirm = () => {
        item.to_id = userCaretaker.caretaker_id;
        item.response = isUserAdmin(user) ? 'Meni potrjen in poslan v tisk!\n' : 'Meni potrjen in poslan adminu v pregled!\n';
        item.response += ' | ' + (comment ? comment : '')
        item.status = NOTIFICATION_STATUS.CONFIRMED;
        dispatch(respondToNotification(item, user, () => {
            onSuccessAction("Meni uspešno potrjen in poslan v tisk :)")
        }, () => {
            displayAlert("Napaka pri komuniciranju s strežnikom.. Prosimo osvežite stran in poskusite znova..")
        }));
    };
    const onReject = () => {
        item.to_id = userCaretaker.caretaker_id;
        item.response = 'Meni zavrnjen!\n';
        item.response += ' | ' + (comment ? comment : '')
        item.status = NOTIFICATION_STATUS.REJECTED;
        dispatch(respondToNotification(item, user, () => {
            onSuccessAction("Meni uspešno zavrnjen!")
        }, () => {
            displayAlert("Napaka pri komuniciranju s strežnikom.. Prosimo osvežite stran in poskusite znova..")
        }));
    };
    const onEditMenu = () => {
        console.log(item);
        dispatch(getMenuByMenuId(item.menu_id, (menu) => {
            console.log(menu);
            dispatch(getBarByBarId(menu.bar_id, (bar) => {
                navigateAndLoadSelectedBar(bar, bar.user_id, bar.caretaker_id, bar.bar_owner_id, () => {
                    onNavigateTo(PAGES.MENUS_LIST);
                    setTimeout(() => navigateAndLoadEditMenu(PAGES.CREATE_MENU, menu), 0);
                });
            }));
        }));
    };
    const navigateAndLoadEditMenu = (path, menu) => {
        dispatch(loadMenu(menu, () => {
            console.log("Menu loaded successfully!");
            onNavigateTo(path);
        }, (e) => {
            console.log(e)
        }));
    };
    const navigateAndLoadSelectedBar = (bar, barUserId, caretakerId, ownerCaretakerId, onSuccess) => {
        dispatch(setActiveBar(bar));
        //dispatchLoadCaretaker();
        dispatch(loadUser(barUserId, USER_ROLES.BAR_REPRESENTATIVE, () => {
            console.log("Bar representative loaded..")

            dispatch(loadCaretaker(caretakerId, USER_ROLES.CARETAKER, () => {
                console.log("Caretaker loaded..");
                //dispatchLoadCaretaker();

                dispatch(loadCaretaker(ownerCaretakerId, USER_ROLES.OWNER, () => {
                    console.log("Caretaker owner loaded..");
                    //dispatchLoadCaretaker();
                    onSuccess();

                }, () => {
                    //dispatchLoadCaretaker();
                    dispatch(setActiveBarOwner(null));
                    onSuccess();
                    console.log("Caretaker owner loading failed..")
                }));

                //onNavigateTo(path);
            }, () => {
                //  dispatchLoadCaretaker();

                dispatch(loadCaretaker(ownerCaretakerId, USER_ROLES.OWNER, () => {
                    console.log("Caretaker owner loaded..");
                    //dispatchLoadCaretaker();
                    onSuccess();
                }, () => {
                    //dispatchLoadCaretaker();
                    dispatch(setActiveBarOwner(null));
                    onSuccess();
                    console.log("Caretaker owner loading failed..")
                }));

                // onNavigateTo(path);
                console.log("Caretaker loading failed..")
            }));

        }, () => {
            dispatch(setActiveBarRepresentative(null));
            console.log("Bar representative loading failed..")
            onSuccess();
        }));
    };

    const renderInteractiveNotification = () => {
        if (isUserAdmin(user)) {
            return <>
                <a onClick={() => setTriggerPreviewMenu(true)}>predogled cenika</a>
                <Input onSubmit={() => {
                }} textarea
                       style={{
                           background: '#F0F0F0',
                           minHeight: '80px',
                           height: '80px',
                           maxHeight: '80px',
                           minWidth: '94%',
                           maxWidth: '94%',
                           width: 'fit-content',
                           margin: '20px',
                           marginBottom: '-10px',
                           marginTop: '30px'
                       }}
                       onChange={setComment} placeholder={'Sporočilo'}/>
                <div className={'buttons-container'}>
                    <Button text={'ZAVRNI'} reject action={onReject}/>
                    <Button text={'POTRDI'} confirm action={onConfirm}/>
                </div>
            </>
        } else if (isUserCaretaker(user)) {
            return <>
                <a onClick={() => setTriggerPreviewMenu(true)}>predogled cenika</a>
                <Input onSubmit={() => {
                }} textarea
                       style={{
                           background: '#F0F0F0',
                           minHeight: '80px',
                           height: '80px',
                           maxHeight: '80px',
                           minWidth: '94%',
                           maxWidth: '94%',
                           width: 'fit-content',
                           margin: '20px',
                           marginBottom: '-10px',
                           marginTop: '30px'
                       }}
                       onChange={setComment} placeholder={'Sporočilo'}/>
                <div className={'buttons-container'}>
                    <Button text={'ZAVRNI'} reject action={onReject}/>
                    <Button text={'POTRDI'} confirm action={onConfirm}/>
                </div>
                <div className={'buttons-container'}>
                    <Button text={'UREDI CENIK'} confirm action={onEditMenu}/>
                </div>
            </>
        } else {
            return <>
                <div className={'buttons-container'}>
                    <Button text={'PREDOGLED CENIKA'} reject action={() => setTriggerPreviewMenu(true)}/>
                    <Button text={'UREDI CENIK'} confirm action={onEditMenu}/>
                </div>
            </>
        }
    };

    console.log("NOTIFICATION:", item);
    if (!user) {
        return null;
    }
    if (isUserAdmin(user) && item.status !== NOTIFICATION_STATUS.PENDING) {
        return null;
    }
    if (isUserAdmin(user) && (item.responsibility !== null && Number(item.responsibility) !== USER_ROLES.ADMIN)) {
        return null;
    }
    if (isUserCaretaker(user) && item.from_id === userCaretaker.caretaker_id) {
        return null;
    }
    if (isUserOwner(user) && item.from_id === userCaretaker.caretaker_id) {
        return null;
    }
    if (isUserBarRepresentative(user) && item.from_id === userCaretaker.caretaker_id) {
        return null;
    }
    /*else if (parseUserRole(user.type) === USER_ROLES.ADMIN && item.status !== NOTIFICATION_STATUS.PENDING) {

    }
     */

    let message = isUserCaretaker(user) ? parseMapCaretChars(item.response) : parseMapCaretChars(item.content);
    message = isUserCaretaker(user) === item.from_id ?  parseMapCaretChars(item.content) : parseMapCaretChars(item.response);
    if (isUserCaretaker(user) && item.origin_caretaker_id === item.from_id) {
        message = parseMapCaretChars(item.content);
    } else if (isUserCaretaker(user) && item.origin_caretaker_id !== item.from_id) {
        message = parseMapCaretChars(item.response);
    } else if (isUserAdmin(user)) {
        message = parseMapCaretChars(item.content);
    } else if (isUserBarRepresentative(user) || isUserOwner(user)) {
        message = parseMapCaretChars(item.response);
    }

    //console.log("NOTIFICATION:", item);
    return (
        <div className={'notification'}>
            <div className={'notification-header'}>
                <p className={'notification-title'}>{parseMapCaretChars(item.name)}</p>
                <p className={'notification-date'}>{displayDate(item.date_modify)}</p>
            </div>
            <div className={'notification-body'}>
                <p className={'notification-title'}>{parseMapCaretChars(barData?.name)}</p>
                <p className={'notification-message'}>{parseMapCaretChars(message)}</p>
                {renderInteractiveNotification()}
            </div>
        </div>
    );
}

export default Notification;