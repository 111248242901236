import React, {useState, useEffect, createRef, useCallback} from "react";
import {ReactComponent as IconPalm} from "../assets/svg/icon-phone-palm.svg";
import {ReactComponent as IconDrink} from "../assets/svg/icon-drink.svg";

import Header from "../components/js/Header";
import Input from "../components/js/Input";

import "../css/ProductBarEdit.css"
import "../css/Ads.css"

import {useDispatch, useSelector} from "react-redux";
import {displayAlert, hasItem, isSessionValid, mapCaretChars, parseMapCaretChars} from "../helpers/helpers";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import {
    loadBars, setActiveBar,
    setCreateMenuActive, setSidebarWidth,

} from "../store/actions/actions";
import {useHistory} from "react-router-dom";
import Dropdown from "../components/js/Dropdown";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import {ReactComponent as IconDelete} from "../assets/svg/Icon feather-trash.svg";

// in pixels as integer
export const BANNER_WIDTH = 350;

export const sumAllQrBarsScans = (bars) => {
    let c = 0;
    for (let i = 0; i < bars.length; i++) {
        let bar = bars[i];
        if (bar?.scans_counter) {
            c += bar.scans_counter;
        }
    }
    return c;
}

function Statistics(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let user = useSelector(state => state.reducer.user);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    let bars = useSelector(state => state.reducer.bars);
    const [barChosen, setBarChosen] = useState(null);

    const [qrCodesScanned, setQrCodesScanned] = useState(null);

    const [link, setLink] = useState(null);

    const [addAllBars, setAddAllBars] = useState(null);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);


    useEffect(() => {
        window.statistics_page_open = true;
        dispatch(setCreateMenuActive(null));

        return () => {
            window.statistics_page_open = false;
        };
    }, []);

    useEffect(() => {
        if (bars) {
            console.log("Bars loaded");
            let allScans = sumAllQrBarsScans(bars);
            setQrCodesScanned(allScans);
        }
    }, [bars]);

    useEffect(() => {
        let done = false;
        const asyncEffect = async () => {
            if (user) {
                await dispatch(loadBars(user.user_id, () => {
                    console.log("Bars successfully loaded..");
                    done = true;
                }, () => {
                    console.warn("Error loading bars..")
                }));
            }
        }
        asyncEffect().then(() => console.log(done && "done loading data."));
    }, [user]);

    useEffect(() => {
        if (barChosen) {
            //dispatch(setActiveBar(barChosen));
            console.log("Bar chosen", barChosen);

        }
    }, [barChosen]);

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page relative ad className={'main'} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            <div className={'body'} style={{paddingLeft: `${sidebarWidth + 50}px`}}>
                <Header left={`${sidebarWidth + 50}px`} title={`STATISTIKA / OSNOVNA`}/>
                <div className={'container-column-2'}>
                    <div className={'column-2'} style={{width: '40%'}}>
                        <p className={'p-title-small'}>{'Število vseh skeniranih QR kod'}</p>
                        <div className={'stats-card'}>
                            <IconPalm className={'icon icon-stats'} width={'20px'} height={'22px'} title={'icon'}/>
                            <p className={'p-stats'}>{!qrCodesScanned ? '-' : qrCodesScanned}</p>
                        </div>

                    </div>
                    <div className={'column-2'} style={{width: '60%', paddingRight: '48px'}}>
                        <p className={'p-title-small'}>{'Število skeniranih QR kod glede na lokal'}</p>
                        <div className={'stats-card'}>
                            <IconPalm className={'icon icon-stats'} width={'20px'} height={'22px'} title={'icon'}/>
                            <Dropdown loadBars label={'Ime lokala'} onChange={setBarChosen}
                                      style={{margin: 0}}
                                      grey
                                      placeholder={'Izberi ali vpiši lokal'}/>
                            <p className={'p-stats'}>{!barChosen ? '-' : barChosen?.scans_counter}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
        </div>
}

export default Statistics;