import React, {useState, useEffect} from "react";
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import Input from "../js/Input";

import "../css/Modal.css";
import "../../css/HappyHour.css";

import * as IoIcons from "react-icons/io";
import Button from "../js/Button";
import {ALERGEN_OPTIONS, COUNTRY_OPTIONS} from "../../constants/enums";
import {useDispatch, useSelector} from "react-redux";
import {
    createNewBar,
    createNewCategory,
    createNewProduct,
    loadBars,
    loadCategories, loadComboOffers,
    loadProducts
} from "../../store/actions/actions";
import {
    displayAlert,
    hasItem, isNewObjValidCheck, isUserAdmin,
    mapCaretChars, parsePostDecimalValue,
    parseUserRole,
    validateUserInput
} from "../../helpers/helpers";
import {USER_ROLES} from "../../constants/enums";
import Dropdown from "./Dropdown";
import * as AiIcons from "react-icons/ai";
import countryDE from "../../assets/img/country_de.png";
import countryIT from "../../assets/img/country_it.png";
import countryHR from "../../assets/img/country_hr.png";
import * as CgIcons from "react-icons/cg";
import FileUpload from "./FileUpload";

const CREATE_ACTION = {
    CREATE_CATEGORY: 0,
    CREATE_PRODUCT: 1,
    CREATE_COMBO_OFFER: 2,
    CREATE_BAR: 3
}

const DEFAULT_PRODUCT_CATEGORY = 'unikat';
const BEER_CIDER_PRODUCT_CATEGORY = 'beer&cider';

const NEW_PRODUCT_REQUIRED_FIELDS = {
    'name': 'Ime izdelka'
};

const NEW_BAR_REQUIRED_FIELDS = {
    'name': 'Ime lokala',
    'external_id': 'External Id',
    'tax_number': 'Davčna št.',
    'street': 'Naslov - ulica',
    'street_number': 'Naslov - hišna št.',
    'postal_code': 'Naslov - poštna št.',
    'city': 'Naslov - pošta',
    // 'country': 'Država'
}

function Modal(props) {
    console.log("Open modal");

    let dispatch = useDispatch();
    const user = useSelector(state => state.reducer.user);
    const userCaretaker = useSelector(state => state.reducer.userCaretaker);
    const activeBar = useSelector(state => state.reducer.activeBar);
    const categories = useSelector(state => state.reducer.categories);
    const products = useSelector(state => state.reducer.products);

    const [name, setName] = useState(null);
    const [descSlo, setDescSlo] = useState(null);
    const [descEng, setDescEng] = useState(null);
    const [nameEn, setNameEn] = useState(null);
    const [nameDe, setNameDe] = useState(null);
    const [nameIt, setNameIt] = useState(null);
    const [nameHr, setNameHr] = useState(null);
    const [descIt, setDescIt] = useState(null);
    const [descDe, setDescDe] = useState(null);
    const [descHr, setDescHr] = useState(null);

    const [beer_cider_type, set_beer_cider_type] = useState(null);
    const [name_secondary, set_name_secondary] = useState(null);
    const [desc_secondary, set_desc_secondary] = useState(null);
    const [measure_unit_2, set_measure_unit_2] = useState(null);
    const [measure_unit_3, set_measure_unit_3] = useState(null);

    const [unit, setUnit] = useState(null);
    const [packaging, setPackaging] = useState(null);
    const [alergens, setAlergens] = useState(null);
    const [selectedAlergen, setSelectedAlergen] = useState(null)
    const [selectedAlergens, setSelectedAlergens] = useState([])
    const [alcoholLevel, setAlcoholLevel] = useState(null);
    const [category, setCategory] = useState(null);

    const [externalId, setExternalId] = useState(null)
    const [taxNumber, setTaxNumber] = useState(null)
    const [street, setStreet] = useState(null)
    const [number, setNumber] = useState(null)
    const [postalCode, setPostalCode] = useState(null)
    const [city, setCity] = useState(null)
    const [country, setCountry] = useState(null)

    const [createTrigger, setCreateTrigger] = useState(false);
    const [action, setAction] = useState(null);

    const [currentAlergenOptions, setCurrentAlergenOptions] = useState(ALERGEN_OPTIONS)

    const [image, setImage] = useState(null);
    const [imageChosen, setImageChosen] = useState(null);

    const [invalidInputs, setInvalidInputs] = useState([]);

    const [isHappyHour, setIsHappyHour] = useState(false);
    const [happyHourShowAlways, setHappyHourShowAlways] = useState(false);
    const [happyHourPrice, setHappyHourPrice] = useState(null);
    const [happyHourTimeStartEnd, setHappyHourTimeStartEnd] = useState(['10:00', '11:00']);

    const [happyHourMon, setHappyHourMon] = useState(null);
    const [happyHourTue, setHappyHourTue] = useState(null);
    const [happyHourWed, setHappyHourWed] = useState(null);
    const [happyHourThu, setHappyHourThu] = useState(null);
    const [happyHourFri, setHappyHourFri] = useState(null);
    const [happyHourSat, setHappyHourSat] = useState(null);
    const [happyHourSun, setHappyHourSun] = useState(null);


    useEffect(() => {
        if (props.bar) {
            document.body.style = 'overflow: hidden;'

            return () => {
                document.body.style = ''
            }
        }
        if (props.beer_cider_type) {
            set_beer_cider_type(true);
        }
    }, [])

    useEffect(() => {
        if (image) {
            if (image === 'REMOVE') {
                setImage(null);
                setImageChosen(null);
            } else {
                setImageChosen(image);
            }
        }
    }, [image]);

    useEffect(() => {
        if (createTrigger) {
            switch (action) {
                case CREATE_ACTION.CREATE_PRODUCT:
                    confirmAddProduct();
                    break;
                case CREATE_ACTION.CREATE_CATEGORY:
                    confirmAddCategory();
                    break;
                case CREATE_ACTION.CREATE_COMBO_OFFER:
                    confirmAddComboOffer();
                    break;
                case CREATE_ACTION.CREATE_BAR:
                    confirmAddBar();
                    break;
                default:
                    console.warn("Create action not implemented..", action);
            }
        }
    }, [createTrigger]);

    useEffect(() => {
        if (activeBar && props.category) {
            dispatch(loadCategories(parseUserRole(user.type) === USER_ROLES.ADMIN ? null : activeBar.bar_id, user, false,() => {
            }, () => displayAlert('Napaka pri pridobivanju seznama kategorij. Prosimo poskusite znova..')));
        } else if (activeBar && props.product) {
            dispatch(loadProducts(parseUserRole(user.type) === USER_ROLES.ADMIN ? null : activeBar.bar_id, user, false,() => {
            }, () => displayAlert('Napaka pri pridobivanju seznama izdelkov. Prosimo poskusite znova..')));
        } else if (activeBar && props.product) {
            dispatch(loadComboOffers(parseUserRole(user.type) === USER_ROLES.ADMIN ? null : activeBar.bar_id, () => {
            }, () => displayAlert('Napaka pri pridobivanju seznama izdelkov posebnih ponudb. Prosimo poskusite znova..')));
        }
    }, [activeBar]);

    useEffect(() => {
        if (!!alergens && alergens !== '') {
            // calculate new current options to include options not available
            let alergensArray = alergens.split(",").map(function (item) {
                return item.trim();
            });
            let newCurrentOptions = []
            let newSelectedAlergens = []
            for (const alergenOption of ALERGEN_OPTIONS) {
                if (alergensArray.includes(alergenOption.value)) {
                    newSelectedAlergens.push(alergenOption)
                } else {
                    newCurrentOptions.push(alergenOption)
                }
            }
            setCurrentAlergenOptions(newCurrentOptions)
            setSelectedAlergens(newSelectedAlergens)
        } else {
            setCurrentAlergenOptions(ALERGEN_OPTIONS)
            setSelectedAlergens([])
        }
    }, [alergens])

    useEffect(() => {
        if (!!selectedAlergen) {
            let newAlergens = ''
            if (!!alergens) {
                newAlergens = alergens + ', ' + selectedAlergen
            } else {
                newAlergens = selectedAlergen
            }
            setAlergens(newAlergens)
            setSelectedAlergen(null)
        }
    }, [selectedAlergen])

    const removeAlergen = (removeValue) => {
        if (!!alergens && alergens !== '') {
            let alergensArray = alergens.split(",").map(function (item) {
                return item.trim();
            });
            let newAlergens = []
            for (const alergen of alergensArray) {
                if (alergen !== removeValue) {
                    newAlergens.push(alergen)
                }
            }
            setAlergens(newAlergens.join(', '))
        }
    }

    const handleImageUpload = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.addEventListener("load", function () {
                console.log("load");
                console.log(reader.result);
                setImage(reader.result);
            }, false);
            reader.readAsDataURL(file);
        }
    };

    const previewImage = (base64Data) => {
        base64Data = base64Data === 'REMOVE' ? null : base64Data;
        return <div className={'container-img-upload'}
                    style={{marginLeft: '370px', marginTop: '-150px', marginBottom: '35px', position: 'unset'}}>
            <img className={'img-upload'} src={base64Data} alt="banner" height={'111px'}/>
            <CgIcons.CgCloseO onClick={() => {
                setImage("REMOVE")
            }} className={'icon-close'}/>
        </div>
    };

    const renderFileUpload = (title) => {
        return <><p className={'p-title-small'}>{title}</p>
            <FileUpload comboOffer button={'Izberi in naloži sliko'}
                        handleFile={(file) => handleImageUpload(file)}/>
            {image && previewImage(image)}
        </>
    };

    const renderHappyHourSection = () => {
        return (
            <div className={'happy-hour-container'}>
                <p className={'p-title-small'}>Happy hour</p>
                {renderIsHappyHourCheckbox()}
                <div className={"modal-body-split"}
                     style={{display: isHappyHour ? 'flex' : 'none', alignItems: 'flex-end'}}>
                    <div>

                        <p className={'p-title-small'}>Aktivno časovno obdobje</p>
                        <div className={'happy-hour-days'}
                             style={{display: isHappyHour ? 'flex' : 'none', flexWrap: 'wrap', paddingBottom: '12px'}}>
                            {renderHappyHourDayCheckbox("PON", happyHourMon, setHappyHourMon)}
                            {renderHappyHourDayCheckbox("TOR", happyHourTue, setHappyHourTue)}
                            {renderHappyHourDayCheckbox("SRE", happyHourWed, setHappyHourWed)}
                            {renderHappyHourDayCheckbox("ČET", happyHourThu, setHappyHourThu)}
                            {renderHappyHourDayCheckbox("PET", happyHourFri, setHappyHourFri)}
                            {renderHappyHourDayCheckbox("SOB", happyHourSat, setHappyHourSat)}
                            {renderHappyHourDayCheckbox("NED", happyHourSun, setHappyHourSun)}
                        </div>
                        <TimeRangePicker onChange={(value) => {
                            setHappyHourTimeStartEnd(value);
                        }} value={happyHourTimeStartEnd}/>

                    </div>
                    <div style={{marginBottom: '-16px'}}>
                        {renderIsHappyHourShownAlwaysCheckbox()}
                        <Input onSubmit={() => {
                        }}
                               onChange={setHappyHourPrice}
                               placeholder={'Akcijska cena'}
                               style={{border: invalidInputs.includes('measure_unit') ? "1px solid red" : "none"}}/>
                    </div>
                </div>
            </div>
        );
    }

    const renderHappyHourDayCheckbox = (day, value, setAction) => {
        return (
            <div className={'checkbox-container'} style={{marginBottom: '12px'}}>
                <label>
                    <input type="checkbox"
                           style={{height: '13px', width: '13px'}}
                           checked={value}
                           onChange={() => {
                               console.log(!value);
                               setAction(!value)
                           }}
                    />
                    {day}
                </label>
            </div>
        );
    };


    const renderIsHappyHourCheckbox = () => {
        return (
            <div className={'checkbox-container'} style={{marginBottom: '12px'}}>
                <label>
                    <input type="checkbox"
                           checked={isHappyHour}
                           onChange={() => {
                               console.log(!isHappyHour);
                               setIsHappyHour(!isHappyHour)
                           }}
                    />
                    Happy hour izdelek
                </label>
            </div>
        );
    };
    const renderIsHappyHourShownAlwaysCheckbox = () => {
        return (
            <div className={'checkbox-container'} style={{margin: '12px 0'}}>
                <label>
                    <input type="checkbox"
                           style={{height: '15px', width: '15px'}}
                           checked={happyHourShowAlways}
                           onChange={() => {
                               console.log(!happyHourShowAlways);
                               setHappyHourShowAlways(!happyHourShowAlways)
                           }}
                    />
                    Izdelek naj bo aktiven po redni ceni tudi izven časa happy hour ponudbe
                </label>
            </div>
        );
    };

    const confirmAddBar = () => {
        // add bar to database
        console.log('add bar to database')
        // construct bar object
        let bar = {
            external_id: externalId,
            name: mapCaretChars(name),
            tax_number: taxNumber,
            street: mapCaretChars(street),
            street_number: mapCaretChars(number),
            postal_code: postalCode,
            city: mapCaretChars(city),
            // country: country
        }

        // check data validity
        let response = isNewObjValidCheck(bar, NEW_BAR_REQUIRED_FIELDS);

        if (!response.isValid) {
            displayAlert(response.errorMessages.join('\n'));
            setCreateTrigger(false);
            setInvalidInputs(response.invalidFields);
            return;
        }

        let insertBar = {
            external_id: bar.external_id,
            name: bar.name,
            address: bar.street + ' ' + bar.street_number + ', ' + bar.postal_code + ' ' + bar.city,
            // address: bar.street + ' ' + bar.street_number + ', ' + bar.postal_code + ' ' +bar.city + ', ' +bar.country,
            tax_number: bar.tax_number,
            user_id: null, // user_id: user.user_id,
            bar_owner_id: null,
            caretaker_id: userCaretaker.caretaker_id
        }

        // save new bar to database
        dispatch(createNewBar(insertBar, null, () => {
            dispatch(loadBars(user.user_id, () => {
            }, () => displayAlert('Napaka pri pridobivanju seznama lokalov. Prosimo poskusite znova..')));
            setCreateTrigger(false);
            props.actionClose();
        }, () => {
            displayAlert('Napaka pri ustvarjanju lokala. Popravite vnešene napake in poskusite znova..');
            setCreateTrigger(false);
        }));
    }

    const confirmAddCategory = () => {
        if (!hasItem("name", "menu_group_id", name, categories)) {
            let category = {
                name: mapCaretChars(name),
                position: null,
                eng_name: mapCaretChars(nameEn),
                de_name: mapCaretChars(nameDe),
                it_name: mapCaretChars(nameIt),
                hr_name: mapCaretChars(nameHr)
            }
            dispatch(createNewCategory(parseUserRole(user.type) === USER_ROLES.ADMIN ? null : activeBar.bar_id, category, () => {
                dispatch(loadCategories(parseUserRole(user.type) === USER_ROLES.ADMIN ? null : activeBar.bar_id, user, false,() => {
                }, () => displayAlert('Napaka pri pridobivanju seznama kategorij. Prosimo poskusite znova..')));
            }, () => displayAlert('Napaka pri ustvarjanju kategorije. Prosimo osvežite stran in poskusite znova..')));
            setCreateTrigger(false);
            props.actionClose();
        } else {
            setCreateTrigger(false);
            props.actionClose();
            displayAlert('Kategorija že obstaja. Izberite željeno kategorijo iz seznama..')
        }
    };

    const confirmAddComboOffer = () => {
        let product = {
            external_id: null,
            name: mapCaretChars(name),
            initial_price: 0,
            bar_id: activeBar.bar_id,
            picture: imageChosen,
            slo_descr: mapCaretChars(descSlo),
            eng_descr: mapCaretChars(descEng),
            eng_name: mapCaretChars(nameEn),
            de_descr: mapCaretChars(descDe),
            de_name: mapCaretChars(nameDe),
            it_descr: mapCaretChars(descIt),
            it_name: mapCaretChars(nameIt),
            hr_descr: mapCaretChars(descHr),
            hr_name: mapCaretChars(nameHr),
            packaging: null,
            measure_unit: null,
            allergenes: null,
            alcohol_percent: null,
            category_label: 'Posebna ponudba',
            combo_offer: true
        };
        let validateInput = validateUserInput([product.name]);
        if (!validateInput) {
            displayAlert("Neveljaven vnos podatkov! Za nadaljevanje vnesite željeno ime.")
            setCreateTrigger(false);
            return;
        }

        dispatch(createNewProduct(product, true, () => {
            dispatch(loadComboOffers(activeBar['bar_id'], () => {
            }, () => displayAlert('Napaka pri pridobivanju seznama izdelkov. Prosimo poskusite znova..')));

            setCreateTrigger(false);
            props.actionClose();

        }, () => {
            displayAlert('Napaka pri ustvarjanju artikla. Popravite vnešene napake in poskusite znova..');
            setCreateTrigger(false);
        }));
    };

    const confirmAddProduct = () => {
        let product = {
            external_id: externalId,
            name: mapCaretChars(name),
            initial_price: 0,
            bar_id: isUserAdmin(user) ? null : activeBar.bar_id,
            picture: null,
            slo_descr: mapCaretChars(descSlo),
            eng_descr: mapCaretChars(descEng),
            eng_name: mapCaretChars(nameEn),
            de_descr: mapCaretChars(descDe),
            de_name: mapCaretChars(nameDe),
            it_descr: mapCaretChars(descIt),
            it_name: mapCaretChars(nameIt),
            hr_descr: mapCaretChars(descHr),
            hr_name: mapCaretChars(nameHr),
            packaging: mapCaretChars(packaging),
            name_secondary: mapCaretChars(name_secondary),
            desc_secondary: mapCaretChars(desc_secondary),
            measure_unit: unit,
            measure_unit_2: measure_unit_2,
            measure_unit_3: measure_unit_3,
            initial_price_2: 0,
            initial_price_3: 0,
            beer_cider_type: !!props.beer_cider_type,
            allergenes: alergens,
            alcohol_percent: alcoholLevel ? alcoholLevel.replace(',', '.') : '',
            category_label: user.type === 'ADMIN' ? !!props.beer_cider_type ? BEER_CIDER_PRODUCT_CATEGORY : category : DEFAULT_PRODUCT_CATEGORY,
            combo_offer: false,
            happy_hour: isHappyHour,
            happy_hour_price: happyHourPrice ? parsePostDecimalValue(happyHourPrice) : null,
            happy_hour_show_always: happyHourShowAlways,
            happy_hour_time_start: happyHourTimeStartEnd ? happyHourTimeStartEnd[0] : null,
            happy_hour_time_end: happyHourTimeStartEnd ? happyHourTimeStartEnd[1] : null,
            happy_hour_mon: happyHourMon ? happyHourMon : null,
            happy_hour_tue: happyHourTue ? happyHourTue : null,
            happy_hour_wed: happyHourWed ? happyHourWed : null,
            happy_hour_thu: happyHourThu ? happyHourThu : null,
            happy_hour_fri: happyHourFri ? happyHourFri : null,
            happy_hour_sat: happyHourSat ? happyHourSat : null,
            happy_hour_sun: happyHourSun ? happyHourSun : null
        };

        let response = isNewObjValidCheck(product, NEW_PRODUCT_REQUIRED_FIELDS, true);

        if (!response.isValid) {
            displayAlert(response.errorMessages.join('\n'));
            setCreateTrigger(false);
            setInvalidInputs(response.invalidFields);
            return;
        }

        product.alcohol_percent = product.alcohol_percent !== '' ? product.alcohol_percent : null;

        dispatch(createNewProduct(product, false, () => {
            dispatch(loadProducts(isUserAdmin(user) ? null : activeBar['bar_id'], user, false,() => {
                displayAlert('Nov artikel uspešno ustvarjen.');
            }, () => displayAlert('Napaka pri pridobivanju seznama izdelkov. Prosimo poskusite znova..')));
            setCreateTrigger(false);
            props.actionClose();

        }, () => {
            displayAlert('Napaka pri ustvarjanju artikla. Popravite vnešene napake in poskusite znova..');
            setCreateTrigger(false);
        }));
    };

    const triggerAction = (action) => {
        setAction(action);
        setCreateTrigger(true);
    };


    const renderHeader = (title, actionClose) => {
        return (
            <div className={'modal-header'}>
                <p>{title}</p>
                <div onClick={actionClose} style={{cursor: 'pointer'}}><IoIcons.IoMdClose
                    className={'icon-close'}/>
                </div>
            </div>
        );
    };

    const renderFooter = (text, actionCreate) => {
        return (
            <Button text={text} confirm action={actionCreate} style={{
                alignSelf: 'flex-end',
                marginTop: '16px'
            }}/>
        );
    };

    const renderAddBarModal = () => {
        // email - vodja lokala | email - potnik | email -lastnik
        return (
            <div className={'modal-overlay'}>
                <div className={'modal modal-product modal-height'}>
                    {renderHeader('USTVARI NOV LOKAL', props.actionClose)}
                    <div className={'modal-body'}>
                        <Input onSubmit={() => triggerAction(CREATE_ACTION.CREATE_BAR)} onChange={setName}
                               placeholder={'Ime lokala'}
                               style={{
                                   marginBottom: '24px',
                                   border: invalidInputs.includes('name') ? "1px solid red" : "none"
                               }}/>
                        <div className={'modal-body-split'}>
                            <Input onSubmit={() => {
                            }}
                                   onChange={setExternalId}
                                   placeholder={'External Id'}
                                   style={{border: invalidInputs.includes('external_id') ? "1px solid red" : "none"}}/>
                            <Input onSubmit={() => {
                            }}
                                   onChange={setTaxNumber}
                                   placeholder={'Davčna št.'}
                                   style={{border: invalidInputs.includes('tax_number') ? "1px solid red" : "none"}}/>
                        </div>
                        <div className={'modal-body-split'}>
                            <Input onSubmit={() => {
                            }}
                                   onChange={setStreet}
                                   placeholder={'Naslov - ulica'}
                                   style={{border: invalidInputs.includes('street') ? "1px solid red" : "none"}}/>
                            <Input onSubmit={() => {
                            }}
                                   onChange={setNumber}
                                   placeholder={'Naslov - hišna št.'}
                                   style={{border: invalidInputs.includes('street_number') ? "1px solid red" : "none"}}/>
                        </div>
                        <div className={'modal-body-split'}>
                            <Input onSubmit={() => {
                            }}
                                   onChange={setPostalCode}
                                   placeholder={'Naslov - poštna št.'}
                                   style={{border: invalidInputs.includes('postal_code') ? "1px solid red" : "none"}}/>
                            <Input onSubmit={() => {
                            }}
                                   onChange={setCity}
                                   placeholder={'Naslov - pošta'}
                                   style={{border: invalidInputs.includes('city') ? "1px solid red" : "none"}}/>
                        </div>
                        <Dropdown country grey onChange={setCountry}
                                  placeholder={'Država'} options={COUNTRY_OPTIONS}
                                  invalidInput={invalidInputs.includes('country')}
                        />
                    </div>
                    {renderFooter('USTVARI', () => triggerAction(CREATE_ACTION.CREATE_BAR))}
                </div>
            </div>
        );
    };

    const renderAddCategoryModal = () => {
        return (
            <div className={'modal-overlay'}>
                <div className={'modal modal-name'}>
                    {renderHeader('POIMENUJ SVOJO NOVO KATEGORIJO', props.actionClose)}
                    <Input onSubmit={() => {
                    }} onChange={setName}
                           label={'Slovenski naziv kategorije'}
                           placeholder={'Ime kategorije'}
                           style={{marginBottom: '24px'}}/>

                    <Input onChange={setNameEn} placeholder={'Angleško ime kategorije'}
                           label={'Angleški naziv kategorije'}
                           style={inputStyle}
                    />
                    <Input onChange={setNameDe} placeholder={'Nemško ime kategorije'}
                           label={'Nemški naziv kategorije'}
                           style={inputStyle}
                    />
                    <Input onChange={setNameIt} placeholder={'Italijansko ime kategorije'}
                           label={'Italijanski naziv kategorije'}
                           style={inputStyle}
                    />
                    <Input onChange={setNameHr} placeholder={'Hrvaško ime kategorije'}
                           label={'Hrvaški naziv kategorije'}
                           style={inputStyle}
                    />
                    {renderFooter('USTVARI', () => triggerAction(CREATE_ACTION.CREATE_CATEGORY))}
                </div>
            </div>
        );
    };

    const renderAddComboOfferModal = () => {
        return (
            <div className={'modal-overlay'}>
                <div className={'modal modal-name'}>
                    {renderHeader('POIMENUJ SVOJ NOV VMESNI OGLAS', props.actionClose)}
                    <Input onSubmit={() => triggerAction(CREATE_ACTION.CREATE_COMBO_OFFER)} onChange={setName}
                           placeholder={'Ime vmesnega oglasa'}
                           style={{marginBottom: '24px'}}/>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%'}}>
                        {renderFileUpload('Dodajte sliko oglasa')}
                    </div>

                    {renderFooter('USTVARI', () => triggerAction(CREATE_ACTION.CREATE_COMBO_OFFER))}
                </div>
            </div>
        );
    };


    let inputStyle = {marginBottom: '24px'};
    const renderAddProductModal = () => {
        return (<>
                <div className={'modal-overlay'} style={{bottom: 'auto'}}>
                    <div className={'modal modal-product'} style={{overflow: 'scroll'}}>
                        {renderHeader('USTVARI NOV ARTIKEL', props.actionClose)}
                        <Input onChange={setName}
                               placeholder={'Ime izdelka'}
                               style={{border: invalidInputs.includes('name') ? "1px solid red" : "none"}}
                        />
                        {user.type === 'ADMIN' &&
                        <Input onSubmit={() => {
                        }}
                               onChange={setExternalId}
                               placeholder={'External Id'}/>
                        }

                        <div className={'modal-body-split'}>
                            {props.beer_cider_type ?
                                <>
                                    <Input onSubmit={() => {
                                    }}
                                           onChange={setUnit}
                                           placeholder={'Enota'}
                                           style={{border: invalidInputs.includes('measure_unit') ? "1px solid red" : "none"}}/>
                                    <Input onSubmit={() => {
                                    }} onChange={set_measure_unit_2} placeholder={'Enota 2'}/>
                                    <Input onSubmit={() => {
                                    }} onChange={set_measure_unit_3} placeholder={'Enota 3'}/>
                                </>
                                :
                                <Input onSubmit={() => {
                                }}
                                       onChange={setUnit}
                                       placeholder={'Enota'}
                                       style={{border: invalidInputs.includes('measure_unit') ? "1px solid red" : "none"}}/>
                            }
                            <Input onSubmit={() => {
                            }} onChange={setPackaging} placeholder={'Pakiranje'}/>
                        </div>
                        <div className={'modal-body-split'}>
                            <Dropdown loadAlergens grey onChange={setSelectedAlergen}
                                      placeholder={'Alergeni'} options={currentAlergenOptions} value={selectedAlergen}/>
                            <Input onSubmit={() => {
                            }}
                                   onChange={setAlcoholLevel}
                                   placeholder={'Stopnja alkohola'}
                                   style={{border: invalidInputs.includes('alcohol_percent') ? "1px solid red" : "none"}}/>
                        </div>
                        <div className={'modal-body-split'}>
                            <div className={'ad-bars-list'} style={{width: '48%'}}>
                                {selectedAlergens.map((alergen, b) => (
                                    <div key={`${b}_${alergen.value}`}
                                         className={'bar-list-item'}
                                         onDoubleClick={() => console.log("remove item", alergen.value)}>
                                        <p className={'p-title-product'}>{alergen.label}</p>
                                        <div className={'container-icon-selected'} style={{marginLeft: '0px'}}>
                                            <div className={'remove-bar-icon'} onClick={() => {
                                                removeAlergen(alergen.value)
                                            }} style={{cursor: 'pointer'}}>
                                                <AiIcons.AiFillDelete/>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {user.type === 'ADMIN' &&
                        !props.beer_cider_type &&
                        <Input onSubmit={() => {
                        }} onChange={setCategory} placeholder={'Kategorija'}/>
                        }
                        <Input onSubmit={() => {
                        }} textarea
                               style={{background: '#F0F0F0', minHeight: '115px', height: '115px', maxHeight: '115px'}}
                               onChange={setDescSlo} placeholder={'Opis artikla (maksimalno število znakov 140)'}
                        />

                        {props.beer_cider_type &&
                        <Input onChange={set_name_secondary}
                               placeholder={'Sekundarno ime izdelka (izdelek v paru)'}
                               style={{marginTop: '12px'}}
                        />}
                        {props.beer_cider_type &&
                        <Input onSubmit={() => {
                        }}
                               textarea
                               style={{
                                   background: '#F0F0F0',
                                   minHeight: '115px',
                                   height: '115px',
                                   maxHeight: '115px',
                                   marginBottom: '12px'
                               }}
                               onChange={set_desc_secondary}
                               placeholder={'Sekundarni opis artikla (maksimalno število znakov 140)'}
                        />}

                        {renderHappyHourSection()}

                        <Input onChange={setNameEn} placeholder={'Angleški naziv izdelka'}
                               label={'Angleški naziv izdelka'}
                               style={inputStyle}
                        />
                        <Input onChange={setDescEng} placeholder={'Angleški opis'}
                               label={'Angleški opis'}
                               style={inputStyle}
                        />
                        <div
                            className={'country-label-container'}
                            style={{alignSelf: 'self-end', marginBottom: '-20px'}}>
                            <img src={countryDE} alt="DE" width={'25px'}/>
                        </div>
                        <Input onChange={setNameDe} placeholder={'Nemški naziv izdelka'}
                               label={'Nemški naziv izdelka'}
                               style={inputStyle}
                        />
                        <Input onChange={setDescDe} placeholder={'Nemški opis'}
                               label={'Nemški opis'}
                               style={inputStyle}
                        />
                        <div
                            className={'country-label-container'}
                            style={{alignSelf: 'self-end', marginBottom: '-20px'}}>
                            <img src={countryIT} alt="IT" width={'25px'}/>
                        </div>
                        <Input onChange={setNameIt} placeholder={'Italijanski naziv izdelka'}
                               label={'Italijanski naziv izdelka'}
                               style={inputStyle}
                        />
                        <Input onChange={setDescIt} placeholder={'Italijanski opis'}
                               label={'Italijanski opis'}
                               style={inputStyle}
                        />
                        <div
                            className={'country-label-container'}
                            style={{alignSelf: 'self-end', marginBottom: '-20px'}}>
                            <img src={countryHR} alt="HR" width={'25px'}/>
                        </div>
                        <Input onChange={setNameHr} placeholder={'Hrvaški naziv izdelka'}
                               label={'Hrvaški naziv izdelka'}
                               style={inputStyle}
                        />
                        <Input onChange={setDescHr} placeholder={'Hrvaški opis'}
                               label={'Hrvaški opis'}
                               style={inputStyle}
                        />

                        {renderFooter('USTVARI', () => triggerAction(CREATE_ACTION.CREATE_PRODUCT))}
                    </div>
                </div>
            </>
        );
    };


    if (props.category) {
        return renderAddCategoryModal();
    } else if (props.product) {
        return renderAddProductModal();
    } else if (props.comboOffer) {
        return renderAddComboOfferModal();
    } else if (props.bar) {
        return renderAddBarModal();
    }
}

export default Modal;