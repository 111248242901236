import React, {useState, useEffect, createRef, useCallback} from "react";
import {ReactComponent as IconMegaphone} from "../assets/svg/icon-megaphone.svg";
import {ReactComponent as IconDrink} from "../assets/svg/icon-drink.svg";

import Header from "../components/js/Header";
import Input from "../components/js/Input";

import "../css/ProductBarEdit.css"
import "../css/Ads.css"

import {useDispatch, useSelector} from "react-redux";
import {displayAlert, hasItem, isSessionValid, mapCaretChars, parseMapCaretChars} from "../helpers/helpers";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import {
    loadAds,
    loadBars, loadComboOffers, setActiveBar,
    setCreateMenuActive, setSidebarWidth,

} from "../store/actions/actions";
import {useHistory} from "react-router-dom";
import Dropdown from "../components/js/Dropdown";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import {ReactComponent as IconDelete} from "../assets/svg/Icon feather-trash.svg";
import {sumAllQrBarsScans} from "./Statistics";

// in pixels as integer
export const BANNER_WIDTH = 350;

export const sumAllAdClicks = (internalAds) => {
    let c = 0;
    for (let i = 0; i < internalAds.length; i++) {
        let internalAd = internalAds[i];
        if (internalAd?.clicks) {
            c += internalAd.clicks;
        }
    }
    return c;
}

function StatisticsAds(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let user = useSelector(state => state.reducer.user);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    let ads = useSelector(state => state.reducer.comboOffers);
    const [addChosen, setAddChosen] = useState(null);

    const [name, setName] = useState(null);
    const [link, setLink] = useState(null);

    const [allAdsClicks, setAllAdsClicks] = useState(null);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);


    useEffect(() => {
        window.statistics_page_open = true;
        dispatch(setCreateMenuActive(null));

        return () => {
            window.statistics_page_open = false;
        };
    }, []);

    useEffect(() => {
        if (ads) {
            console.log("Ads loaded");
            let allAdImpressions = sumAllAdClicks(ads);
            setAllAdsClicks(allAdImpressions);
        }
    }, [ads]);

    useEffect(() => {
        let done = false;
        const asyncEffect = async () => {
            if (user) {
                await dispatch(loadComboOffers(null,() => {
                    console.log("internal Ads/combo offers successfully loaded..");
                    done = true;
                }, () => {
                    console.warn("Error loading internal Ads/combo offers..")
                }));
            }
        }
        asyncEffect().then(() => console.log(done && "done loading data."));
    }, [user]);

    useEffect(() => {
        if (addChosen) {
            //dispatch(setActiveBar(barChosen));
            console.log("internal Ad/combo offer chosen", addChosen);
        }
    }, [addChosen]);

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page relative ad className={'main'} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            <div className={'body'} style={{paddingLeft: `${sidebarWidth + 50}px`}}>
                <Header left={`${sidebarWidth + 50}px`} title={`STATISTIKA / OGLASI`}/>
                <div className={'container-column-2'}>
                    <div className={'column-2'} style={{width: '40%'}}>
                        <p className={'p-title-small'}>{'Številko klikov na oglase skupno'}</p>
                        <div className={'stats-card'}>
                            <IconMegaphone className={'icon icon-stats'} width={'20px'} height={'22px'} title={'icon'}/>
                            <p className={'p-stats'}>{!allAdsClicks ? '-' : allAdsClicks}</p>
                        </div>

                    </div>
                    <div className={'column-2'} style={{width: '60%', paddingRight: '48px'}}>
                        <p className={'p-title-small'}>{'Številko klikov na posamični oglas'}</p>
                        <div className={'stats-card'}>
                            <IconMegaphone className={'icon icon-stats'} width={'20px'} height={'22px'} title={'icon'}/>
                            <Dropdown loadInternalAds label={'Ime lokala'} onChange={setAddChosen}
                                      style={{margin: 0}}
                                      grey
                                      placeholder={'Izberi ali vpiši ime oglasa'}/>
                            <p className={'p-stats'}>{!addChosen ? '-' : addChosen?.clicks}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
        </div>
}

export default StatisticsAds;