import React, {useState, useEffect, useCallback} from "react";
import Masonry from "react-responsive-masonry"

import '../css/EditPublishedMenu.css';

import {useDispatch, useSelector} from "react-redux";

import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import Footer from "../components/js/Footer";
import Input from "../components/js/Input";
import * as AiIcons from "react-icons/ai";
import {
    loadLiveMenu, setActiveMenu,
    setLiveMenu, setSidebarWidth,
    updateProductPriceOnLiveMenu
} from "../store/actions/actions";
import {
    displayAlert, displayPrice,
    isSessionValid,
    parseMapCaretChars, parsePostDecimalValue,
    validatePriceUpdateAction,
    validateUserInputNumerical
} from "../helpers/helpers";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import {ReactComponent as IconEdit} from "../assets/svg/Icon feather-edit.svg";
import {useHistory} from "react-router-dom";

function EditPublishedMenu(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    const menuLive = useSelector(state => state.reducer.menuLive);
    const activeBar = useSelector(state => state.reducer.activeBar);
    const activeMenu = useSelector(state => state.reducer.activeMenu);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    //let menuLive = menuLive.categories;

    //const [liveMenu, setLiveMenu] = useState(menuLive ? menuLive : []);

    const [changePriceTrigger, setChangePriceTrigger] = useState(null);
    const [productId, setProductId] = useState(null);
    const [price, setPrice] = useState(null);

    const dispatchLoadLiveMenu = () => {
        if (!activeBar?.bar_id) {
            return;
        }
        dispatch(loadLiveMenu(activeBar['bar_id'], () => {


        }, () => {
            dispatch(setLiveMenu(null));
            dispatch(setActiveMenu(null));
            displayAlert('Napaka pri pridobivanju trenutnega cenika. Prosimo poskusite znova..')
        }));
    };


    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    useEffect(() => {
        console.log("LIVE MENU", menuLive);
    }, [menuLive]);

    useEffect(() => {
        console.log("ACTIVE BAR", activeBar);

        dispatchLoadLiveMenu();
    }, [activeBar]);

    useEffect(() => {
        console.log("ACTIVE MENU", activeMenu);
        if (activeMenu) {
            dispatch(setLiveMenu(activeMenu));
        }
    }, [activeMenu]);

    useEffect(() => {
        if (changePriceTrigger) {
            dispatch(updateProductPriceOnLiveMenu(activeBar.bar_id, menuLive.menu_id, productId, price, () => {
                console.log("Live menu successfully updated!");
            }, () => {
                console.warn("Error updating live menu..")
            }));
            setChangePriceTrigger(false);
        }
    }, [changePriceTrigger]);

    const onActionPriceChange = (productId, price) => {
        if (!menuLive) {
            displayAlert('Napaka pri pridobivanju seznama trenutnega cenika. Prosimo poskusite znova..');
            return;
        }
        setProductId(productId);
        setPrice(price);
        setChangePriceTrigger(true)
    };

    function Item(props) {
        const product = props.value;
        if (product.combo_offer) {
            return <></>
        }
        return (
            <div className={'menu-category-product'}>
                <div className={'menu-category-product-left'}>
                    <p className={'p-title-product'}>{parseMapCaretChars(product.name)}</p>
                    <div className={'menu-category-product-left-desc'}>
                        <p className={'p-desc-product'}>{parseMapCaretChars(product.packaging)}</p>
                        <p className={'p-desc-product'}>{product.measure_unit}</p>
                    </div>
                </div>
                <div className={'menu-category-product-right'}>
                    <Input price
                           onSubmit={() => {
                           }}
                           onChange={() => {}}
                           onBlur={(e) => {
                               console.log('Triggered because this input lost focus', e.target.value);
                               validatePriceUpdateAction(e.target.value, () => {
                                   onActionPriceChange(product.product_id, parsePostDecimalValue(e.target.value))
                               })
                           }}
                           placeholder={!product.initial_price && product.initial_price === 0 ? 'Vpiši ceno (v EUR)' : displayPrice(product.initial_price)}/>

                </div>
            </div>
        );
    }

    const renderLiveMenu = () => {
        if (!menuLive || !menuLive.categories) {
            return null;
        }
        return (
            <>
                <p className={'p-title-small'}>{'S klikom na polje, lahko popravljaš trenutne cene cenika.'}</p>
                <Masonry columnsCount={2} className={'masonry-edit-menu'} gutter="32px">
                    {menuLive.categories.map((category, c) => (
                        <div key={c} className={'menu-category'}>
                            <div className={'menu-category-header'}>
                                <p className={'p-title-category'}>{parseMapCaretChars(category.category.name)}</p>
                            </div>
                            <div className={'menu-category-content'}>
                                {category.products.map((product, p) => (
                                        <Item key={p} value={product}/>
                                    )
                                )}
                            </div>
                        </div>
                    ))}
                </Masonry>
            </>
        );
    };

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page relative editMenu className={'main'} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            <Header left={`${sidebarWidth + 50}px`} title={'UREDI DIGITALNI CENIK'}
                    step={`Pozdravljena restavracija, ${activeBar ? activeBar.name : ''}`}/>
            <div className={'body'} style={{minHeight: '100vh', paddingLeft: `${sidebarWidth + 50}px`}}>
                {renderLiveMenu()}
            </div>
            <Footer left={`${sidebarWidth}px`} next={'POTRDI'} urlNext={''} onActionNext={() => {displayAlert("Cenik uspešno posodobljen."); onNavigateTo(PAGES.HOME)}}/>
        </Page>
        </div>

}

export default EditPublishedMenu;

