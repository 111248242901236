import React, {useState, useCallback, useEffect} from "react";

import '../components/css/Tables.css'

import {useDispatch, useSelector} from "react-redux";

import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import {ReactComponent as IconPrinter} from "../assets/svg/Icon feather-printer.svg";
import {ReactComponent as IconEdit} from "../assets/svg/Icon feather-edit.svg";
import {useHistory} from "react-router-dom";
import {PAGES} from "../App";
import {
    loadUsers, setActiveEditUser,
    setSidebarWidth
} from "../store/actions/actions";
import {
    displayAlert,
    displayDate,
    isSessionValid, isUserAdmin, isUserCaretaker,
    parseMapCaretChars,
    printDimensions
} from "../helpers/helpers";
import {Redirect} from "react-router";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";

function UsersList(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let user = useSelector(state => state.reducer.user);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);
    let users = useSelector(state => state.reducer.allUsers);
    let activeEditUser = useSelector(state => state.reducer.activeEditUser);

    const [shouldNavigate, setShouldNavigate] = useState(false);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    useEffect(() => {
        if (user) {
            dispatch(loadUsers(user.user_id, () => {
                console.log("Users successfully loaded..");
            }, () => {
                console.warn("Error loading users..");
                displayAlert('Napaka pri pridobivanju seznama uporabnikov. Prosimo poskusite znova..')
            }));
        }

    }, [user]);

    useEffect(() => {
        if (activeEditUser && shouldNavigate) {
            onNavigateTo(PAGES.EDIT_USER);
        }

    }, [activeEditUser, shouldNavigate]);

    const navigateAndLoadEditUser = async (user) => {
        await dispatch(setActiveEditUser(user));
        setTimeout(() => setShouldNavigate(true), 100);
    };

    useEffect(() => {
        console.log("USERS:", users)
    }, [users]);

    function Item(props) {
       // console.log(props.value);
        const item = props.value;
        return (
            <li onClick={() => {
            }} className={'row'} style={{left: `${sidebarWidth + 50}px`}}>
                <div className={'row-bar'}>{item.external_id}</div>
                <div className={'row-date'}>{parseMapCaretChars(item.full_name)}</div>
                <div className={'row-date'}>{item.username}</div>
                <div className={'row-dimension'}>{parseMapCaretChars(item.type)}</div>

                <div className={'row-edit'} style={{visibility: isUserAdmin(user) ? 'visible' : 'hidden'}}><a
                    className={'a-icon'}
                    onClick={async () => await navigateAndLoadEditUser(item)}><IconEdit
                    className={'icon'}
                    width={'24px'} height={'24px'}
                    title={'icon list'}/></a>
                </div>
            </li>
        );
    }

    function renderTable(items) {
        const header = () => {
            return (
                <div className={'row-header'} style={{left: `${sidebarWidth + 50}px`}}>
                    <div className={'row-bar'}>External ID</div>
                    <div className={'row-date'}>Polno ime</div>
                    <div className={'row-date'}>E-mail</div>
                    <div className={'row-dimension'}>Tip</div>
                    <div className={'row-edit'} style={{visibility: isUserAdmin(user) ? 'visible' : 'hidden'}}>Uredi
                    </div>
                </div>
            );
        };
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul className={'item-list'} style={{left: `${sidebarWidth + 50}px`}}>
                    {items.map((item, index) => <Item key={index} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {header()}
                {list(items)}
            </>
        );
    }

    let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
    const [mainHeight, setMainHeight] = useState(documentSize.y);
    useEffect(() => {
        setTimeout(() => {
            let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
            setMainHeight(documentSize.y);
        }, 250)
    }, [])

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page className={'main'} style={{height: `${mainHeight}px`}} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            <Header left={`${sidebarWidth + 50}px`} title={'UPORABNIŠKI RAČUNI'}/>
            <div className={'body'}>
                {renderTable(users)}
            </div>
        </Page>
        </div>

}

export default UsersList;

