import React, {useEffect, useState} from 'react'
import Sidebar from "./Sidebar";
import {useDispatch, useSelector} from "react-redux";
import {setShowSidebar} from "../../store/actions/actions";
import {ReactComponent as LeftArrow} from "../../assets/svg/Icon left-arrow.svg";

export const SIDEBAR_WIDTH = 390


function Page(props) {
    const dispatch = useDispatch();

    const showSidebar = useSelector(state => state.reducer.showSidebar);

    let sideBarWidthDefault
    if (showSidebar) {
        sideBarWidthDefault = SIDEBAR_WIDTH
    } else {
        sideBarWidthDefault = 0
    }
    const [sidebarWidth, setSidebarWidth] = useState(sideBarWidthDefault)

    let pageClasses = 'page'
    if (props.relative) {
        pageClasses += ' relative'
    }
    if (props.editMenuAddProducts) {
        pageClasses += ' edit-menu-add-products'
    }
    if (props.editMenu) {
        pageClasses += ' edit-menu'
    }
    if (props.centered) {
        pageClasses += ' centered'
    }
    if (props.ad) {
        pageClasses += ' ad'
    }
    if (props.fileUpload) {
        pageClasses += ' file-upload'
    }
    const [pageClassName] = useState(pageClasses)

    const setShowSidebarValue = (value) => {
        dispatch(setShowSidebar(value))
    }

    useEffect(() => {
        const {onToggleSidebar} = props
        if (onToggleSidebar) {
            onToggleSidebar(showSidebar)
        }
        if (showSidebar) {
            setSidebarWidth(SIDEBAR_WIDTH)
        } else {
            setSidebarWidth(0)
        }
    }, [showSidebar])

    return <div className={props.className} style={props.style}>
        {showSidebar && <Sidebar display={showSidebar}/>}
        <div className={pageClassName} style={props.pageStyle}>
        {/*<div className={pageClassName} style={{width: `${window.innerWidth - sidebarWidth}px`}}>*/}
            <div style={{position: 'absolute', top:'30px', left: `${sidebarWidth+10}px`}} onClick={() => {
                setShowSidebarValue(!showSidebar)
            }}>
                {showSidebar ?
                    <><LeftArrow className={'icon arrow'} width={'30px'} height={'30px'}
                                 title={'icon edit'} /></>
                    :
                    <><LeftArrow className={'icon rotate arrow'} width={'30px'} height={'30px'}
                                 title={'icon edit'} /></>
                }
            </div>
            {props.children}
        </div>
    </div>;
}

export default Page;