import React, {useState, useCallback, useEffect} from "react";

import '../components/css/Tables.css'

import {useDispatch, useSelector} from "react-redux";

import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import {ReactComponent as IconPrinter} from "../assets/svg/Icon feather-printer.svg";
import {ReactComponent as IconEdit} from "../assets/svg/Icon feather-edit.svg";
import {ReactComponent as IconDelete} from "../assets/svg/Icon feather-trash.svg";
import {useHistory} from "react-router-dom";
import {PAGES} from "../App";
import {
    loadCategories,
    loadComboOffers,
    loadMenu,
    loadMenus, loadProducts, setActiveBar, setComboOffers,
    setCreateMenuActive, setSessionCategories, setSessionComboOffers, setSessionProducts, setSidebarWidth
} from "../store/actions/actions";
import {
    deleteLocalMenu,
    displayAlert,
    displayDate,
    findItem, getLocalStorageObject,
    isSessionValid, isUserBarRepresentative, isUserCaretaker, isUserOwner,
    parseLeadingBrand, parseMapCaretChars, parseUserRole,
    printDimensions
} from "../helpers/helpers";
import {LOCAL_STORAGE_KEYS, USER_ROLES} from "../constants/enums";
import {Redirect} from "react-router";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";

function MenusListLocal(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let user = useSelector(state => state.reducer.user);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    let allProducts = useSelector(state => state.reducer.products);
    let comboOffers = useSelector(state => state.reducer.comboOffers);
    let allCategories = useSelector(state => state.reducer.categories);
    const [products, setProducts] = useState(allProducts)
    const [productsC, setProductsC] = useState(comboOffers)
    const [categories, setCategories] = useState(allCategories)

    const [menuSelected, setMenuSelected] = useState(null)
    const [dataLoaded, setDataLoaded] = useState(false)

    const getLocalMenus = () => {
        let menusObject = getLocalStorageObject(LOCAL_STORAGE_KEYS.LOCAL_MENUS_IN_EDITING)
        return !menusObject ? [] : Object.values(menusObject)
    }

    const [menus, setMenus] = useState(getLocalMenus())


    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    const navigateAndLoadEditMenu = async (path, menu) => {
        const parseLatestL10NData = async (menu) => {
            console.log(menu);
            //setMenuSelected(menu);
        }
        await parseLatestL10NData(menu)
        setMenuSelected(menu);

        //await dispatch(setCreateMenuActive(menu));
        //await dispatch(setActiveBar(menu.bar));
        //onNavigateTo(path);
//
    };

    useEffect(() => {
            let done = false;
            const asyncEffect = async () => {
                if (menuSelected) {
                    console.log("MENU SELECTED", menuSelected)
                    let barId = null;
                    barId = menuSelected.bar.bar_id;

                    await dispatch(loadProducts(barId, user, false,(products) => {
                        setSessionProducts(JSON.stringify(products));
                        setProducts(products)
                        console.log("Products successfully loaded..");
                    }, () => {
                        console.warn("Error loading products..")
                    }));
                    await dispatch(loadComboOffers(barId, (comboOffers) => {
                        console.log("Combo Offers successfully loaded..");
                        setSessionComboOffers(JSON.stringify(comboOffers));
                        setProductsC(comboOffers)
                    }, () => {
                        console.warn("Error loading combo offers..")
                    }));
                    await dispatch(loadCategories(barId, user, false,(categories) => {
                        console.log("Combo Offers successfully loaded..");
                        setSessionCategories(JSON.stringify(categories));
                        setCategories(categories)
                    }, () => {
                        console.warn("Error loading combo offers..")
                    }));
                done = true;
                }
            }
            asyncEffect().then(() => {
                if (menuSelected) {
                    setDataLoaded(true);
                    console.log(done && "done loading data.")
                }
            });
        }, [user, menuSelected]
    );


    useEffect(() => {
            let done = false;
            const asyncEffect = async () => {
                if (menuSelected) {

                    console.log("WTF", menuSelected, menuSelected.categories)
                    for(let i = 0; i < menuSelected.categories.length; i++) {
                        console.log("WHAT");
                        let catLatest = findItem('menu_group_id', menuSelected.categories[i].category.menu_group_id, categories)
                        console.log("latest cat", catLatest);
                        if (catLatest) {
                            menuSelected["categories"][i]["category"].name = catLatest.name;
                            menuSelected["categories"][i]["category"].de_name = catLatest.de_name;
                            menuSelected["categories"][i]["category"].eng_name = catLatest.eng_name;
                            menuSelected["categories"][i]["category"].it_name = catLatest.it_name;
                            menuSelected["categories"][i]["category"].hr_name = catLatest.hr_name;
                        }
                        for (let j = 0; j < menuSelected.categories[i].products.length; j++) {
                            let prodLatest = findItem('product_id', menuSelected.categories[i].products[j].product_id, products)
                            if (prodLatest) {
                                menuSelected["categories"][i]["products"][j].name = prodLatest.name;
                                menuSelected["categories"][i]["products"][j].de_name = prodLatest.de_name;
                                menuSelected["categories"][i]["products"][j].eng_name = prodLatest.eng_name;
                                menuSelected["categories"][i]["products"][j].it_name = prodLatest.it_name;
                                menuSelected["categories"][i]["products"][j].hr_name = prodLatest.hr_name;

                                //TODO: add descriptions if needed
                            }
                        }
                    }
                    done = true;

                    console.log("PARSING DONE !!!", menuSelected);
                    await dispatch(setCreateMenuActive(menuSelected));
                    await dispatch(setActiveBar(menuSelected.bar));
                    onNavigateTo(PAGES.CREATE_MENU);
                }
            }
            if (dataLoaded) {
                asyncEffect().then(() => {
                    console.log(done && "done with data.")
                });
            }
        }, [dataLoaded/*, categories, products, productsC*/]
    );

    useEffect(() => {
        dispatch(setCreateMenuActive(null));
    }, []);

    const deleteMenuFromStorage = (localId) => {
        deleteLocalMenu(localId)
        getLocalStorageObject(LOCAL_STORAGE_KEYS.LOCAL_MENUS_IN_EDITING)
        setMenus(getLocalMenus())
    }

    function Item(props) {
        //console.log(props.value);
        const item = props.value;
        console.log(item);
        if (!item || !item?.bar) {
            return <></>
        }

        return (
            <li onClick={() => {
            }} className={'row'} style={{left: `${sidebarWidth + 50}px`}}>
                <div className={'row-bar'}>{parseMapCaretChars(item?.bar?.name)}</div>
                <div className={'row-date'}>{displayDate(item.createdDate)}</div>
                <div className={'row-date'}>{displayDate(item.dateValidFrom)}</div>
                <div className={'row-dimension'}>{printDimensions(item)}</div>
                <div className={'row-leading-brand'}>{parseLeadingBrand(item.leading_brand)}</div>

                {isUserCaretaker(user)}
                <div className={'row-edit'} style={{visibility: isUserCaretaker(user) || isUserBarRepresentative(user) || isUserOwner(user) ? 'visible' : 'hidden'}}><a
                    className={'a-icon'}
                    onClick={async () => await navigateAndLoadEditMenu(PAGES.CREATE_MENU, item)}><IconEdit
                    className={'icon'}
                    width={'24px'} height={'24px'}
                    title={'icon list'}/></a></div>
                <div className={'row-delete'} style={{visibility: isUserCaretaker(user) || isUserBarRepresentative(user) || isUserOwner(user) ? 'visible' : 'hidden'}}><a
                    className={'a-icon'}
                    onClick={() => deleteMenuFromStorage(item.localId)}><IconDelete
                    className={'icon'}
                    width={'24px'} height={'24px'}
                    title={'icon list'}/></a></div>
            </li>
        );
    }

    function renderTable(items) {
        const header = () => {
            return (
                <div className={'row-header'} style={{left: `${sidebarWidth + 50}px`}}>
                    <div className={'row-bar'}>Lokal</div>
                    <div className={'row-date'}>Datum izdelave</div>
                    <div className={'row-date'}>Datum veljavnosti</div>
                    <div className={'row-dimension'}>Dimenzija</div>
                    <div className={'row-leading-brand'}>Glavna znamka</div>
                    <div className={'row-edit'}
                         style={{visibility: isUserCaretaker(user) ? 'visible' : 'hidden'}}>Uredi
                    </div>
                    <div className={'row-delete'}></div>
                </div>
            );
        };
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul className={'item-list'} style={{left: `${sidebarWidth + 50}px`}}>
                    {items.map((item, index) => <Item key={index} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {header()}
                {list(items)}
            </>
        );
    }

    // return <></>

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page className={'main'} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            {/*<Sidebar/>*/}
            {/*<div className={'page'}>*/}
            <Header left={`${sidebarWidth + 50}px`} title={'NEODDANI CENIKI'}/>
            <div className={'body'}>
                {renderTable(menus)}
            </div>
            {/*</div>*/}
        </Page>
        </div>

}

export default MenusListLocal;

