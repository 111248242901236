import React, {useState, useEffect, createRef, useCallback, useRef} from "react";
import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import FileUpload from "../components/js/FileUpload";
import Input from "../components/js/Input";

import "../css/ProductBarEdit.css"
import * as CgIcons from "react-icons/cg";

import Modal from "../components/js/Modal";
import {useDispatch, useSelector} from "react-redux";
import {
    displayAlert,
    displayPrice,
    isSessionValid, isUserAdmin,
    isUserCaretaker,
    mapCaretChars,
    parsePostDecimalValue
} from "../helpers/helpers";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import {
    setActiveProduct,
    setCreateMenuActive, setSidebarWidth,
    updateProduct,
    updateProductActiveStatus
} from "../store/actions/actions";
import {useHistory} from "react-router-dom";
import Dropdown from "../components/js/Dropdown";
import {ALERGEN_OPTIONS, L10N_MENU} from "../constants/enums";
import * as AiIcons from "react-icons/ai";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";

import countrySI from "../assets/img/country_si.png";
import countryEN from "../assets/img/country_en.png";
import countryDE from "../assets/img/country_de.png";
import countryIT from "../assets/img/country_it.png";
import countryHR from "../assets/img/country_hr.png";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";

function Product(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let selectedProduct = useSelector(state => state.reducer.selectedProduct);
    const user = useSelector(state => state.reducer.user);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    const [image, setImage] = useState(selectedProduct?.picture);
    const [name, setName] = useState(null);
    const [nameEn, setNameEn] = useState(null);
    const [nameDe, setNameDe] = useState(null);
    const [nameIt, setNameIt] = useState(null);
    const [nameHr, setNameHr] = useState(null);
    const [descSlo, setDescSlo] = useState(null);
    const [descEng, setDescEng] = useState(null);
    const [descIt, setDescIt] = useState(null);
    const [descDe, setDescDe] = useState(null);
    const [descHr, setDescHr] = useState(null);
    const [category, setCategory] = useState(null);
    const [unit, setUnit] = useState(null);
    const [packaging, setPackaging] = useState(null);
    const [alergens, setAlergens] = useState(selectedProduct?.allergenes);
    const [allowProductUpdateAllergens, setAllowProductUpdateAllergens] = useState(false)
    const [selectedAlergen, setSelectedAlergen] = useState(null)
    const [selectedAlergens, setSelectedAlergens] = useState([])
    const [alcoholLevel, setAlcoholLevel] = useState(null);

    const [beer_cider_type, set_beer_cider_type] = useState(null);
    const [name_secondary, set_name_secondary] = useState(null);
    const [desc_secondary, set_desc_secondary] = useState(null);
    const [measure_unit_2, set_measure_unit_2] = useState(null);
    const [measure_unit_3, set_measure_unit_3] = useState(null);

    const [link, setLink] = useState(null);


    const [currentAlergenOptions, setCurrentAlergenOptions] = useState(ALERGEN_OPTIONS)

    const [isOwnBrand, setIsOwnBrand] = useState(selectedProduct?.own_brand ? selectedProduct.own_brand : false);
    const [isHappyHour, setIsHappyHour] = useState(selectedProduct?.happy_hour ? selectedProduct.happy_hour : false);
    const [happyHourShowAlways, setHappyHourShowAlways] = useState(selectedProduct?.happy_hour_show_always ? selectedProduct.happy_hour_show_always : false);
    const [happyHourPrice, setHappyHourPrice] = useState(selectedProduct?.happy_hour_price ? displayPrice(selectedProduct.happy_hour_price) : null);
    const [happyHourTimeStartEnd, setHappyHourTimeStartEnd] = useState(selectedProduct?.happy_hour_time_start && selectedProduct.happy_hour_time_end ? [selectedProduct.happy_hour_time_start, selectedProduct.happy_hour_time_end] : ['10:00', '11:00']);

    const [happyHourMon, setHappyHourMon] = useState(selectedProduct?.happy_hour_mon ? selectedProduct.happy_hour_mon : false);
    const [happyHourTue, setHappyHourTue] = useState(selectedProduct?.happy_hour_tue ? selectedProduct.happy_hour_tue : false);
    const [happyHourWed, setHappyHourWed] = useState(selectedProduct?.happy_hour_wed ? selectedProduct.happy_hour_wed : false);
    const [happyHourThu, setHappyHourThu] = useState(selectedProduct?.happy_hour_thu ? selectedProduct.happy_hour_thu : false);
    const [happyHourFri, setHappyHourFri] = useState(selectedProduct?.happy_hour_fri ? selectedProduct.happy_hour_fri : false);
    const [happyHourSat, setHappyHourSat] = useState(selectedProduct?.happy_hour_sat ? selectedProduct.happy_hour_sat : false);
    const [happyHourSun, setHappyHourSun] = useState(selectedProduct?.happy_hour_sun ? selectedProduct.happy_hour_sun : false);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    const onDeleteProduct = () => {
        console.log("DELETE PRODUCT!");
        selectedProduct.active = false;
        dispatch(updateProductActiveStatus(selectedProduct, () => {

            if (selectedProduct?.combo_offer) {
                displayAlert("Vmesni oglas uspešno odstranjen!")
                dispatch(setActiveProduct(null))
                onNavigateTo(PAGES.ADS_LIST);
            } else {
                displayAlert("Izdelek uspešno odstranjen!")
                dispatch(setActiveProduct(null))
                onNavigateTo(PAGES.PRODUCTS_LIST);
            }

            console.log("Product successfully deactivated!");
        }, () => {
            console.warn("Error deactivating product..")
        }));
    };

    useEffect(() => {
        dispatch(setCreateMenuActive(null));
    }, []);

    useEffect(() => {
        if (selectedProduct && image) {
            if (image === 'REMOVE') {
                selectedProduct.picture = null;
                setImage(null);
            } else {
                selectedProduct.picture = image;
            }
            console.log("PRODUCT DATA LOADED..:", selectedProduct);
            updateProductDispatch(selectedProduct);
        }
    }, [image]);

    useEffect(() => {
        let newCurrentOptions = []
        let newSelectedAlergens = []
        if (!!alergens && alergens !== '') {
            // calculate new current options to include options not available
            let alergensArray = alergens.split(",").map(function (item) {
                return item.trim();
            });
            for (const alergenOption of ALERGEN_OPTIONS) {
                if (alergensArray.includes(alergenOption.value)) {
                    newSelectedAlergens.push(alergenOption)
                } else {
                    newCurrentOptions.push(alergenOption)
                }
            }
            setCurrentAlergenOptions(newCurrentOptions)
            setSelectedAlergens(newSelectedAlergens)
        } else {
            setCurrentAlergenOptions(ALERGEN_OPTIONS)
            setSelectedAlergens([])
        }
        if (selectedProduct) {
            selectedProduct.allergenes = alergens;
            console.log("SELECTED ALERGENS", alergens, selectedAlergens, newSelectedAlergens);
            updateProductDispatch(selectedProduct);
        }
    }, [alergens])

    useEffect(() => {
        if (!!selectedAlergen) {
            let newAlergens = ''
            if (!!alergens) {
                newAlergens = alergens + ', ' + selectedAlergen
            } else {
                newAlergens = selectedAlergen
            }
            setAlergens(newAlergens)
            setSelectedAlergen(null)
        }
    }, [selectedAlergen])


    const removeAlergen = (removeValue) => {
        if (!!alergens && alergens !== '') {
            let alergensArray = alergens.split(",").map(function (item) {
                return item.trim();
            });
            let newAlergens = []
            for (const alergen of alergensArray) {
                if (alergen !== removeValue) {
                    newAlergens.push(alergen)
                }
            }
            setAlergens(newAlergens.join(', '))
        }
    }

    const updateProductDispatch = (product, shouldAlert) => {
        if (user.type !== 'ADMIN') {
            product.category_label = selectedProduct.category_label
        }
        dispatch(updateProduct(product, () => {
            console.log("Product successfully updated!");

            if (shouldAlert) {
                displayAlert("Vmesni oglas uspešno posodobljen!")
            }
        }, () => {
            if (shouldAlert) {
                displayAlert("Napaka pri posodabljanju vmesnega oglasa..")
            }
            console.warn("Error updating product..")
        }));
    }

    const handleImageUpload = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.addEventListener("load", function () {
                //console.log("load");
                //console.log(reader.result);
                setImage(reader.result);
            }, false);
            reader.readAsDataURL(file);
        }
    };

    const previewImage = (base64Data) => {
        base64Data = base64Data === 'REMOVE' ? null : base64Data;
        return <div className={'container-img-upload'}>
            <img className={'img-upload'} src={base64Data} alt="banner" height={'111px'}/>
            <CgIcons.CgCloseO onClick={() => {
                setImage("REMOVE")
            }} className={'icon-close'}/>
        </div>
    };

    const renderFileUpload = (title) => {
        return <><p className={'p-title-small'}>{title}</p>
            <FileUpload comboOffer button={'Izberi in naloži sliko'}
                        handleFile={(file) => handleImageUpload(file)}/>
            {image && previewImage(image)}
        </>
    };

    const renderOwnBrandSection = () => {
        console.log(!isUserCaretaker(user) || !isUserAdmin(user), isUserCaretaker(user), isUserAdmin(user));
        if (!(isUserCaretaker(user) || isUserAdmin(user))) {
            return <></>
        }
        return <div className={'happy-hour-container'}>
            <p className={'p-title-small'}>Lastna znamka</p>
            {renderIsOwnBrandCheckbox()}
        </div>
    }

    const renderIsOwnBrandCheckbox = () => {
        return (
            <div className={'checkbox-container'} style={{marginBottom: '12px', marginTop: 0}}>
                <label>
                    <input type="checkbox"
                           checked={isOwnBrand}
                           onChange={() => {
                               if (selectedProduct) {
                                   selectedProduct.own_brand = !isOwnBrand;
                                   updateProductDispatch(selectedProduct);
                               }
                               setIsOwnBrand(!isOwnBrand)
                           }}
                    />
                    Izdelek je del lastne znamke
                </label>
            </div>
        );
    };


    const renderHappyHourSection = () => {
        return (
            <div className={'happy-hour-container'}>
                <p className={'p-title-small'}>Happy hour</p>
                {renderIsHappyHourCheckbox()}
                <div className={'happy-hour-days'} style={{display: isHappyHour ? 'flex' : 'none'}}>
                    {renderHappyHourDayCheckbox("PON", happyHourMon, setHappyHourMon)}
                    {renderHappyHourDayCheckbox("TOR", happyHourTue, setHappyHourTue)}
                    {renderHappyHourDayCheckbox("SRE", happyHourWed, setHappyHourWed)}
                    {renderHappyHourDayCheckbox("ČET", happyHourThu, setHappyHourThu)}
                    {renderHappyHourDayCheckbox("PET", happyHourFri, setHappyHourFri)}
                    {renderHappyHourDayCheckbox("SOB", happyHourSat, setHappyHourSat)}
                    {renderHappyHourDayCheckbox("NED", happyHourSun, setHappyHourSun)}
                </div>
                {renderIsHappyHourShownAlwaysCheckbox()}

                <div className={"modal-body-split"}
                     style={{display: isHappyHour ? 'flex' : 'none', alignItems: 'flex-end'}}>
                    <div>
                        <p className={'p-title-small'}>Aktivno časovno obdobje</p>
                        <TimeRangePicker className={'time-picker'} onChange={(value) => {
                            if (selectedProduct) {
                                selectedProduct.happy_hour_time_start = value ? value[0] : null;
                                selectedProduct.happy_hour_time_end = value ? value[1] : null;
                                updateProductDispatch(selectedProduct);
                            }
                            setHappyHourTimeStartEnd(value);

                        }} value={happyHourTimeStartEnd}
                        />
                    </div>
                    <div style={{width: '100%', paddingTop: '20px'}}>
                        <p className={'p-title-small'}>Akcijska cena</p>
                        <Input white onSubmit={(value) => {
                            if (selectedProduct) {
                                selectedProduct.happy_hour_price = happyHourPrice ? parsePostDecimalValue(happyHourPrice) : null
                                console.log(selectedProduct.happy_hour_price, value);
                                updateProductDispatch(selectedProduct);
                            }
                            // setHappyHourPrice(value);
                        }} onBlur={(value) => {
                            if (selectedProduct) {
                                selectedProduct.happy_hour_price = happyHourPrice ? parsePostDecimalValue(happyHourPrice) : null
                                console.log(selectedProduct.happy_hour_price, value);
                                updateProductDispatch(selectedProduct);
                            }
                            // setHappyHourPrice(value);
                        }}
                               style={{marginBottom: 0}}
                               loadProduct
                               happy_hour_price
                               onChange={(value) => {
                                   setHappyHourPrice(value);
                               }}
                               placeholder={'Akcijska cena (€)'}/>
                    </div>
                </div>
            </div>
        );
    }

    const renderIsHappyHourCheckbox = () => {
        return (
            <div className={'checkbox-container'} style={{marginBottom: '12px', marginTop: 0}}>
                <label>
                    <input type="checkbox"
                           checked={isHappyHour}
                           onChange={() => {
                               if (selectedProduct) {
                                   selectedProduct.happy_hour = !isHappyHour;
                                   updateProductDispatch(selectedProduct);
                               }
                               setIsHappyHour(!isHappyHour)
                           }}
                    />
                    Happy hour izdelek
                </label>
            </div>
        );
    };

    const renderIsHappyHourShownAlwaysCheckbox = () => {
        return (
            <div className={'checkbox-container'} style={{
                marginBottom: '22px',
                paddingRight: '30px',
                display: isHappyHour ? 'flex' : 'none',
                alignItems: 'center'
            }}>
                <label>
                    <input type="checkbox"
                           checked={happyHourShowAlways}
                           onChange={() => {
                               if (selectedProduct) {
                                   selectedProduct.happy_hour_show_always = !happyHourShowAlways;
                                   updateProductDispatch(selectedProduct);
                               }
                               setHappyHourShowAlways(!happyHourShowAlways)
                           }}
                    />
                    Izdelek naj bo aktiven po redni ceni tudi izven časa happy hour ponudbe
                </label>
            </div>
        );
    };

    const renderHappyHourDayCheckbox = (day, value, setAction) => {
        return (
            <div className={'checkbox-container'} style={{marginBottom: '12px'}}>
                <label>
                    <input type="checkbox"
                           checked={value}
                           onChange={() => {
                               console.log(!value);
                               setAction(!value);

                               if (selectedProduct) {
                                   switch (day) {
                                       case "PON":
                                           selectedProduct.happy_hour_mon = !value;
                                           break;
                                       case "TOR":
                                           selectedProduct.happy_hour_tue = !value;
                                           break;
                                       case "SRE":
                                           selectedProduct.happy_hour_wed = !value;
                                           break;
                                       case "ČET":
                                           selectedProduct.happy_hour_thu = !value;
                                           break;
                                       case "PET":
                                           selectedProduct.happy_hour_fri = !value;
                                           break;
                                       case "SOB":
                                           selectedProduct.happy_hour_sat = !value;
                                           break;
                                       case "NED":
                                           selectedProduct.happy_hour_sun = !value;
                                           break;
                                   }

                                   updateProductDispatch(selectedProduct);
                               }
                           }}
                    />
                    {day}
                </label>
            </div>
        );
    };


    const [mainHeight, setMainHeight] = useState(null);
    useEffect(() => {
        setTimeout(() => {
            let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
            setMainHeight(documentSize.y);
        }, 350)
    }, [])

    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, []);

    let inputStyle = {marginBottom: '48px'};

    const renderProduct = () => {
        return <>
            <Header left={`${sidebarWidth + 50}px`} title={`PREGLED IZDELKOV / ${name}`} interactive delete
                    button={'Izbriši izdelek'}
                    action={onDeleteProduct}/>
            <div className={'container-column-2'}>
                <div className={'column-2'}>
                    <div
                        className={'country-label-container'}
                        style={{alignSelf: 'self-end', marginBottom: '-20px'}}>
                        <img src={countrySI} alt="SI" width={'25px'}/>
                    </div>
                    <Input white shadow loadProduct name onChange={setName} placeholder={'Slovenski naziv izdelka'}
                           label={'Slovenski naziv izdelka'}
                           style={inputStyle}
                           onBlur={(e) => {
                               console.log('Triggered because this input lost focus', e.target.value);
                               if (selectedProduct && name) {
                                   selectedProduct.name = mapCaretChars(name);
                                   updateProductDispatch(selectedProduct);
                               }
                           }}
                    />
                    <Input white loadProduct sloDesc onChange={setDescSlo} placeholder={'Slovenski opis'}
                           label={'Slovenski opis'}
                           style={inputStyle}
                           onBlur={(e) => {
                               console.log('Triggered because this input lost focus', e.target.value);
                               if (selectedProduct && descSlo) {
                                   selectedProduct.slo_descr = mapCaretChars(descSlo);
                                   updateProductDispatch(selectedProduct);
                               }
                           }}/>

                    {selectedProduct?.beer_cider_type ?
                        <>
                            <Input white loadProduct nameSec onChange={set_name_secondary}
                                   placeholder={'Sekundarno ime izdelka'}
                                   label={'Sekundarno ime izdelka (izdelek v paru)'}
                                   style={inputStyle}
                                   onBlur={(e) => {
                                       console.log('Triggered because this input lost focus', e.target.value);
                                       if (selectedProduct && name_secondary) {
                                           selectedProduct.name_secondary = mapCaretChars(name_secondary);
                                           updateProductDispatch(selectedProduct);
                                       }
                                   }}/>
                            <Input white loadProduct descSec onChange={set_desc_secondary}
                                   placeholder={'Sekundarni opis artikla (maksimalno število znakov 140)'}
                                   label={'Sekundarni opis artikla (izdelek v paru)'}
                                   style={inputStyle}
                                   onBlur={(e) => {
                                       console.log('Triggered because this input lost focus', e.target.value);
                                       if (selectedProduct && desc_secondary) {
                                           selectedProduct.desc_secondary = mapCaretChars(desc_secondary);
                                           updateProductDispatch(selectedProduct);
                                       }
                                   }}/>
                        </>
                        :
                        <></>}


                    <div
                        className={'country-label-container'}
                        style={{alignSelf: 'self-end', marginBottom: '-20px'}}>
                        <img src={countryEN} alt="EN" width={'25px'}/>
                    </div>
                    <Input white shadow loadProduct engName onChange={setNameEn} placeholder={'Angleški naziv izdelka'}
                           label={'Angleški naziv izdelka'}
                           style={inputStyle}
                           onBlur={(e) => {
                               console.log('Triggered because this input lost focus', e.target.value);
                               if (selectedProduct && nameEn) {
                                   selectedProduct.eng_name = mapCaretChars(nameEn);
                                   updateProductDispatch(selectedProduct);
                               }
                           }}
                    />
                    <Input white loadProduct engDesc onChange={setDescEng} placeholder={'Angleški opis'}
                           label={'Angleški opis'}
                           style={inputStyle}
                           onBlur={(e) => {
                               console.log('Triggered because this input lost focus', e.target.value);
                               if (selectedProduct && descEng) {
                                   selectedProduct.eng_descr = mapCaretChars(descEng);
                                   updateProductDispatch(selectedProduct);
                               }
                           }}/>
                    <div
                        className={'country-label-container'}
                        style={{alignSelf: 'self-end', marginBottom: '-20px'}}>
                        <img src={countryDE} alt="DE" width={'25px'}/>
                    </div>
                    <Input white shadow loadProduct deName onChange={setNameDe} placeholder={'Nemški naziv izdelka'}
                           label={'Nemški naziv izdelka'}
                           style={inputStyle}
                           onBlur={(e) => {
                               console.log('Triggered because this input lost focus', e.target.value);
                               if (selectedProduct && nameDe) {
                                   selectedProduct.de_name = mapCaretChars(nameDe);
                                   updateProductDispatch(selectedProduct);
                               }
                           }}
                    />
                    <Input white loadProduct deDesc onChange={setDescDe} placeholder={'Nemški opis'}
                           label={'Nemški opis'}
                           style={inputStyle}
                           onBlur={(e) => {
                               console.log('Triggered because this input lost focus', e.target.value);
                               if (selectedProduct && descDe) {
                                   selectedProduct.de_descr = mapCaretChars(descDe);
                                   updateProductDispatch(selectedProduct);
                               }
                           }}/>
                    <div
                        className={'country-label-container'}
                        style={{alignSelf: 'self-end', marginBottom: '-20px'}}>
                        <img src={countryIT} alt="IT" width={'25px'}/>
                    </div>
                    <Input white shadow loadProduct itName onChange={setNameIt}
                           placeholder={'Italijanski naziv izdelka'}
                           label={'Italijanski naziv izdelka'}
                           style={inputStyle}
                           onBlur={(e) => {
                               console.log('Triggered because this input lost focus', e.target.value);
                               if (selectedProduct && nameIt) {
                                   selectedProduct.it_name = mapCaretChars(nameIt);
                                   updateProductDispatch(selectedProduct);
                               }
                           }}
                    />
                    <Input white loadProduct itDesc onChange={setDescIt} placeholder={'Italijanski opis'}
                           label={'Italijanski opis'}
                           style={inputStyle}
                           onBlur={(e) => {
                               console.log('Triggered because this input lost focus', e.target.value);
                               if (selectedProduct && descIt) {
                                   selectedProduct.it_descr = mapCaretChars(descIt);
                                   updateProductDispatch(selectedProduct);
                               }
                           }}/>
                    <div
                        className={'country-label-container'}
                        style={{alignSelf: 'self-end', marginBottom: '-20px'}}>
                        <img src={countryHR} alt="HR" width={'25px'}/>
                    </div>
                    <Input white shadow loadProduct hrName onChange={setNameHr} placeholder={'Hrvaški naziv izdelka'}
                           label={'Hrvaški naziv izdelka'}
                           style={inputStyle}
                           onBlur={(e) => {
                               console.log('Triggered because this input lost focus', e.target.value);
                               if (selectedProduct && nameHr) {
                                   selectedProduct.hr_name = mapCaretChars(nameHr);
                                   updateProductDispatch(selectedProduct);
                               }
                           }}
                    />
                    <Input white loadProduct hrDesc onChange={setDescHr} placeholder={'Hrvaški opis'}
                           label={'Hrvaški opis'}
                           style={inputStyle}
                           onBlur={(e) => {
                               console.log('Triggered because this input lost focus', e.target.value);
                               if (selectedProduct && descHr) {
                                   selectedProduct.hr_descr = mapCaretChars(descHr);
                                   updateProductDispatch(selectedProduct);
                               }
                           }}/>

                </div>
                <div className={'column-2'} style={{paddingRight: '16px'}}>
                    {renderFileUpload('Slika izdelka')}
                    <div style={{height: '94px'}}/>
                    {user.type === 'ADMIN' &&
                    <Input white loadProduct category onChange={setCategory} placeholder={'Kategorija'}
                           label={'Kategorija'}
                           style={{...inputStyle, ...{fontWeight: 700}}}
                           onBlur={(e) => {
                               console.log('Triggered because this input lost focus', e.target.value);
                               if (selectedProduct && category) {
                                   selectedProduct.category_label = mapCaretChars(category);
                                   updateProductDispatch(selectedProduct);
                               }
                           }}/>}
                    <div className={'container-row-2'}>
                        <div className={'row-2'}>
                            <Input white loadProduct unit onChange={setUnit}
                                   placeholder={selectedProduct?.beer_cider_type ? 'Enota 1' : '0.5 L'}
                                   label={selectedProduct?.beer_cider_type ? 'Enota 1' : 'Enota'}
                                   style={inputStyle}
                                   onBlur={(e) => {
                                       console.log('Triggered because this input lost focus', e.target.value);
                                       if (selectedProduct && unit) {
                                           selectedProduct.measure_unit = unit;
                                           updateProductDispatch(selectedProduct);
                                       }
                                   }}/>
                            {
                                selectedProduct?.beer_cider_type ?
                                    <>
                                        <Input white loadProduct unit2 onChange={set_measure_unit_2}
                                               placeholder={'Enota 2'} label={'Enota 2'}
                                               style={inputStyle}
                                               onBlur={(e) => {
                                                   console.log('Triggered because this input lost focus', e.target.value);
                                                   if (selectedProduct && measure_unit_2) {
                                                       selectedProduct.measure_unit_2 = measure_unit_2;
                                                       updateProductDispatch(selectedProduct);
                                                   }
                                               }}/>
                                        <Input white loadProduct unit3 onChange={set_measure_unit_3}
                                               placeholder={'Enota 3'} label={'Enota 3'}
                                               style={inputStyle}
                                               onBlur={(e) => {
                                                   console.log('Triggered because this input lost focus', e.target.value);
                                                   if (selectedProduct && measure_unit_3) {
                                                       selectedProduct.measure_unit_3 = measure_unit_3;
                                                       updateProductDispatch(selectedProduct);
                                                   }
                                               }}/>
                                    </>
                                    :
                                    <></>
                            }

                            <Input white loadProduct packaging onChange={setPackaging} placeholder={'npr. steklenica'}
                                   label={'Pakiranje'}
                                   style={inputStyle}
                                   onBlur={(e) => {
                                       console.log('Triggered because this input lost focus', e.target.value);
                                       if (selectedProduct && packaging) {
                                           selectedProduct.packaging = mapCaretChars(packaging);
                                           updateProductDispatch(selectedProduct);
                                       }
                                   }}/>
                        </div>
                        <div className={'row-2'}>
                            <Dropdown loadAlergens label={'Alergeni'} alergensEdit onChange={setSelectedAlergen}
                                      placeholder={'Alergeni'} options={currentAlergenOptions} value={selectedAlergen}/>
                            <Input white loadProduct alcoPercentage onChange={setAlcoholLevel} placeholder={'40 %'}
                                   label={'Stopnja alkohola'}
                                   style={inputStyle}
                                   onBlur={(e) => {
                                       console.log('Triggered because this input lost focus', e.target.value);
                                       if (selectedProduct && alcoholLevel) {
                                           selectedProduct.alcohol_percent = alcoholLevel;
                                           updateProductDispatch(selectedProduct);
                                       }
                                   }}/>
                        </div>
                        <div className={'ad-bars-list'} style={{width: '48%'}}>
                            {selectedAlergens.map((alergen, b) => (
                                <div key={`${b}_${alergen.value}`}
                                     className={'bar-list-item'}
                                     onDoubleClick={() => console.log("remove item", alergen.value)}>
                                    <p className={'p-title-product'}>{alergen.label}</p>
                                    <div className={'container-icon-selected'} style={{marginLeft: '0px'}}>
                                        <div className={'remove-bar-icon'} onClick={() => {
                                            removeAlergen(alergen.value)
                                        }} style={{cursor: 'pointer'}}>
                                            <AiIcons.AiFillDelete/>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {renderOwnBrandSection()}
                        {renderHappyHourSection()}
                    </div>
                </div>
            </div>
        </>
    }

    const renderComboOffer = () => {
        return <>
            <Header left={`${sidebarWidth + 50}px`} title={`PREGLED VMESNIH OGLASOV / ${name}`} interactive delete
                    button={'Izbriši vmesni oglas'}
                    action={onDeleteProduct}/>

            <div className={'container-column-2'} style={{height: '100%'}}>
                <div className={'column-2'}>
                    <Input white shadow loadProduct name onChange={setName} placeholder={'Ime vmesne ponudbe'}
                           label={'Ime'}
                           style={inputStyle}
                           onSubmit={() => {
                           }}
                           onBlur={(e) => {
                               console.log('Triggered because this input lost focus', e.target.value);
                               if (selectedProduct && name) {
                                   selectedProduct.name = mapCaretChars(name);
                                   updateProductDispatch(selectedProduct, true);

                               }
                           }}/>
                    <Input white loadProduct link onChange={setLink} placeholder={'Link (url)'} label={'Link (url)'}
                           style={inputStyle}
                           onSubmit={() => {
                           }}
                           onBlur={(e) => {
                               console.log('Triggered because this input lost focus', e.target.value);
                               if (selectedProduct && link) {
                                   selectedProduct.link = link;
                                   updateProductDispatch(selectedProduct, true);
                               }
                           }}/>

                </div>
                <div className={'column-2'}>
                    {renderFileUpload('Slika izdelka')}
                    <div style={{height: '94px'}}/>
                </div>
            </div>
        </>
    }

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div ref={ref}><Page relative className={'main'} style={{height: `${mainHeight}px`}}
                                      onToggleSidebar={(shown) => {
                                          if (shown) {
                                              dispatch(setSidebarWidth(SIDEBAR_WIDTH))
                                          } else {
                                              dispatch(setSidebarWidth(0))
                                          }
                                      }}>
            <div className={'body'} style={{
                marginTop: !selectedProduct?.combo_offer ? '220px' : '140px',
                paddingLeft: `${sidebarWidth + 50}px`
            }}>
                {!selectedProduct?.combo_offer ? renderProduct() : renderComboOffer()}
            </div>
        </Page>
        </div>
}

export default Product;