import React, {useState, useEffect} from "react";

import '../css/LiveDigitalMenu.css';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom'
import {
    getAllQuestionnaires,
    getQuestion,
    getVoter,
    isRememberMeEnabled,
    loadBar,
    loadBarAds,
    loadBarL10NSupport,
    loadCredentials,
    loadLiveMenu,
    loadVoterCredentials,
    saveVoterCredentials,
    submitNewVote,
    submitNewVoter,
    submitNewVoteTicket,
    userLogin
} from "../store/actions/actions";
import {
    displayPrice,
    displayValidFromMenuFormat,
    getL10NAlergens,
    getL10NMenuPricesDisclaimer,
    getL10NMenuValidFrom,
    getL10NVat,
    hasItem,
    mapCaretChars,
    parseMapCaretChars,
} from "../helpers/helpers";

import '../assets/fonts/Poppins-Regular.ttf';

import countrySI from "../assets/img/country_si.png";
import countryEN from "../assets/img/country_en.png";
import countryDE from "../assets/img/country_de.png";
import countryIT from "../assets/img/country_it.png";
import countryHR from "../assets/img/country_hr.png";

//import barcaffeBcg from "../assets/img/barcaffe/barcaffe-bcg.png";
import barcaffeBcg from "../assets/img/barcaffe/questionnaire_bcg.png";
import barcaffeBcgWhite from "../assets/img/barcaffe-white.jpg";
import barcaffeLogo from "../assets/img/barcaffe/barcaffe.png";
import barcaffeLogoWhite from "../assets/img/barcaffe/barcaffe.png";
import footerBcg from "../assets/img/barcaffe/footer.png";
import barcaffeLogoHorizontal from "../assets/img/barcaffe/barcaffe-horizontal.png";

import iconFood from "../assets/img/barcaffe/i-food.png";
import iconCake from "../assets/img/barcaffe/i-cake.png";
import iconWine from "../assets/img/barcaffe/i-wine.png";
import iconDrink from "../assets/img/barcaffe/i-drink.png";
import iconTea from "../assets/img/barcaffe/i-tea.png";
import iconSnack from "../assets/img/barcaffe/i-snack.png";


import Collapsible from "react-collapsible";
import {IoIosArrowUp, IoIosArrowDown} from 'react-icons/io';
import {LEADING_BRAND} from "../components/js/ButtonGroupSelection";
import {L10N_FIELDS, L10N_MENU} from "../constants/enums";
import {ICONS, ICONS_BlACK} from "./Category";
import Input from "../components/js/Input";
import Loader from "react-loader-spinner";
import colors from "../constants/colors";
import Button from "../components/js/Button";
import Message, {ErrorType} from "../components/js/Message";
import {Redirect} from "react-router";
import {PAGES} from "../App";

const DISPLAY_AD_DELAY = 5000;
const DISPLAY_AD_SHOWN = 7000;
const DISPLAY_AD_HIDE_AFTER = DISPLAY_AD_DELAY + DISPLAY_AD_SHOWN;

const shouldDisplayL10NOption = (l10nKey, availableLanguages, override) => {
    if (!override && !availableLanguages || availableLanguages?.length === 0 || (availableLanguages?.length === 1 && (!availableLanguages[0]?.l10n) || availableLanguages[0]?.l10n === L10N_MENU.si.value)) {
        console.log("SHOULD DISPLAY", false, l10nKey, availableLanguages)
        return false;
    }
    let shouldDisplay = l10nKey === L10N_MENU.si.value
    if (shouldDisplay) {
        return true;
    }
    shouldDisplay = !override ? !!hasItem('l10n', 'l10n', l10nKey, availableLanguages) : true;

    console.log("SHOULD DISPLAY", shouldDisplay, l10nKey, availableLanguages)
    return shouldDisplay;
}


const parseSelectedCountryLabel = (country, selectAction, onActionCollapseL10NOptions, availableLanguages, override) => {
    const action = () => {
        selectAction(country)
        onActionCollapseL10NOptions()
    }
    if (!shouldDisplayL10NOption(country, availableLanguages, override)) {
        return <></>
    }

    switch (country) {
        case L10N_MENU.si.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countrySI} alt="SI" width={'25px'}/>
            </div>
        case L10N_MENU.en.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryEN} alt="EN" width={'25px'}/>
            </div>

        case L10N_MENU.de.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryDE} alt="DE" width={'25px'}/>
            </div>
        case L10N_MENU.it.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryIT} alt="IT" width={'25px'}/>
            </div>
        case L10N_MENU.hr.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryHR} alt="HR" width={'25px'}/>
            </div>
    }
}
let QUESTIONNAIRE = ["Espresso", "Machiatto", "Kavo z mlekom", "Cappuccino", "Belo kavo", "Drugo"]

function LiveDigitalMenu(props) {
    let dispatch = useDispatch();

    const {id} = useParams();
    console.log(id);
    let menu = useSelector(state => state.reducer.onlinePriceList);
    let bar = useSelector(state => state.reducer.onlineBar);

    //TESTING MAIN MENU BRAND STYLES
    /*
    if (menu) {
        menu.main_brand = 5;
    }

     */

    const question1name = 'Katero kavo ste pili:';
    const question2name = 'Kako bi svoj napitek ocenili?';

    const isRememberMeSet = useSelector(state => state.reducer.rememberMe);
    const savedCredentials = useSelector(state => state.reducer.savedCredentials);

    const [fullName, setFullName] = useState(null);
    const [tel, setTel] = useState(null);

    const [voter, setVoter] = useState(null);
    const [question1, setQuestion1] = useState(null);
    const [question2, setQuestion2] = useState(null);

    const [ticket1, setTicket1] = useState(null);
    const [ticket2, setTicket2] = useState(null);

    const [voteSubmitted, setVoteSubmitted] = useState(null);


    const [questionnaire, setQuestionnaire] = useState(false);

    const [consent, setConsent] = useState();
    const [dataAnalysis, setDataAnalysis] = useState();
    const [questionnaireSet, setQuestionnaireSet] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState();
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [error, setError] = useState(null);


    const [isL10NOptionsOpen, setIsL10NOptionsOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(L10N_MENU.si.value);
    const [availableLanguages, setAvailableLanguages] = useState(false);
    const [maxScore, setMaxScore] = useState(5);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedScore, setSelectedScore] = useState(0);

    const dispatchBarL10NSupport = (barId) => {
        dispatch(loadBarL10NSupport(barId, (l10n) => {
            console.log("Live menu l10n loaded..", l10n);
            setAvailableLanguages(l10n);
        }, () => console.warn("Error loading live menu l10n languages support...")));
    };


    const dispatchLoadLiveMenu = () => {
        dispatch(loadLiveMenu(id, () => {
            console.log("Live menu loaded..");
        }, () => console.warn("Error loading live menu...")));
    };

    const dispatchLoadBarData = (barId) => {
        dispatchBarL10NSupport(barId)
        dispatch(loadBar(barId, () => {
            console.log("Bar loaded..");
        }, () => console.warn("Error loading bar...")));
    };


    const loadVoter = () => {
        dispatch(getVoter(tel, (voter) => {
                setVoter(voter);
                console.log("Voter loaded..", voter);

                if (!voter || voter.error) {
                    let voterPostData = {
                        name: fullName,
                        surname: '',
                        telephone: tel,
                        email: '',
                    }

                    dispatch(submitNewVoter(voterPostData, (voter) => {
                        setVoter(voter);
                        console.log("Voter created..", voter);

                    }, () => console.warn("Error creating voter...")));
                }
            }, () => {
                console.warn("Error loading voter...");

                if (!voter || voter.error) {
                    let voterPostData = {
                        name: fullName,
                        surname: '',
                        telephone: tel,
                        email: '',
                    }

                    dispatch(submitNewVoter(voterPostData, (voter) => {
                        setVoter(voter);
                        console.log("Voter created..", voter);
                        saveVoterCredentials(fullName, tel);

                    }, () => console.warn("Error creating voter...")));
                }
            }
        ));
    };


    const loadQuestions = (name, name2) => {
        dispatch(getQuestion(name, (question) => {
            setQuestion1(question);
            console.log("question 1 loaded..", question);
        }, () => console.warn("Error loading question 1...")));
        dispatch(getQuestion(name2, (question) => {
            setQuestion2(question);
            console.log("question 2 loaded..", question);
        }, () => console.warn("Error loading question 2...")));
    };

    const loadQuestionnaires = () => {
        dispatch(getAllQuestionnaires((resData) => {
            setQuestionnaire(resData);
            console.log("Questionnaire", resData);
        }, () => console.warn("Error loading question 1...")));
    };

    useEffect(() => {
        dispatchLoadLiveMenu();
    }, [])
    useEffect(() => {
        if (menu) {
            console.log("ONLINE MENU:", menu)
            dispatchLoadBarData(menu.bar_id);
        }
    }, [menu])

    useEffect(() => {
        dispatch(loadVoterCredentials((credentials) => {
            setFullName(credentials.name);
            setTel(credentials.telephone)
        }));

        loadQuestionnaires();
        loadQuestions(question1name, question2name);
    }, []);


    useEffect(() => {
        //setFullName(savedCredentials.email);
        //setTel(savedCredentials.password)
    }, [savedCredentials])
    useEffect(() => {
        if (selectedOption && selectedScore && !questionnaireSet) {
            setQuestionnaireSet(true)
        }
        //setFullName(savedCredentials.email);
        //setTel(savedCredentials.password)
    }, [selectedOption, selectedScore])
    useEffect(() => {
        if (fullName && tel && consent && dataAnalysis && !voter) {
            loadVoter();
        }
        //setFullName(savedCredentials.email);
        //setTel(savedCredentials.password)
    }, [fullName, tel, dataAnalysis, consent])
    useEffect(() => {
        if (ticket1 && ticket2 && bar && voter && questionnaire) {
            let vote = {
                voter_id: voter.voter_id,
                bar_id: bar.bar_id,
                questionnaire_id: questionnaire.questionnaire_id,
                vote1_id: ticket1.vote_id,
                vote2_id: ticket2.vote_id,
                vote3_id: null,
            };

            dispatch(submitNewVote(vote, (result) => {
                setVoteSubmitted(result);
            }, () => {
                setVoteSubmitted(true); //TODO: remove! testing demo only
            }, () => {
                setVoteSubmitted(true); //TODO: remove! testing demo only
            }));
        }
        //setFullName(savedCredentials.email);
        //setTel(savedCredentials.password)
    }, [ticket1, ticket2])

    useEffect(() => {
        if (voteSubmitted) {
            console.log("Submitted new vote!");
        }
        //setFullName(savedCredentials.email);
        //setTel(savedCredentials.password)
    }, [voteSubmitted])


    const displayCollapsibleL10NHeader = (status) => {
        let arrowStyle = {width: '24px', height: '24px', color: parseSecondaryColor(LEADING_BRAND.BARCAFFE)};
        return (
            <div className={'collapsible-header l10n-options'} style={{justifyContent: 'flex-end'}}>
                {parseSelectedCountryLabel(selectedLanguage, setSelectedLanguage, () => onActionCollapseL10NOptions(false), availableLanguages, true)}
                {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
            </div>
        );
    }

    const onActionCollapseL10NOptions = (state) => {
        setIsL10NOptionsOpen(state);
    }

    const displayCollapsibleArrow = (name, c, status) => {
        let arrowStyle = {width: '24px', height: '24px', color: parseSecondaryColor(LEADING_BRAND.BARCAFFE)};
        return (
            <div className={'collapsible-header'}>
                <p className={'online-menu-title-category'}
                   style={{
                       letterSpacing: parseLetterSpacingRegular(LEADING_BRAND.BARCAFFE),
                       color: parseSecondaryColor(LEADING_BRAND.BARCAFFE),
                       fontFamily: parseFontTitle(LEADING_BRAND.BARCAFFE),
                   }}>{parseMapCaretChars(name)}</p>
                <hr className={'online-menu-category-line'}/>
                {/*status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>*/}
            </div>
        );
    }

    const renderHeader = () => {
        return (
            <div className={'logo-row'} style={{alignSelf: 'center', height: 'fit-content', padding: 0}}>
                <img src={parseLogo(LEADING_BRAND.BARCAFFE)} alt="logo"/>
                <div className={'logo-row-inner'}>
                    <img src={bar?.logo ? bar.logo : ''} alt="bar logo"/>
                </div>
            </div>
        );
    };

    const renderOptions = () => {
        if (!QUESTIONNAIRE || QUESTIONNAIRE.length <= 0) {
            return null;
        }
        return (
            <div className={'pool-vertical'} style={{alignSelf: 'center', height: 'fit-content', padding: 0}}>
                {menu.categories.map((option, o) => (
                    <div key={`${o}_${option}`} className={'category-outer-container'}>
                        <p className={'online-menu-title-product'} style={{
                            textAlign: 'left',
                            lineHeight: '13px',
                            padding: '8px',
                            letterSpacing: parseLetterSpacingRegular(LEADING_BRAND.BARCAFFE),
                            fontSize: parseFontSizeRegular(LEADING_BRAND.BARCAFFE),
                            fontFamily: parseFontTitleProd(LEADING_BRAND.BARCAFFE),
                            color: parsePrimaryColor(LEADING_BRAND.BARCAFFE)
                        }}>{option}</p>
                    </div>
                ))}
            </div>
        );
    };


    const renderFooter = () => {
        if (!bar) {
            return null;
        }
        return (
            <div className={'footer-digital-menu questionnaire'} style={{backgroundImage: `url(${footerBcg})`}}>
                <div className={'logo-row'}>
                    <img src={barcaffeLogoHorizontal} alt="logo"/>
                    <img src={bar?.logo ? bar.logo : ''} alt="bar logo"/>
                </div>
                <div>
                    <p className={'online-menu-text-footer bar-info'}
                       style={{fontFamily: parseFontLight(LEADING_BRAND.BARCAFFE)}}>{`${parseMapCaretChars(bar.name)} | ${parseMapCaretChars(bar.address)} | ${getL10NVat(selectedLanguage)} ${parseMapCaretChars(bar.tax_number)} | ${getL10NMenuPricesDisclaimer(selectedLanguage)} ${getL10NMenuValidFrom(selectedLanguage)} ${displayValidFromMenuFormat(menu.valid_from)}`}</p>
                </div>
            </div>
        );
    };

    const parseBackgroundColor = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
                return 'rgba(23, 34, 41, 0.95)'
            case LEADING_BRAND.BARCAFFE_WHITE:
                return '#FFF';
        }
    };
    const parsePrimaryColor = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE_WHITE:
                return '#333F48';
            case LEADING_BRAND.BARCAFFE:
                return '#fff';
        }
    };
    const parseSecondaryColor = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
                return '#fff';
            case LEADING_BRAND.BARCAFFE_WHITE:
                return '#333F48';
        }
    };
    const parseSecondaryFontWeight = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
                return 'normal';
            case LEADING_BRAND.BARCAFFE_WHITE:
                return '600';
        }
    };

    const parseBackground = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
                return barcaffeBcg;
            case LEADING_BRAND.BARCAFFE_WHITE:
                return barcaffeBcg;
        }
    };
    const parseLogo = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
                return barcaffeLogoWhite;
            case LEADING_BRAND.BARCAFFE_WHITE:
                return barcaffeLogo;
        }
    };

    const parseCategoryIcon = (i, menuType) => {
        const parseIconSet = (menuType) => {
            switch (Number(menuType)) {
                case LEADING_BRAND.BARCAFFE_WHITE:
                    return ICONS_BlACK
                case LEADING_BRAND.BARCAFFE:
                default:
                    return ICONS;
            }
        }
        if (!i && (i !== 0 || i !== '0')) {
            return null;
        } else {
            i = Number(i);
        }
        let icons = parseIconSet(menuType)
        return icons[i];
    };

    const parseFontTitle = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return 'Poppins-Bold';
        }
    };
    const parseFontTitleProd = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return 'Poppins';
        }
    };
    const parseFontLight = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return 'Poppins-Thin';
        }
    };
    const parseFontPrice = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return 'Poppins';
        }
    };
    const parseFontUnit = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return 'Poppins';
        }
    };
    const parseFontSizeRegular = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return '12px';
        }
    };
    const parseFontSizeInfo = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return '11px';
        }
    };
    const parseLetterSpacingRegular = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return '1.2px';
        }
    };
    const parseLetterSpacingTight = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return '0.85px';
        }
    };
    const parseLineHeight = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return '12px';
        }
    };

    const parseBodyPadding = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE_WHITE:
            case LEADING_BRAND.BARCAFFE:
                return '27px';
        }
    }

    const parseBackgroundStyle = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE_WHITE:
                return {
                    //backgroundColor: parseBackgroundColor(brand),
                    backgroundImage: `url(${parseBackground(brand)})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionY: 'top',
                    backgroundPositionX: 'right'
                };
            case LEADING_BRAND.BARCAFFE:
                return {
                    // backgroundColor: parseBackgroundColor(brand),
                    backgroundImage: `url(${parseBackground(brand)})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionY: 'center',
                    backgroundPositionX: 'center'
                };
        }
    };

    const onSubmit = () => {
        setLoadingSpinner(true);
        const onSuccessLogin = (resData) => {
            console.log('redirect success');
            setSubmitSuccessful(true);
            setLoadingSpinner(false);
        };
        const UserAccessNotGranted = () => {
            console.log("Error fetching data");
            setError(ErrorType.USER_ACCESS_NOT_GRANTED);
            setLoadingSpinner(false);
        };
        const onErrorUnauthorized = () => {
            console.log("Unauthorized");
            setError(ErrorType.UNAUTHORIZED);
            setLoadingSpinner(false);
        };

        let ticket1Data = {
            question_id: question1.question_id,
            voter_id: voter.voter_id,
            score: selectedOption
        };
        let ticket2Data = {
            question_id: question2.question_id,
            voter_id: voter.voter_id,
            score: selectedScore
        };
        dispatch(submitNewVoteTicket(ticket1Data, (ticket) => {
            setTicket1(ticket);
        }, UserAccessNotGranted, onErrorUnauthorized));
        dispatch(submitNewVoteTicket(ticket2Data, (ticket) => {
            setTicket2(ticket);
        }, UserAccessNotGranted, onErrorUnauthorized));
    };

    const onInputChange = (value, onInputAction) => {
        setError(null);
        onInputAction(value);
    }

    const renderL10NOption = (l10nKey, imgData) => {
        if (!shouldDisplayL10NOption(l10nKey, availableLanguages, false)) {
            return <></>;
        }
        return (
            <div onClick={() => {
                onActionCollapseL10NOptions(false)
                setSelectedLanguage(l10nKey)
            }}
                 className={'country-label-container'}
                 style={{alignSelf: 'center'}}>
                <img src={imgData} alt={l10nKey} width={'25px'}/>
            </div>
        );
    }

    const renderL10NOptions = () => {
        if (!availableLanguages || (availableLanguages?.length === 1 && (!availableLanguages[0]?.l10n || availableLanguages[0]?.l10n === 'SI')) || availableLanguages.length === 2 && (availableLanguages[0]?.l10n === 'SI' && !availableLanguages[1]?.l10n)) {
            return <></>;
        }

        return (
            <Collapsible className={'collapsible-menu-listing l10-options'}
                         transitionTime={400}
                         onClosing={() => onActionCollapseL10NOptions(false)}
                         onOpening={() => onActionCollapseL10NOptions(true)}
                         open={isL10NOptionsOpen}
                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                         trigger={displayCollapsibleL10NHeader(false)}
                         triggerWhenOpen={displayCollapsibleL10NHeader(true)}>
                <div className={'l10n-options-content'}>
                    {renderL10NOption(L10N_MENU.si.value, countrySI)}
                    {renderL10NOption(L10N_MENU.en.value, countryEN)}
                    {renderL10NOption(L10N_MENU.de.value, countryDE)}
                    {renderL10NOption(L10N_MENU.it.value, countryIT)}
                    {renderL10NOption(L10N_MENU.hr.value, countryHR)}
                </div>
            </Collapsible>
        );
    }

    const renderOptionsRow = (menu, o, option) => {

        let className = `online-menu-item-product-container questionnaire ${selectedOption === option ? 'selected' : ''}`
        return <>
            <div key={`${o}_${option}`}
                 className={'online-menu-item-product'}>
                <div className={className} onClick={() => setSelectedOption(option)}>
                    <div className={'online-menu-item-product-left'}>
                        <div className={'online-menu-item-product-left-row'}>
                            <p className={'online-menu-title-product'} style={{
                                letterSpacing: parseLetterSpacingRegular(LEADING_BRAND.BARCAFFE),
                                fontSize: parseFontSizeRegular(LEADING_BRAND.BARCAFFE),
                                fontFamily: parseFontTitleProd(LEADING_BRAND.BARCAFFE),
                                color: parsePrimaryColor(LEADING_BRAND.BARCAFFE)
                            }}>{parseMapCaretChars(option)}</p>
                        </div>
                    </div>

                </div>
            </div>
        </>

    }

    const renderScoreRow = (score, s) => {
        if (!score || score <= 0) {
            return null;
        }
        let className = `online-menu-item-score ${selectedScore === score ? 'selected' : ''}`
        return (
            <div onClick={() => setSelectedScore(score)} className={className}
                 style={{alignSelf: 'center', height: 'fit-content', padding: 0}}>
                <p style={{
                    textAlign: 'center',
                    lineHeight: '13px',
                    padding: '8px',
                    letterSpacing: parseLetterSpacingRegular(LEADING_BRAND.BARCAFFE),
                    fontSize: parseFontSizeRegular(LEADING_BRAND.BARCAFFE),
                    fontFamily: parseFontTitleProd(LEADING_BRAND.BARCAFFE),
                    color: parsePrimaryColor(LEADING_BRAND.BARCAFFE)
                }}>{score}</p>
            </div>
        );
    };

    const renderErrorMessage = () => {
        switch (error) {
            case ErrorType.UNAUTHORIZED:
                return <Message error type={ErrorType.UNAUTHORIZED}/>
            /*case ErrorType.ERROR_FETCHING_DATA:
                return <Message error type={ErrorType.ERROR_FETCHING_DATA}/>

             */
            case ErrorType.USER_ACCESS_NOT_GRANTED:
                return <Message error type={ErrorType.USER_ACCESS_NOT_GRANTED}/>
            default:
                return null;
        }
    };

    const renderConsentCheckbox = () => {
        return (
            <div className={'checkbox-container'}>
                <label>
                    <input type="checkbox"
                           checked={consent}
                           onChange={() => {
                               console.log(!consent);
                               if (fullName && tel) {
                                   setConsent(!consent)
                               }
                           }}
                    />
                    <span>Strinjam se s pravili nagradne igre</span>
                </label>
            </div>
        );
    };

    const renderDataUsageCheckbox = () => {
        return (
            <div className={'checkbox-container'}>
                <label>
                    <input type="checkbox"
                           checked={dataAnalysis}
                           onChange={() => {
                               console.log(!dataAnalysis);
                               if (fullName && tel) {
                                   setDataAnalysis(!dataAnalysis)
                               }
                           }}
                    />
                    <span>
                        S prijavo potrjujem, da se strinjam z obdelavo mojih osebnih podatkov, vključno z elektronskim naslovom, ki sem jih podal v zgornjem kontaktnem obrazcu, vse v skladu s Pravili o varovanju zasebnosti ter Politiko piškotkov, za namen obveščanja o aktualnih in bodočih vsebinah na Barcaffe youtube kanalu.
                    </span>
                </label>
            </div>
        );
    };

    /*
    if (loginSuccessful) {
        console.log('redirect');
        return <Redirect to={PAGES.HOME} push={false}/>
    }
     */
    if (document.querySelector(".placeholder")) {
        document.querySelector(".placeholder").style.pointerEvents = "none";
    }
    const renderVoter = () => {
        console.log(fullName)
        return (
            <>
                <div className={'form-container'}>
                    <p style={{opacity: !fullName ? 1 : 0}} className={'placeholder placeholder-name'}>Ime in
                        Priimek:</p>
                    <Input fullName
                           loadUserActivation
                           onChange={(value) => onInputChange(value, setFullName)}
                        //onSubmit={onSubmit}
                           label={''}
                           style={{
                               marginBottom: '36px',
                               background: 'transparent',
                               borderBottom: '2px solid #fff',
                               paddingLeft: 0,
                           }}/>
                    <p style={{opacity: !tel ? 1 : 0}} className={'placeholder placeholder-tel'}>Mobilni telefon:</p>
                    <Input tel
                           loadUserActivation
                           onChange={(value) => onInputChange(value, setTel)} //onSubmit={onSubmit}
                           placeholder={''} label={''}
                           style={{
                               marginBottom: '10px',
                               background: 'transparent',
                               borderBottom: '2px solid #fff',
                               paddingLeft: 0,
                           }}/>
                    {renderErrorMessage()}
                    {renderConsentCheckbox()}
                    {renderDataUsageCheckbox()}
                    {loadingSpinner ?
                        <Loader
                            type="TailSpin"
                            color={colors.primary}
                            height={36}
                            width={36}
                            style={{paddingTop: 52, paddingBottom: 45}}
                        /> :
                        <Button text={'PRIJAVA'} login action={onSubmit} style={{
                            height: '40px',
                            borderRadius: '5px',
                            margin: '20px 0',
                            marginBottom: '200px'
                        }}/>
                    }
                </div>
            </>
        );
    };

    const renderQuestionnaire = () => {
        return (
            <>
                <p className={'questionnaire main-title'}>
                    Oceni svojo kavo in sodeluj v nagradni igri za gram zlata!
                </p>
                <div className={'questionnaire divider'}>
                    <br/>
                </div>
                <p className={'questionnaire title'}>Katero kavo ste pili:</p>
                <div className={'online-menu-category'}>

                    <div className={'online-menu-item-category'}>
                        {QUESTIONNAIRE.map((option, o) => (
                            renderOptionsRow(menu, o, option)
                        ))}
                    </div>
                </div>
            </>

        );
    };

    const renderQuestionnairePage = () => {
        return (
            <>
                {renderQuestionnaire()}
                {renderScorePool()}
            </>

        );
    };

    const renderScorePool = () => {
        if (!maxScore || maxScore <= 0) {
            return null;
        }
        let scoreArr = [];
        for (let i = 0; i < maxScore; i++) {
            scoreArr.push(i + 1)
        }
        if (!scoreArr || scoreArr.length === 0) {
            return null;
        }
        console.log(scoreArr)

        return (
            <>
                <p className={'questionnaire title'}> Kako bi svoj napitek
                    ocenili? <span>(1 - slaba, 5 - odlična)</span></p>
                <div className={'online-menu-score'}>
                    {scoreArr.map((score, s) => (
                        renderScoreRow(score, s)
                    ))}
                </div>
            </>
        );
    };

    const renderThankYouPage = () => {
        return (
            <div>
                <p className={'questionnaire title'}>Hvala za oddan glas in participaciji v nagradni igri.</p>
            </div>
        );
    }


    if (!menu || !QUESTIONNAIRE || QUESTIONNAIRE.length <= 0) {
        return <></>;
    }

    let className = 'template-0 questionnaire';
    className = `${className}`
    return <div className={`main ${className}`} style={{background: 'rgba(1, 1, 1, 1)'}}>
        <div className={`background fixed ${className}`}
             style={{...parseBackgroundStyle(LEADING_BRAND.BARCAFFE), ...{backgroundPosition: '50% 160%'}}}/>
        <div className={'page digital-menu'}>
            <div className={'body'} style={{
                paddingLeft: parseBodyPadding(LEADING_BRAND.BARCAFFE),
                paddingRight: parseBodyPadding(LEADING_BRAND.BARCAFFE)
            }}>
                {/*renderL10NOptions()*/}
                {/*renderAdBanner()*/}
                {renderHeader()}
                {
                    voteSubmitted ? renderThankYouPage() :
                    questionnaireSet ? renderVoter() : renderQuestionnairePage()
                }
                {renderFooter()}
            </div>
        </div>
    </div>
}

export default LiveDigitalMenu;