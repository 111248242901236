import React, {useState, useEffect, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import FileUpload from "../components/js/FileUpload";
import Input from "../components/js/Input";

import "../css/CreateComboOffer.css"
import * as CgIcons from "react-icons/cg";

import {
    createNewAd,
    loadAds,
    setCreateMenuActive, setSidebarWidth,
} from "../store/actions/actions";
import Button from "../components/js/Button";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import {hasItem, isSessionValid, mapCaretChars, parseMapCaretChars} from "../helpers/helpers";
import {BANNER_WIDTH} from "./Ad";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import {useHistory} from "react-router-dom";

function CreateAd(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let ads = useSelector(state => state.reducer.ads);
    const [image, setImage] = useState(null);
    const [name, setName] = useState(null);
    const [link, setLink] = useState(null);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    const [createTrigger, setCreateTrigger] = useState(false);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    useEffect(() => {
        dispatch(setCreateMenuActive(null));
    }, []);

    useEffect(() => {
        dispatch(loadAds(() => {
            console.log("Ads successfully loaded..");
        }, () => {
            console.warn("Error loading ads..")
        }));

    }, []);

    useEffect(() => {
        console.log("Ads:", ads);

        if (ads && ads.length >= 0 && createTrigger && name && link) {
            const asyncEffect = async () => {
                let ad = {
                    name: mapCaretChars(name),
                    link: link,
                    image: image,
                };
                if (!hasItem('name', 'name', ad.name, ads)) {
                    await dispatch(createNewAd(ad,

                        async () => {
                            alert(`Oglas '${name}' uspešno dodan!`);

                            await dispatch(loadAds(() => {
                                console.log("Ads successfully loaded..");

                                onNavigateTo(PAGES.ADS_LIST)
                            }, () => {
                                console.warn("Error loading ads..")
                            }));
                        }, () => {
                        }))
                } else {
                    alert(`Oglas z imenom "${parseMapCaretChars(ad.name)}" že obstaja v bazi!`);
                    setCreateTrigger(false);

                }
            }
            asyncEffect().then(() => console.log("Ad post done.."));
        }
    }, [createTrigger]);

    const handleBannerUpload = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.addEventListener("load", function () {
                console.log("load");
                console.log(reader.result);
                setImage(reader.result);
            }, false);
            reader.readAsDataURL(file);
        }
    };

    const previewImage = (base64Data) => {
        return <div className={'img-banner-container'}>
            <img className={'img-banner-upload'} src={`${base64Data}`} alt="banner"
                 width={`${BANNER_WIDTH}px`}/>
            <CgIcons.CgCloseO onClick={() => {
                setImage(null)
            }} className={'icon-close'}/>
        </div>
    };

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page relative className={'main'} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            {/*<Sidebar/>*/}
            {/*<div className={'page relative'}>*/}
                <div className={'body'} style={{paddingLeft: `${sidebarWidth + 50}px`}}>
                    <Header left={`${sidebarWidth + 50}px`} title={'IZDELAVA OGLASA'}/>

                    <div className={'container-column-2'}>
                        <div className={'column-2'}>
                            <Input white onChange={setName} placeholder={''} label={'Ime oglasa'}
                                   style={{width: '700px'}}/>
                            <Input white onChange={setLink} placeholder={''} label={'Link oglasa'}
                                   style={{marginBottom: '0px', width: '700px'}}/>

                            <p className={'p-title-small'} style={{marginTop: '60px'}}>Dodajte sliko oglasa</p>
                            <FileUpload comboOffer button={'Izberi in naloži sliko'}
                                        handleFile={(file) => handleBannerUpload(file)}/>
                            {image && previewImage(image)}
                            <Button text={'USTVARI OGLAS'} confirm action={() => {
                                setCreateTrigger(true)
                            }} style={{
                                margin: 0, marginTop: !image ? '36px' : '-80px', width: '350px'
                            }}/>
                        </div>
                    </div>
                </div>
            {/*</div>*/}
        </Page>
        </div>
}

export default CreateAd;