import React, {useEffect, useState} from "react";
import '../css/Header.css'
import Button from "./Button";
import {mapCaretChars, parseMapCaretChars} from "../../helpers/helpers";
import Input from "./Input";
import {removeFolder, updateFolderDescription} from "../../store/actions/actions";
import {useDispatch} from "react-redux";

function Header(props) {
    let dispatch = useDispatch();
    const [description, setDescription] = useState(props.sharepoint_folder_description);

    useEffect(() => {
        setDescription(props.sharepoint_folder_description);
        console.log("DESC:", props.sharepoint_folder_description);
    }, [props.sharepoint_folder_description])

    useEffect(() => {
        console.log(props.folder);
    }, [props.folder])

    useEffect(() => {
        console.log("EDIT DESC", props.enable_edit, props.edit_description);

    }, [props.enable_edit, props.edit_description])

    /*
    useEffect(() => {
    }, [props.rerender])
     */

    if (props.welcome) {
        return (
            <div className={'header-default header-welcome'} style={{left: props.left}}>
                <p className={'p-welcome'}>{props.welcome}</p>
            </div>
        )
    } else if (props.step) {
        return (<div className={'header-default header-create-menu'} style={{left: props.left}}>
            <p className={'header-title'}>{props.title}</p>
            <p className={'step'}>{props.step}</p>
        </div>)
    } else if (props.interactive) {
        return (<div className={'header-default header-interactive'} style={{left: props.left}}>
            <p className={'header-title'}>{props.title}</p>
            {props.button2 && props.action2 ? <div className={'header-double-action'}>
                    <Button text={props.button2} add={props.add} delete={props.delete} action={props.action2}/>
                    <Button text={props.button} add={props.add} delete={props.delete} action={props.action}/>
                </div> :
                <Button text={props.button} add={props.add} delete={props.delete} action={props.action}/>
            }

        </div>)
    } else if (props.sharepoint_folder_description || props.sharepoint_folder_description === '') {
        return (
            <>
                <p className={'header-pwd'} style={{left: props.left}}>{parseMapCaretChars(props.path)}</p>
                <div className={'header-default header-sharepoint-folder'} style={{left: props.left}}>
                    <p className={'header-title'}>{parseMapCaretChars(props.title)}</p>
                    <div style={{
                        position: 'absolute',
                        right: '0',
                        top: '12px'
                    }}
                         onClick={(e) => {
                             console.log(e.currentTarget);
                             console.log(description)
                             props.edit_description_action(description);
                         }}>
                        <Input onSubmit={() => {
                        }}
                               style={{
                                   height: '50px',
                                   padding: '12px',
                                   minWidth: '350px',
                                   minHeight: '64px',
                                   maxWidth: 'fit-content',
                                   fontSize: '14px'
                               }}
                               textarea
                               white
                               disabled={!props.enable_edit || !props.edit_description}
                               sharepoint_folder_description={props.sharepoint_folder_description}
                               onChange={setDescription}
                               onBlur={(e) => {
                                   console.log('Triggered because this input lost focus', e.target.value);
                                   if (description) {
                                       console.log(description);
                                       props.edit_description_action(false);

                                       dispatch(updateFolderDescription(props.folder.folder_id, description, (res) => {
                                           console.log("Folder successfully updated..", res);
                                       }, (err) => {
                                           console.warn("Error updating folder..", err)
                                       }));
                                       //updateProductDispatch(selectedProduct);
                                   }
                               }}
                               placeholder={''}/>
                    </div>

                    {/*<p className={'header-description'}>{parseMapCaretChars(props.sharepoint_folder_description)}</p>*/}
                </div>
            </>

        )
    } else if (props.sharepoint && props.path) {
        return (
            <>
                <p className={'header-pwd'} style={{left: props.left}}>{parseMapCaretChars(props.path)}</p>
                <div className={'header-default header-sharepoint-folder'} style={{left: props.left}}>
                    <p className={'header-title'}>{parseMapCaretChars(props.title)}</p>
                </div>
            </>
        )

    } else {
        return (<div className={'header-default'} style={{left: props.left}}>
            <p className={'header-title'}>{props.title}</p>
        </div>)
    }
}

export default Header;