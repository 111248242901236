import React, {useEffect, useState} from "react";

import '../css/Message.css'

export const ErrorType = {
    SUCCESS: -1,
    UNAUTHORIZED: 0,
    ERROR_FETCHING_DATA: 1,
    USER_ACCESS_NOT_GRANTED: 2
}

function Message (props) {
    let className = 'message';
    if (props.error) {
        className += ' error'
    }

    const getMessageText = (type) => {
        switch (type) {
            case ErrorType.UNAUTHORIZED:
                return 'Nepravilno uporabniško ime in geslo. Prosimo poskusite znova.';
            case ErrorType.ERROR_FETCHING_DATA:
                return 'Napaka pri pridobivanju podatkov. Prosimo poskusite znova kasneje.';
            case ErrorType.USER_ACCESS_NOT_GRANTED:
                return 'Uporabnik nima uporabniških pravic za dostop do sistema.';
        }
    };

    return (
        <div className={className}>
            <p>{getMessageText(props.type)}</p>
        </div>
    );
}

export default Message;