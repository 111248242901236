import React, {useState, useCallback, useEffect} from "react";

import '../components/css/Tables.css'

import {useDispatch, useSelector} from "react-redux";

import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import {ReactComponent as IconEdit} from "../assets/svg/Icon feather-edit.svg";
import {useHistory} from "react-router-dom";
import {PAGES} from "../App";
import {
    loadAds, loadAllBars, loadBars, loadBarsForBarRepresentative, loadBarsForOwner, loadComboOffers, loadProducts,
    setActiveAd, setActiveProduct, setAds,
    setCreateMenuActive, setSidebarWidth
} from "../store/actions/actions";
import {displayAlert, findItem, isSessionValid, parseMapCaretChars, parseUserRole} from "../helpers/helpers";
import {Redirect} from "react-router";
import Modal from "../components/js/Modal";
import {USER_ROLES} from "../constants/enums";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";

function AdsList(props) {
    let dispatch = useDispatch();
    let token = useSelector(state => state.reducer.userToken);
    let user = useSelector(state => state.reducer.user);
    let ads = useSelector(state => state.reducer.ads);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    let activeBar = useSelector(state => state.reducer.activeBar);

    let comboOffers = useSelector(state => state.reducer.comboOffers);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    console.log("Ads:", ads);

    useEffect(() => {
        dispatch(setCreateMenuActive(null));
    }, []);

    useEffect(() => {
            let done = false;
            const asyncEffect = async () => {
                if (user) {
                    await dispatch(loadAds(() => {
                        console.log("Ads successfully loaded..");
                    }, () => {
                        console.warn("Error loading ads..")
                    }));
                    await dispatch(loadComboOffers(null, () => {
                        console.log("Combo Offers successfully loaded..");
                    }, () => {
                        console.warn("Error loading combo offers..")
                    }));
                }
            }
            console.log("USER:", user)
            if (parseUserRole(user.type) === USER_ROLES.ADMIN) {
                dispatch(loadAllBars(user['user_id'], () => {
                }, () => displayAlert('Napaka pri pridobivanju seznama lokalov. Prosimo poskusite znova..')));

            } else if (parseUserRole(user.type) === USER_ROLES.CARETAKER) {
                dispatch(loadBars(user['user_id'], () => {
                }, () => displayAlert('Napaka pri pridobivanju seznama lokalov. Prosimo poskusite znova..')));
            } else if (parseUserRole(user.type) === USER_ROLES.OWNER) {
                dispatch(loadBarsForOwner(user['user_id'], () => {
                }, () => displayAlert('Napaka pri pridobivanju seznama lokalov. Prosimo poskusite znova..')));
            } else if (parseUserRole(user.type) === USER_ROLES.BAR_REPRESENTATIVE) {
                dispatch(loadBarsForBarRepresentative(user['user_id'], () => {
                }, () => displayAlert('Napaka pri pridobivanju seznama lokalov. Prosimo poskusite znova..')));
            }
            asyncEffect().then(() => console.log(done && "done loading data."));
        }, [user, activeBar]
    );

    const navigateAndLoadSelectedAd = (ad) => {
        if (ad?.combo_offer) {
            dispatch(setActiveProduct(ad));
            onNavigateTo(PAGES.EDIT_PRODUCT);
        } else {
            dispatch(setActiveAd(ad));
            onNavigateTo(PAGES.EDIT_AD);
        }
    };


    function Item(props) {
        console.log(props.value);
        const item = props.value;
        return (
            <li onClick={() => {
            }} className={'row'} style={{left: `${sidebarWidth + 50}px`}}>
                <div className={'row-name'}>{parseMapCaretChars(item.name)}</div>
                <div className={'row-unit'}><a href={item?.link} target="_blank"
                                               rel="noopener noreferrer">{item.link}</a></div>
                <div className={'row-package'}>
                    <img className={'img-banner-upload'} src={`${item.image}`} alt="ad banner"
                         height={'60px'}/>
                </div>
                <div onClick={() => navigateAndLoadSelectedAd(item)} className={'row-edit'}>
                    <a className={'a-icon'}><IconEdit
                        className={'icon'}
                        width={'24px'} height={'24px'}
                        title={'icon list'}/></a></div>
            </li>
        );
    }

    function renderTable(items) {
        const header = () => {
            return (
                <div className={'row-header'} style={{left: `${sidebarWidth + 50}px`}}>
                    <div className={'row-name'}>Naziv</div>
                    <div className={'row-package'}>Link</div>
                    <div className={'row-unit'}>Slika</div>
                    <div className={'row-edit'}>Uredi</div>
                </div>
            );
        };
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul className={'item-list'} style={{left: `${sidebarWidth + 50}px`}}>
                    {items.map((item, index) => <Item key={index} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {header()}
                {list(items)}
            </>
        );
    }

    let windowSize = {x: 0, y: document.documentElement.scrollHeight}
    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page className={'main'} style={{height: `${windowSize.y}px`}} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            {/*<Sidebar/>*/}
            {/*<div className={'page'}>*/}
            <Header left={`${sidebarWidth + 50}px`} title={'PREGLED OGLASOV'}/>
            <div className={'body'}>
                {renderTable([...ads, ...comboOffers])}
            </div>
            {/*</div>*/}
        </Page>
        </div>
}

export default AdsList;

