import React, {useEffect, useState} from "react";

import '../css/CreateMenuChooseBackground.css';

import {useDispatch, useSelector} from "react-redux";

import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import Footer from "../components/js/Footer";
import ButtonGroupSelection, {LEADING_BRAND} from "../components/js/ButtonGroupSelection";
import * as AiIcons from "react-icons/ai";
import {setCreateMenuActive, setIsCreatingMenu, setSidebarWidth} from "../store/actions/actions";
import {isSessionValid, saveToLocalStorage, validateUserInput} from "../helpers/helpers";
import {PAGES} from "../App";
import {Redirect} from "react-router";

import backgroundBarcaffe from "../assets/img/Barcaffe_dark_A5.jpg"
import backgroundBarcaffeWhite from "../assets/img/Barcaffe_light_A5.jpg"
import backgroundLaskoLight from "../assets/img/LASKO_A5_1.png"
import backgroundLaskoDark from "../assets/img/LASKO_black_A5_1.png"
import backgroundUnionLight from "../assets/img/UNION_A5.png"
import backgroundUnionDark from "../assets/img/UNION_black_A5.png"

import beer_cider_1_bcg from "../assets/img/PLU-BEERCARD-A4-1-bckg-1.jpg"
import beer_cider_2_bcg from "../assets/img/PLU-BEERCARD-A4-2-bckg-1.jpg"
import beer_cider_3_bcg from "../assets/img/PLU-BEERCARD-A4-3-bckg-1.jpg"
import beer_cider_4_bcg from "../assets/img/PLU-BEERCARD-A4-4-bckg-1.jpg"
import beer_cider_5_bcg from "../assets/img/PLU-BEERCARD-A4-5-bckg-1.jpg"
import beer_cider_6_bcg from "../assets/img/PLU-BEERCARD-A4-6-bckg-1.jpg"


import Page, {SIDEBAR_WIDTH} from "../components/js/Page";

function CreateMenuChooseBackground(props) {
    const dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let createMenuActive = useSelector(state => state.reducer.createMenuActive);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    let defaultValue = createMenuActive.menuBackground !== undefined ? createMenuActive.menuBackground : !createMenuActive.beer_cider_menu ? createMenuActive.main_brand : createMenuActive.menuBackground;
    //let defaultValue = createMenuActive.menuBackground!==undefined ? createMenuActive.menuBackground : createMenuActive.main_brand;
    const [leadingBrandBackground, setLeadingBrandBackground] = useState(defaultValue);

    useEffect(() => {
        dispatch(setIsCreatingMenu(1));
    }, []);

    useEffect(() => {
        if (leadingBrandBackground !== null) {
            onConfirmCurrentChanges();
            saveToLocalStorage(createMenuActive)
        }
    }, [leadingBrandBackground]);


    const onConfirmCurrentChanges = () => {
        const isUserInputValid = validateUserInput([leadingBrandBackground]);

        if (!isUserInputValid) {
            alert("Za nadaljevanje najprej izberite željeno ozadje..");
            return false;
        }
        createMenuActive.menuBackground = leadingBrandBackground;

        dispatch(setCreateMenuActive(createMenuActive));
        return true;
    };

    const selectBackground = (selection) => {
        setLeadingBrandBackground(selection);

        return selection;
    };

    const isBackgroundSelected = (selection) => {
        return leadingBrandBackground === selection;
    };

    const renderBackgroundOptions = () => {
        let content;
        if (createMenuActive.beer_cider_menu) {
            content = <div className={'container-background-image beer-cider'}>
                <div style={{backgroundImage: `url(${beer_cider_1_bcg})`, backgroundSize: 'contain'}}
                     className={isBackgroundSelected(LEADING_BRAND.BEER_CIDER_1) ? 'wrapper-background-image selected' : 'wrapper-background-image'}
                     onClick={() => selectBackground(LEADING_BRAND.BEER_CIDER_1)}>
                    {isBackgroundSelected(LEADING_BRAND.BEER_CIDER_1) && <AiIcons.AiFillCheckCircle/>}
                </div>
                <div style={{backgroundImage: `url(${beer_cider_2_bcg})`, backgroundSize: 'contain'}}
                     className={isBackgroundSelected(LEADING_BRAND.BEER_CIDER_2) ? 'wrapper-background-image selected' : 'wrapper-background-image'}
                     onClick={() => selectBackground(LEADING_BRAND.BEER_CIDER_2)}>
                    {isBackgroundSelected(LEADING_BRAND.BEER_CIDER_2) && <AiIcons.AiFillCheckCircle/>}
                </div>
                <div style={{backgroundImage: `url(${beer_cider_3_bcg})`, backgroundSize: 'contain'}}
                     className={isBackgroundSelected(LEADING_BRAND.BEER_CIDER_3) ? 'wrapper-background-image selected' : 'wrapper-background-image'}
                     onClick={() => selectBackground(LEADING_BRAND.BEER_CIDER_3)}>
                    {isBackgroundSelected(LEADING_BRAND.BEER_CIDER_3) && <AiIcons.AiFillCheckCircle/>}
                </div>
                <div style={{backgroundImage: `url(${beer_cider_4_bcg})`, backgroundSize: 'contain'}}
                     className={isBackgroundSelected(LEADING_BRAND.BEER_CIDER_4) ? 'wrapper-background-image selected' : 'wrapper-background-image'}
                     onClick={() => selectBackground(LEADING_BRAND.BEER_CIDER_4)}>
                    {isBackgroundSelected(LEADING_BRAND.BEER_CIDER_4) && <AiIcons.AiFillCheckCircle/>}
                </div>
                <div style={{backgroundImage: `url(${beer_cider_5_bcg})`, backgroundSize: 'contain'}}
                     className={isBackgroundSelected(LEADING_BRAND.BEER_CIDER_5) ? 'wrapper-background-image selected' : 'wrapper-background-image'}
                     onClick={() => selectBackground(LEADING_BRAND.BEER_CIDER_5)}>
                    {isBackgroundSelected(LEADING_BRAND.BEER_CIDER_5) && <AiIcons.AiFillCheckCircle/>}
                </div>
                <div style={{backgroundImage: `url(${beer_cider_6_bcg})`, backgroundSize: 'contain'}}
                     className={isBackgroundSelected(LEADING_BRAND.BEER_CIDER_6) ? 'wrapper-background-image selected' : 'wrapper-background-image'}
                     onClick={() => selectBackground(LEADING_BRAND.BEER_CIDER_6)}>
                    {isBackgroundSelected(LEADING_BRAND.BEER_CIDER_6) && <AiIcons.AiFillCheckCircle/>}
                </div>
            </div>
        } else {
            if (createMenuActive.leading_brand === LEADING_BRAND.BARCAFFE) {
                content = <div className={'container-background-image'}>
                    <div style={{backgroundImage: `url(${backgroundBarcaffe})`, backgroundSize: 'contain'}}
                         className={isBackgroundSelected(LEADING_BRAND.BARCAFFE) ? 'wrapper-background-image selected' : 'wrapper-background-image'}
                         onClick={() => selectBackground(LEADING_BRAND.BARCAFFE)}>
                        {isBackgroundSelected(LEADING_BRAND.BARCAFFE) && <AiIcons.AiFillCheckCircle/>}
                    </div>
                    <div style={{backgroundImage: `url(${backgroundBarcaffeWhite})`, backgroundSize: 'contain'}}
                         className={isBackgroundSelected(LEADING_BRAND.BARCAFFE_WHITE) ? 'wrapper-background-image selected' : 'wrapper-background-image'}
                         onClick={() => selectBackground(LEADING_BRAND.BARCAFFE_WHITE)}>
                        {isBackgroundSelected(LEADING_BRAND.BARCAFFE_WHITE) && <AiIcons.AiFillCheckCircle/>}
                    </div>
                </div>
            } else if (createMenuActive.leading_brand === LEADING_BRAND.LASKO) {
                content = <div className={'container-background-image'}>
                    <div style={{backgroundImage: `url(${backgroundLaskoLight})`, backgroundSize: 'contain'}}
                         className={isBackgroundSelected(LEADING_BRAND.LASKO) ? 'wrapper-background-image selected' : 'wrapper-background-image'}
                         onClick={() => selectBackground(LEADING_BRAND.LASKO)}>
                        {isBackgroundSelected(LEADING_BRAND.LASKO) && <AiIcons.AiFillCheckCircle/>}
                    </div>
                    <div style={{backgroundImage: `url(${backgroundLaskoDark})`, backgroundSize: 'contain'}}
                         className={isBackgroundSelected(LEADING_BRAND.LASKO_DARK) ? 'wrapper-background-image selected' : 'wrapper-background-image'}
                         onClick={() => selectBackground(LEADING_BRAND.LASKO_DARK)}>
                        {isBackgroundSelected(LEADING_BRAND.LASKO_DARK) && <AiIcons.AiFillCheckCircle/>}
                    </div>
                </div>
            } else if (createMenuActive.leading_brand === LEADING_BRAND.UNION) {
                content = <div className={'container-background-image'}>
                    <div style={{backgroundImage: `url(${backgroundUnionLight})`, backgroundSize: 'contain'}}
                         className={isBackgroundSelected(LEADING_BRAND.UNION) ? 'wrapper-background-image selected' : 'wrapper-background-image'}
                         onClick={() => selectBackground(LEADING_BRAND.UNION)}>
                        {isBackgroundSelected(LEADING_BRAND.UNION) && <AiIcons.AiFillCheckCircle/>}
                    </div>
                    <div style={{backgroundImage: `url(${backgroundUnionDark})`, backgroundSize: 'contain'}}
                         className={isBackgroundSelected(LEADING_BRAND.UNION_DARK) ? 'wrapper-background-image selected' : 'wrapper-background-image'}
                         onClick={() => selectBackground(LEADING_BRAND.UNION_DARK)}>
                        {isBackgroundSelected(LEADING_BRAND.UNION_DARK) && <AiIcons.AiFillCheckCircle/>}
                    </div>
                </div>
            }
        }
        return content;
    };

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page relative className={`main ${createMenuActive.beer_cider_menu ? 'beer-cider-page' : 'normal'}`} pageStyle={{height: '100%'}} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            {/*<Sidebar/>*/}
            {/*<div className={'page relative'}>*/}
            <Header left={`${sidebarWidth + 50}px`} title={'IZBERI OZADJE CENIKA'} step={'2. KORAK'}/>
            <div className={'body'} style={{paddingLeft: `${sidebarWidth + 50}px`}}>
                <p className={'p-title-small'}>{'Izberite ozadje za vaš cenik. Izbirate lahko med spodnjimi predlogami. S klikom na predlogo izberete ozadje.'}</p>
                {renderBackgroundOptions()}
            </div>
            <Footer
                left={`${sidebarWidth}px`}
                back={'NAZAJ'}
                urlBack={PAGES.CREATE_MENU}
                next={'POTRDI IN NADALJUJ'}
                urlNext={PAGES.CREATE_MENU_CHOOSE_CATEGORIES}
                progress={2}/>
            {/*</div>*/}
        </Page>
        </div>

}

export default CreateMenuChooseBackground;

