import '../css/Card.css'

function Card(props) {

    let className = 'card';
    if (props.shadow) {
        className += ' shadow';
    }

    return (
        <div onClick={props.onClick} className={className} style={props.style}>
            <a>{props.icon}{props.title}</a>
        </div>
    );
}

export default Card;

