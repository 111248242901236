import React, {useState, useEffect} from "react";
import Select from "react-select";

import '../css/Dropdown.css';
import {useSelector} from "react-redux";
import {parseMapCaretChars} from "../../helpers/helpers";
import {parseUserGroupType} from "./ModalSharepointUserGroup";

const parseDataToOptionsObject = (data) => {
    let obj = [];
    for (let i = 0; i < data.length; i++) {
        let el = { value: JSON.parse(JSON.stringify(data[i])), label: data[i].name ? `${parseMapCaretChars(data[i].name)}` : data[i] }
        obj.push(el);
    }
    //console.log(obj);
    return obj
};

const parseProductsToOptionsObject = (data) => {
    let obj = [];
    for (let i = 0; i < data.length; i++) {
        let el = { value: JSON.parse(JSON.stringify(data[i].products)), label: parseMapCaretChars(data[i].name) }
        obj.push(el);
    }
    //console.log(obj);
    return obj
};

function DropdownFilter(props) {
    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState(parseDataToOptionsObject(props.options));

    /*
    useEffect(() => {

        if (props.loadBars && bars && !createMenuActive) {
            //setOptions(parseDataToOptionsObject(bars));
            setOptions(parseDataToOptionsObject(bars ? bars.slice(0, bars.length >= 100 ? 100 : bars.length) : []));
        } else if (props.loadBarsAd && bars) {
            //setOptions(parseDataToOptionsObject(bars));
            setOptions(parseDataToOptionsObject(bars ? bars.slice(0, bars.length >= 100 ? 100 : bars.length) : []));
        }
    }, [bars]);

     */
//console.log(selectedOption);

    useEffect(() => {
        if (props.value) {
            setSelectedOption(props.value)
        }
    }, [props.value])

    useEffect(() => {
        if (props.options) {
            setOptions(parseDataToOptionsObject(props.options));
        }
    }, [props.options])


    const onInputChange = (selectedOption) => {
        const {onChange} = props;

        setSelectedOption(selectedOption);
        onChange(selectedOption.value);

        console.log(selectedOption);
    }

    const onInputChangeText = (selectedOption) => {
        if (props.loadProducts) {
            console.log(selectedOption);
            if (selectedOption?.length >= 1) {
                setOptions(parseDataToOptionsObject(options));
            }
        }
    }

    let className = 'select-default';
    let classNamePrefix = 'select-default';

    if (props.grey) {
        className += ' grey';
    }

    let inputContent = () => {
        return (
            <Select
                className={className}
                classNamePrefix={classNamePrefix}
                defaultValue={selectedOption}
                onChange={onInputChange}
                onInputChange={onInputChangeText}
                options={parseDataToOptionsObject(props.options)}
                placeholder={props.placeholder}
                noOptionsMessage={() => "Seznam je prazen.."}
            />
        );
    };

    return (
        <form style={{height: '40px', ...props.style}}>
            <label className={'input-label'} >
                {props.label && <p className={'p-title-small'} style={{color: 'black'}}>{props.label}</p>}
                {inputContent()}
            </label>
        </form>
    );
}

export default DropdownFilter;