import React, {useEffect, useState} from "react";

import '../css/Button.css';
import {ReactComponent as IconAdd} from "../../assets/svg/Icon ionic-ios-add-circle-outline.svg";
import {ReactComponent as IconUpload} from "../../assets/svg/Icon feather-upload.svg";
import {ReactComponent as IconDelete} from "../../assets/svg/Icon feather-trash.svg";
import {useSelector} from "react-redux";

function Button(props) {
    let activeBar = useSelector(state => state.reducer.activeBar);
    let createMenuActive = useSelector(state => state.reducer.createMenuActive);

    let className = ' button-container button-default';
    if (props.add) {
        className += ' button-add';
    }
    if (props.delete) {
        className += ' button-delete';
    }
    if (props.login) {
        className += ' button-login';
    }
    if (props.confirm) {
        className += ' button-confirm';
    }
    if (props.reject) {
        className += ' button-reject';
    }
    if (props.upload) {
        className += ' button-upload';
    }
    if (props.comboOffer) {
        className += ' button-combo-offer';
    }
    if (props.selection) {
        className += ' button-selection'
    }
    if (props.selected) {
        className += ' button-selected'
    }
    if (props.confirm) {
        className += ' button-confirm';
    }
    if (props.disabled) {
        className += ' button-disabled'
    }
    /*
     else {
        className += ' button-default';
    }
     */

    useEffect(() => {
        if (props.loadBar) {
            if (props.loadBar && activeBar &&
                (props.id === "button-heineken" && activeBar.main_brand === 0) &&
                (props.id === "button-union" && activeBar.main_brand === 1) &&
                (props.id === "button-union" && activeBar.main_brand === 4) &&
                (props.id === "button-lasko" && activeBar.main_brand === 2) &&
                (props.id === "button-lasko" && activeBar.main_brand === 3)) {
                className += ' button-selected'
            }
        }
    }, [activeBar]);

    useEffect(() => {
        if (props.loadMenu) {
            if (createMenuActive) {
                if (
                    (props.id === "button-digital" && createMenuActive.menu_type === "0") ||
                    (props.id === "button-paper" && createMenuActive.menu_type === "1") ||
                    (props.id === "button-both" && createMenuActive.menu_type === "2")) {
                    className += ' button-selected'
                }
            }
        }
    }, [createMenuActive]);

    return <div className={props.className + ' ' + className} style={props.style}
                onClick={() => {
                    if (!props.disabled) {
                        props.action();
                    }
                }}>
        {props.add && <IconAdd className={'icon'} width={'24px'} height={'24px'} title={'icon add'}/>}
        {props.upload && !props.comboOffer &&
        <IconUpload className={'icon'} width={'82px'} height={'82px'} style={{marginRight: 0}} title={'icon upload'}/>}
        {props.upload && props.comboOffer &&
        <IconUpload className={'icon'} width={'28px'} height={'28px'} title={'icon upload'}/>}

        <div className={'button'}><p style={props.textStyle}>{props.text}</p></div>

        {props.delete && <IconDelete className={'icon'} width={'20px'} height={'22px'} title={'icon delete'}/>}
    </div>
}

export default Button;