import React, {useState, useCallback, useEffect} from "react";

import '../components/css/Tables.css'

import {useDispatch, useSelector} from "react-redux";

import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import Footer from "../components/js/Footer";
import {ReactComponent as IconList} from "../assets/svg/Icon awesome-list-ul.svg";
import {ReactComponent as IconEdit} from "../assets/svg/Icon feather-edit.svg";
import {ReactComponent as IconQR} from "../assets/svg/Icon qr.svg";
import {ReactComponent as IconNotification} from "../assets/svg/Icon material-notifications-none.svg";
import {useHistory} from "react-router-dom";
import {PAGES} from "../App";
import {
    loadAllBars,
    loadBars, loadBarsForBarRepresentative, loadBarsForOwner, loadCaretaker,
    loadUser, previewQR, previewQRForBarId,
    SET_ACTIVE_BAR,
    setActiveBar, setActiveBarOwner,
    setActiveBarRepresentative, setCreateMenuActive, setSidebarWidth
} from "../store/actions/actions";
import {USER_ROLES} from "../constants/enums";
import {
    displayAlert,
    findItem,
    isSessionValid, isUserBarRepresentative, isUserCaretaker, isUserOwner,
    parseLeadingBrand,
    parseMapCaretChars,
    parseUserRole
} from "../helpers/helpers";
import {Redirect} from "react-router";
import Input from "../components/js/Input";
import Button from "../components/js/Button";
import {GenerateMenuType} from "../components/js/ButtonGroupSelection";
import Modal from "../components/js/Modal";

const BARS_PER_PAGE = 100;
const TIMEOUT_INTERVAL = 1;
const SEARCH_BY_FIELDS = [
    'name',
    'external_id'
];

function BarsList(props) {
    let dispatch = useDispatch();
    let token = useSelector(state => state.reducer.userToken);
    const user = useSelector(state => state.reducer.user);
    const allBars = useSelector(state => state.reducer.bars);
    const [userRole, setUserRole] = useState(parseUserRole(user?.type))
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    const barRep = useSelector(state => state.reducer.activeBarRepresentative);
    const owner = useSelector(state => state.reducer.activeBarOwner);

    const [bars, setBars] = useState(null);
    const [filteredBars, setFilteredBars] = useState(allBars);
    const [currentPage, setCurrentPage] = useState(1);

    const [inputTimeout, setInputTimeout] = useState(null);

    const [nameInput, setNameInput] = useState(null);

    const [modal, setModal] = useState(false);
    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
    const [mainHeight, setMainHeight] = useState(documentSize.y);


    const dispatchLoadCaretaker = (caretakerId, path) => {
        dispatch(loadCaretaker(caretakerId, USER_ROLES.CARETAKER, () => {
            console.log("Caretaker loaded..")

            onNavigateTo(path);
        }, () => {
            console.log("Caretaker loading failed..")
            onNavigateTo(path);
        }));
    }

    const navigateAndLoadSelectedBar = (path, bar, barUserId, caretakerId, ownerCaretakerId) => {
        dispatch(setActiveBar(bar));
        //dispatchLoadCaretaker();
        dispatch(loadUser(barUserId, USER_ROLES.BAR_REPRESENTATIVE, () => {
            console.log("Bar representative loaded..")

            dispatch(loadCaretaker(caretakerId, USER_ROLES.CARETAKER, () => {
                console.log("Caretaker loaded..");
                //dispatchLoadCaretaker();

                dispatch(loadCaretaker(ownerCaretakerId, USER_ROLES.OWNER, () => {
                    console.log("Caretaker owner loaded..");
                    //dispatchLoadCaretaker();
                    onNavigateTo(path);
                }, () => {
                    //dispatchLoadCaretaker();
                    dispatch(setActiveBarOwner(null));
                    onNavigateTo(path);
                    console.log("Caretaker owner loading failed..")
                }));

                //onNavigateTo(path);
            }, () => {
                //  dispatchLoadCaretaker();

                dispatch(loadCaretaker(ownerCaretakerId, USER_ROLES.OWNER, () => {
                    console.log("Caretaker owner loaded..");
                    //dispatchLoadCaretaker();
                    onNavigateTo(path);
                }, () => {
                    //dispatchLoadCaretaker();
                    dispatch(setActiveBarOwner(null));
                    onNavigateTo(path);
                    console.log("Caretaker owner loading failed..")
                }));

                // onNavigateTo(path);
                console.log("Caretaker loading failed..")
            }));

        }, () => {
            dispatch(setActiveBarRepresentative(null));
            console.log("Bar representative loading failed..")
            onNavigateTo(path);
        }));
    };

    useEffect(() => {
        dispatch(setCreateMenuActive(null));
        dispatch(setActiveBarRepresentative(null));
        dispatch(setActiveBarOwner(null));

        //console.log("RESET BAR REP & OWNER");

    }, []);

    useEffect(() => {
        if (allBars) {
            setFilteredBars(allBars);
            console.log(document.documentElement.offsetHeight);
            setMainHeight(6200)
        }
    }, [allBars]);

    useEffect(() => {
        if (!!inputTimeout) {
            clearTimeout(inputTimeout);
            setInputTimeout(null);
        }
        setInputTimeout(setTimeout(() => {
            if (!nameInput || nameInput === '') {
                setFilteredBars(allBars);
            } else {
                let searchedBars = [];
                for (const bar of allBars) {
                    for (const fieldName of SEARCH_BY_FIELDS) {
                        if (bar.hasOwnProperty(fieldName) && !!bar[fieldName] && parseMapCaretChars(bar[fieldName]).toLowerCase().includes(nameInput.toLowerCase())) {
                            searchedBars.push(bar);
                            break;
                        }
                    }
                }
                setFilteredBars(searchedBars);
            }
            setCurrentPage(1);
        }, TIMEOUT_INTERVAL));
    }, [nameInput]);

    useEffect(() => {
        if (filteredBars.length < BARS_PER_PAGE) {
            setBars(filteredBars)
        } else {
            setBars(filteredBars.slice((currentPage-1)*BARS_PER_PAGE, (currentPage-1)*BARS_PER_PAGE+BARS_PER_PAGE));
        }
    }, [currentPage, filteredBars]);

    useEffect(() => {

        console.log("BAR REP", barRep);
        console.log("BAR OWNER", owner);

    }, [barRep, owner]);

    useEffect(() => {
        if (user) {
            console.log("USER:", user)
            if (parseUserRole(user.type) === USER_ROLES.ADMIN) {
                dispatch(loadAllBars(user['user_id'], () => {
                }, () => displayAlert('Napaka pri pridobivanju seznama lokalov. Prosimo poskusite znova..')));

            } else if (parseUserRole(user.type) === USER_ROLES.CARETAKER) {
                dispatch(loadBars(user['user_id'], () => {
                }, () => displayAlert('Napaka pri pridobivanju seznama lokalov. Prosimo poskusite znova..')));
            }  else if (parseUserRole(user.type) === USER_ROLES.OWNER) {
                dispatch(loadBarsForOwner(user['user_id'], () => {
                }, () => displayAlert('Napaka pri pridobivanju seznama lokalov. Prosimo poskusite znova..')));
            } else if (parseUserRole(user.type) === USER_ROLES.BAR_REPRESENTATIVE) {
                dispatch(loadBarsForBarRepresentative(user['user_id'], () => {
                }, () => displayAlert('Napaka pri pridobivanju seznama lokalov. Prosimo poskusite znova..')));
            }
        }
    }, [user]);

    const renderModal = () => {
        return (
            modal && <Modal bar actionClose={() => {
                console.log('display modal, false');
                setModal(false)
            }}/>
        );
    };

    const onPreviewQR= async (item) => {
        let format = "qr"
        await dispatch(previewQRForBarId(item.bar_id, format, () => {
            console.log("QR successfully generated!")
        }, (e) => {
            throw new Error("Error previewing menu! " + e);
        }));
    };

    function Item(props) {
        // console.log(props.value);
        const item = props.value;
        return (
            <li onClick={() => {
            }} className={'row'} style={{left: `${sidebarWidth + 50}px`}}>
                <div className={'row-bar'}>{parseMapCaretChars(item.name)}</div>
                <div className={'row-address'}>{parseMapCaretChars(item.address)}</div>
                <div className={'row-security'}>{item.tax_number}</div>
                <div className={'row-leading-brand'}>{parseLeadingBrand(item.main_brand)}</div>
                <div style={{visibility: isUserBarRepresentative(user) || isUserOwner(user) ? 'hidden' : 'unset'}}
                    onClick={() => onNavigateTo(PAGES.NOTIFICATIONS)} className={'row-notification'}><IconNotification
                    className={'icon'} width={'24px'} height={'24px'} title={'icon notification'}/></div>
                <div /*style={{visibility: isUserBarRepresentative(user) || isUserOwner(user) ? 'hidden' : 'unset'}} */
                     onClick={() => navigateAndLoadSelectedBar(PAGES.MENUS_LIST, item, item.user_id, item.caretaker_id, item.bar_owner_id)}
                     className={'row-menu'}>
                    <IconList
                        className={'icon'} width={'24px'} height={'24px'} title={'icon list'}/></div>
                <div
                    onClick={() => navigateAndLoadSelectedBar(PAGES.EDIT_BAR, item, item.user_id, item.caretaker_id, item.bar_owner_id)}
                    className={'row-edit'}>
                    <IconEdit
                        className={'icon'} width={'24px'} height={'24px'} title={'icon edit'}/></div>
                <div
                    onClick={() => onPreviewQR(item)}
                    className={'row-edit'}>
                    <IconQR
                        className={'icon'} width={'24px'} height={'24px'} title={'icon qr'}/></div>
            </li>
        );
    }

    function renderTable(items) {

        const filters = () => {
            return (
                <div className={'search-filters'} style={{left: `${sidebarWidth + 50}px`}}>
                    <Input onSubmit={() => {}}
                           onChange={setNameInput}
                           placeholder={'Naziv ali šifra lokala'}
                           white
                    />
                </div>
            );
        };
        const pages = (className) => {
            return (
                <div className={className} style={{left: `${sidebarWidth + 50}px`}}>
                    <Button disabled={currentPage === 1}
                            text={'Prejšnja stran'}
                            action={() => {setCurrentPage(currentPage-1)}}/>
                    <Button disabled={currentPage === Math.ceil(filteredBars.length/BARS_PER_PAGE)}
                            text={'Naslednja stran'}
                            action={() => {setCurrentPage(currentPage+1)}}/>
                    <p>{currentPage}/{Math.ceil(filteredBars.length/BARS_PER_PAGE)}</p>
                </div>
            );
        };
        const header = () => {
            return (
                <div className={'row-header row-header-has-pagination row-header-has-filter'} style={{left: `${sidebarWidth + 50}px`}}>
                    <div className={'row-bar'}>Lokal</div>
                    <div className={'row-address'}>Naslov</div>
                    <div className={'row-security'}>Davčna številka</div>
                    <div className={'row-leading-brand'}>Glavna znamka</div>
                    <div style={{visibility: isUserBarRepresentative(user) || isUserOwner(user) ? 'hidden' : 'unset'}} className={'row-notification'}>Obvestila</div>
                    <div /*style={{visibility: isUserBarRepresentative(user) || isUserOwner(user) ? 'hidden' : 'unset'}} */ className={'row-menu'}>Ceniki</div>
                    <div className={'row-edit'}>Uredi</div>
                    <div className={'row-edit'}>QR</div>
                </div>
            );
        };
        // console.log(items);
        if (!items || items === {}) {
            return header();
        } else {
            const list = (items) => {
                if (!items) {
                    return;
                }
                return (
                    <ul className={'item-list'} style={{left: `${sidebarWidth + 50}px`}}>
                        {items.map((item, index) => <Item key={index} value={item}/>)}
                        {pages('pagination-container pagination-container-bottom')}
                    </ul>
                );
            }
            return (
                <>
                    {filters()}
                    {pages('pagination-container pagination-container-has-filter')}
                    {header()}
                    {list(items)}
                </>
            );
        }
    }
    let windowSize = {x: 0, y: document.documentElement.scrollHeight}
    console.log(mainHeight);
    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page className={'main'} style={{height: `${mainHeight+10}px`}} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            {/*<Sidebar/>*/}
            {/*<div className={'page'}>*/}
            {userRole === USER_ROLES.CARETAKER ?
                <Header left={`${sidebarWidth + 50}px`} title={'PREGLED LOKALOV'} interactive add button={'DODAJ NOV LOKAL'} action={() => {
                    setModal(true)
                }}/>
                :
                <Header left={`${sidebarWidth + 50}px`} title={'PREGLED LOKALOV'} />
            }

            <div className={'body body-has-pagination body-has-filter'}>
                {renderTable(bars)}
            </div>
            {/*</div>*/}
        </Page>
            {renderModal()}
        </div>
}

export default BarsList;

