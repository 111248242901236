import React, {useState, useEffect, createRef, useCallback, useRef} from "react";
import Header from "../components/js/Header";
import Input from "../components/js/Input";

import "../css/ProductBarEdit.css"

import {useDispatch, useSelector} from "react-redux";
import {displayAlert, isSessionValid, mapCaretChars} from "../helpers/helpers";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import {
    resetUserPassword,
    setSidebarWidth,
    updateUser
} from "../store/actions/actions";
import {useHistory} from "react-router-dom";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import Button from "../components/js/Button";

function User(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    const user = useSelector(state => state.reducer.user);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);
    let activeEditUser = useSelector(state => state.reducer.activeEditUser);

    const [fullName, setFullName] = useState(null);
    const [externalId, setExternalId] = useState(null);
    const [email, setEmail] = useState(null);
    const [telephone, setTelephone] = useState(null);
    const [type, setType] = useState(null);

    const [requestPasswordReset, setRequestPasswordReset] = useState(false);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    useEffect(() => {
        if (activeEditUser) {
            console.log("EDIT USER:", activeEditUser)
            setExternalId(activeEditUser.external_id);
            setFullName(activeEditUser.full_name);
            setEmail(activeEditUser.username);
            setTelephone(activeEditUser.telephone);
            setType(activeEditUser.type);
        }
    }, [activeEditUser]);

    useEffect(() => {
        if (requestPasswordReset) {
            dispatch(resetUserPassword(activeEditUser, (res) => {
                console.log("User password successfully updated!", res);
                displayAlert("Geslo uspešno ponastavljeno in posredovano na uporabnikov e-mail.")
            }, () => {
                console.warn("Error resetting user password..")
                displayAlert("Napaka pri ponastavitvi gesla. Prosimo poskusite znova..")
            }));
            setRequestPasswordReset(false);
        }
    }, [requestPasswordReset]);

    const updateUserDispatch = (user) => {
        dispatch(updateUser(user, (res) => {
            console.log("User successfully updated!", res);
        }, () => {
            console.warn("Error updating user..")
        }));
    }

    const resetUserPasswordDispatch = (user) => {
        dispatch(resetUserPassword(user, (res) => {
            console.log("User password successfully updated!", res);
            displayAlert("Geslo uspešno ponastavljeno in posredovano na uporabnikov e-mail.")
        }, () => {
            console.warn("Error resetting user password..")
            displayAlert("Napaka pri ponastavitvi gesla. Prosimo poskusite znova..")
        }));
    }

    let inputStyle = {marginBottom: '48px'};

    let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
    const [mainHeight, setMainHeight] = useState(documentSize.y);
    useEffect(() => {
        setTimeout(() => {
            let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
            setMainHeight(documentSize.y);
        }, 250)
    }, [])

    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, []);

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div ref={ref}><Page relative className={'main'} style={{height: `${mainHeight}px`}}
                                      onToggleSidebar={(shown) => {
                                          if (shown) {
                                              dispatch(setSidebarWidth(SIDEBAR_WIDTH))
                                          } else {
                                              dispatch(setSidebarWidth(0))
                                          }
                                      }}>

            <div className={'body'} style={{/*marginTop: '190px', */paddingLeft: `${sidebarWidth + 50}px`}}>
                <Header left={`${sidebarWidth + 50}px`} title={`PREGLED UPORABNIKOV / ${fullName}`}/>
                <div className={'container-column-2'}>
                    <div className={'column-2'}>
                        <div className={'container-row-2'}>
                            <div className={'row-2'}>
                                <Input white loadUser externalId onChange={setExternalId}
                                       placeholder={!externalId ? 'External ID' : externalId}
                                       label={'External ID'}
                                       style={inputStyle}
                                       disabled
                                />
                                <Input white loadUser type onChange={setType} placeholder={!type ? 'Tip uporabnika' : type}
                                       label={'Tip uporabnika'}
                                       style={inputStyle}
                                       disabled
                                />
                            </div>
                        </div>
                        <Input white shadow loadUser fullName onChange={setFullName} placeholder={'Polno ime'}
                               label={'Polno ime'}
                               style={inputStyle}
                               onSubmit={(e) => {
                               }}
                               onBlur={(e) => {
                                   console.log('Triggered because this input lost focus', e.target.value);
                                   if (activeEditUser && fullName) {
                                       activeEditUser.full_name = mapCaretChars(fullName);
                                       updateUserDispatch(activeEditUser);
                                   }
                               }}
                        />
                        <Input white loadUser telephone onChange={setTelephone} placeholder={'Telefon'}
                               label={'Telefon'}
                               style={inputStyle}
                               onSubmit={(e) => {
                               }}
                               onBlur={(e) => {
                                   console.log('Triggered because this input lost focus', e.target.value);
                                   if (activeEditUser && telephone) {
                                       activeEditUser.telephone = mapCaretChars(telephone);
                                       updateUserDispatch(activeEditUser);
                                   }
                               }}/>
                        <div className={'container-row-2'}>
                            <div className={'row-2'}>
                                <Input white loadUser email onChange={setEmail} placeholder={'E-mail'}
                                       label={'E-mail'}
                                       style={inputStyle}
                                       onSubmit={(e) => {
                                       }}
                                       onBlur={(e) => {
                                           console.log('Triggered because this input lost focus', e.target.value);
                                           if (activeEditUser && email) {
                                               activeEditUser.username = mapCaretChars(email);
                                               updateUserDispatch(activeEditUser);
                                           }
                                       }}/>
                                <Button text={'PONASTAVI GESLO'} confirm action={() => {
                                    setRequestPasswordReset(true);
                                }}
                                        style={{
                                            marginTop: '-15px'
                                        }}
                                        textStyle={{
                                            fontSize: '14px'
                                        }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
        </div>
}

export default User;