import React, {useState, useEffect, useRef} from "react";
import readXlsxFile from 'read-excel-file';
import ReactExport from "react-export-excel";

import '../css/ImportUsers.css';
import Sidebar from "../components/js/Sidebar";
import {ReactComponent as IconUsersUpdated} from "../assets/svg/Icon users-updated.svg";
import {ReactComponent as IconUpload} from "../assets/svg/Icon feather-upload.svg";
import FileUpload from "../components/js/FileUpload";
import Header from "../components/js/Header";
import {useDispatch, useSelector} from "react-redux";
import {
    createCaretaker,
    createNewBar,
    createNewCategory,
    createNewProduct,
    createNewUser,
    loadAllBars,
    loadBars,
    loadCaretakers,
    loadCategories,
    loadComboOffers,
    loadProducts,
    loadUsers, setImportErrors,
    setSidebarWidth, updateBar, updateBarExcel, updateCategoryExcel, updateImportErrors, updateProductExcel
} from "../store/actions/actions";
import {
    findItem,
    hasItem, isSessionValid, mapCaretChars,
    parseBarDataExcel,
    parseCategoryDataExcel, parseMapCaretChars,
    parseProductDataExcel,
    parseUserDataExcel
} from "../helpers/helpers";
import {XLSX_SHEET_NAMES, XLSX_USERS_FIELDS} from "../constants/enums";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";

import Card from "../components/js/Card";
import Loader from "react-loader-spinner";
import colors from "../constants/colors";


function ImportUsers(props) {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    let dispatch = useDispatch();

    const user = useSelector(state => state.reducer.user);
    const userCaretaker = useSelector(state => state.reducer.userCaretaker);
    let token = useSelector(state => state.reducer.userToken);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);
    const importErrors = useSelector(state => state.reducer.importErrors);

    const users = useSelector(state => state.reducer.allUsers);
    const caretakers = useSelector(state => state.reducer.allCaretakers);
    const bars = useSelector(state => state.reducer.bars);
    const categories = useSelector(state => state.reducer.categories);
    const products = useSelector(state => state.reducer.products);
    const comboOffers = useSelector(state => state.reducer.comboOffers);

    const [file, setFile] = useState(null);
    const [usersImported, setUsersImported] = useState(false);
    const [fileImported, setFileImported] = useState(false);
    const [dataState, setDataState] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [dataImported, setDataImported] = useState(false);


    let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}

    useEffect(() => {
        if (user) {
            dispatch(loadAllBars(user.user_id, () => {
                console.log("Bars successfully loaded..");
                dispatch(loadUsers(user.user_id, () => {
                    console.log("Users successfully re-loaded after creation..");
                    dispatch(loadCaretakers(user.user_id, () => {
                        console.log("Caretakers successfully re-loaded after creation..");
                        dispatch(loadCategories(null, user, false, () => {
                            console.log("Categories successfully loaded..");
                            dispatch(loadProducts(null, user, false,() => {
                                console.log("Products successfully loaded..");
                                dispatch(loadComboOffers(null, () => {
                                    console.log("Combo Offers successfully loaded..");
                                    setTimeout(() => setDataLoaded(true), 1000)
                                }, () => {
                                    console.warn("Error loading combo offers..")
                                }));
                            }, () => {
                                console.warn("Error loading products..")
                            }));
                        }, () => {
                            console.warn("Error loading categories..")
                        }));
                    }, () => {
                        console.warn("Error loading caretakers..");
                    }));

                }, () => {
                    console.warn("Error loading users..");
                }));
            }, () => {
                console.warn("Error loading bars..")
            }));
            dispatch(setImportErrors([]));
        }
    }, [user]);

    useEffect(() => {
        if (dataLoaded || dataImported) {
            console.log("Users:", users);
            console.log("Caretakers:", caretakers);
            console.log("Bars:", bars);
            console.log("Categories:", categories);
            console.log("Products:", products);
            console.log("Combo offers:", comboOffers);
            console.log("Import errors:", importErrors);
        }
    }, [dataLoaded, dataImported]);

    useEffect(() => {
        let done = false;
        const asyncEffect = async () => {
            if (file) {
                await importUsers();
                setUsersImported(true);
                done = true;
            }
        }
        asyncEffect().then(() => console.log(done && "Users sheet done importing."));
    }, [file]);

    useEffect(() => {
        let done = false;
        const asyncEffect = async () => {
            if (users && usersImported) {
                await importBars();
                await importCategories();
                await importProducts();
                done = true;
                setFileImported(true);
            }
            if (usersImported) {
                setUsersImported(false);
            }
        }
        asyncEffect().then(() => {done && console.log("Bars, products, categories sheets finished importing."); setDataImported(true)});
    }, [usersImported]);

    const importUsers = async () => {
        await parseExcelFile(XLSX_SHEET_NAMES.USERS).then(async () => {
            await dispatch(loadUsers(user.user_id, () => {
                console.log("Users successfully re-loaded after creation..");
            }, () => {
                console.warn("Error loading users..");
            }));
            await dispatch(loadCaretakers(user.user_id, () => {
                console.log("Caretakers successfully re-loaded after creation..");
            }, () => {
                console.warn("Error loading caretakers..");
            }));

            console.log("Users successfully imported!");
        }).catch((e) => console.warn(e));
    };

    const importBars = async () => {
        await parseExcelFile(XLSX_SHEET_NAMES.BARS).then(async () => {
            await dispatch(loadAllBars(user.user_id, () => {
                console.log("Bars successfully loaded..");

            }, () => {
                console.warn("Error loading bars..")
            }));
            console.log("Bars successfully imported!");
        }).catch((e) => console.warn(e));
    };

    const importCategories = async () => {
        await parseExcelFile(XLSX_SHEET_NAMES.CATEGORIES).then(async () => {
            await dispatch(loadCategories(null, user, false,() => {
                console.log("Categories successfully loaded..");
            }, () => {
                console.warn("Error loading categories..")
            }));
            console.log("Categories successfully imported!");
        }).catch((e) => console.warn(e));
    };

    const importProducts = async () => {
        await parseExcelFile(XLSX_SHEET_NAMES.PRODUCTS).then(async () => {
            await dispatch(loadProducts(null, user, false,() => {
                console.log("Products successfully loaded..");
            }, () => {
                console.warn("Error loading products..")
            }));
            await dispatch(loadComboOffers(null, () => {
                console.log("Combo Offers successfully loaded..");
            }, () => {
                console.warn("Error loading combo offers..")
            }));

            console.log("Products successfully imported!");
        }).catch((e) => console.warn(e));
    };

    const parseDataAndDispatch = async (row, sheetName) => {
        //console.log(row);
        switch (sheetName) {
            case XLSX_SHEET_NAMES.USERS:
                const userParsed = parseUserDataExcel(row);
                //console.log(userParsed);

                if (!hasItem('external_id', 'user_id', userParsed.external_id, users)) {
                    await dispatch(createNewUser(userCaretaker.caretaker_id, userParsed, () => {
                        console.log("User successfully created!")
                    }, () => {
                        console.warn("Error creating user!")
                    }));
                    console.log("PARSED USER NOT FOUND ON USERS LIST", userParsed);
                }
                break;
            case XLSX_SHEET_NAMES.BARS:

                try {
                    const barParsed = parseBarDataExcel(row, users, caretakers);
                    //console.log("BAR PARSED", barParsed)

                    let bar = findItem('external_id', barParsed.external_id, bars)
                    if (!bar) {
                        try {
                            await dispatch(createNewBar(barParsed, user.username, () => {
                                console.log("Bar successfully created!")
                            }, (e) => {
                                console.warn("Error creating bar!")
                                dispatch(updateImportErrors({error: e, external_id: barParsed.external_id, name: barParsed.name}))
                            }));
                        } catch {
                        }
                        console.log("PARSED BAR NOT FOUND ON USERS LIST", barParsed.external_id, barParsed.name);
                    } else {
                        console.log("PARSED BAR UPDATE", barParsed.external_id, barParsed.name);
                        barParsed.bar_id = bar.bar_id;
                        await dispatch(updateBarExcel(barParsed, (res) => {
                            console.log("Bar successfully updated!")

                        }, (e) => {
                            console.warn("Error updating bar!")
                            dispatch(updateImportErrors({error: e, external_id: bar.external_id, name: bar.name}))
                        }));
                    }
                } catch (e) {
                    console.warn(e)
                    let external_id = row[0] ? mapCaretChars(row[0].toString()) : '';
                    let name = row[1] ? mapCaretChars(row[1].toString()) : '';

                    dispatch(updateImportErrors({
                        error: e.message,
                        external_id: external_id,
                        name
                    }))

                }

                break;
            case XLSX_SHEET_NAMES.CATEGORIES:
                const categoryParsed = parseCategoryDataExcel(row);
                //console.log(categoryParsed)

                try {
                    //console.log("BAR PARSED", barParsed)

                    let category = findItem('name', categoryParsed.name, categories)
                    if (!category) {
                        try {
                            console.log("PARSED CATEGORY NOT FOUND ON USERS LIST", categoryParsed);
                            await dispatch(createNewCategory(null, categoryParsed, () => {
                                console.log("Category successfully created!")
                            }, (e) => {
                                console.warn("Error creating category!")
                                dispatch(updateImportErrors({
                                    error: e,
                                    name: category.name
                                }))
                            }));
                        } catch {
                        }
                    } else {
                        console.log("PARSED CATEGORY UPDATE", categoryParsed.name, categoryParsed.position, categoryParsed.eng_name, categoryParsed.de_name, categoryParsed.it_name, categoryParsed.hr_name);
                        categoryParsed.menu_group_id = category.menu_group_id;
                        await dispatch(updateCategoryExcel(categoryParsed, (res) => {
                            console.log("Category successfully updated!", res)

                        }, (e) => {
                            console.warn("Error updating category!")
                            dispatch(updateImportErrors({
                                error: e,
                                name: category.name
                            }))
                        }));
                    }

                } catch (e) {
                    console.warn(e)
                    let name = row[0] ? mapCaretChars(row[0].toString()) : '';

                    dispatch(updateImportErrors({
                        error: e.message,
                        name: name
                    }))
                }
                break;
            case XLSX_SHEET_NAMES.PRODUCTS:
                const productParsed = parseProductDataExcel(row);
                console.log("PARSED PROD", productParsed)
                try {
                    if (productParsed.combo_offer === 'TRUE' || productParsed.combo_offer === true) {
                        let product = findItem('external_id', productParsed.external_id?.toString(), comboOffers)
                        if (!product) {
                            console.log("PARSED COMBO OFFER NOT FOUND ON USERS LIST", productParsed.name);
                            await dispatch(createNewProduct(productParsed, true, () => {
                                console.log("COMBO OFFER successfully created!")
                            }, (e) => {
                                console.warn("Error creating product!", e)
                                dispatch(updateImportErrors({
                                    error: e,
                                    name: product.name,
                                    external_id: product.external_id,
                                }))
                            }));
                        } else {
                            productParsed.product_id = product.product_id;
                            console.log("PARSED COMBO OFFER UPDATE", productParsed.name);
                            await dispatch(updateProductExcel(productParsed, (res) => {
                                console.log("COMBO OFFER successfully updated!", res)
                            }, (e) => {
                                console.warn("Error updating COMBO OFFER!", e)
                                dispatch(updateImportErrors({
                                    error: e,
                                    name: product.name,
                                    external_id: product.external_id,
                                }))
                            }));
                        }
                    } else {
                        let product = findItem('external_id', productParsed.external_id?.toString(), products)
                        if (!product) {
                            console.log("PARSED PRODUCT NOT FOUND ON USERS LIST", productParsed.name);
                            await dispatch(createNewProduct(productParsed, false, () => {
                                console.log("Product successfully created!")
                            }, (e) => {
                                console.warn("Error creating product!", e)
                                dispatch(updateImportErrors({
                                    error: e,
                                    name: product.name,
                                    external_id: product.external_id,
                                }))
                            }));
                        } else {
                            productParsed.product_id = product.product_id;
                            console.log("PARSED PRODUCT UPDATE", productParsed.name, productParsed.allergenes);
                            await dispatch(updateProductExcel(productParsed, (res) => {
                                console.log("Product successfully updated!")
                            }, (e) => {
                                console.warn("Error updating product!", e)
                                dispatch(updateImportErrors({
                                    error: e,
                                    name: product.name,
                                    external_id: product.external_id,
                                }))
                            }));
                        }
                    }


                } catch (e) {
                    console.warn(e)
                    let external_id = row[0] ? mapCaretChars(row[0].toString()) : '';
                    let name = row[1] ? mapCaretChars(row[1].toString()) : '';

                    dispatch(updateImportErrors({
                        error: e.message,
                        name: name,
                        external_id: external_id,
                    }))
                }
                break;
        }
    };

    const parseExcelFile = async (sheetName) => {
        await readXlsxFile(file, {sheet: sheetName}).then(async (rows) => {
            const START_ROW = 3;
            for (let i = START_ROW; i < rows.length; i++) {
                try {
                    let rowValid = false;
                    for (let j = 0; j < rows[i].length; j++) {
                        if (rows[i][j] !== null) {
                            rowValid = true;
                            break;
                        }
                    }
                    if (!rowValid) {
                        dispatch(updateImportErrors({error: `Prazna vrstica: ${i + 1}. Uvoz podatkov zaključen.`, external_id: '', name: ''}))
                        break;
                    }
                    //console.log(i, rows[i]);
                    await parseDataAndDispatch(rows[i], sheetName);

                } catch (e) {
                    console.warn(e)
                }
            }
        }).catch((e) => {

        });
    };


    function Item(props) {
        //console.log(props.value);
        const item = props.value;
        return (
            <li onClick={() => {
            }} className={'row'} style={{left: `${sidebarWidth + 50}px`}}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                }}>
                    <div className={'row-name'}>{item.error}</div>
                    <div className={'row-package'} style={{minWidth: '400px'}}>{parseMapCaretChars(item.name)}</div>
                    <div className={'row-unit'}>{item.external_id}</div>
                </div>
            </li>
        );
    }

    function renderImportErrors(items) {
        const header = () => {
            return (
                <div className={'row-header'} style={{
                    left: `${(documentSize.x - (sidebarWidth ? sidebarWidth : SIDEBAR_WIDTH * 2)) / 2}px`,
                    margin: '20px 0',
                    width: 'fit-content',
                    top: '620px'
                }}>
                    <div className={'row-name'}>Napaka</div>
                    <div className={'row-package'} style={{minWidth: '400px'}}>Bar [ime]</div>
                    <div className={'row-unit'}>Bar [external id]</div>
                </div>
            );
        };
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul className={'item-list'}
                    style={{left: `${(documentSize.x - (sidebarWidth ? sidebarWidth : + SIDEBAR_WIDTH * 2)) / 2}px`, top: '680px'}}>
                    {items.map((item, index) => <Item key={index} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {header()}
                {list(items)}
            </>
        );
    }

    /** DATA EXPORT **/
    const onFormatItemDataExcel = (item, sheetName) => {

        switch (sheetName) {
            case XLSX_SHEET_NAMES.USERS:
                let user = {
                    external_id: item.external_id,
                    full_name: parseMapCaretChars(item.full_name),
                    address: parseMapCaretChars(item.address),
                    username: item.username,
                    telephone: item.telephone,
                    type: item.type
                };
                /*
                let data = [
                    {value: item.external_id ? item.external_id.toString() : ''},
                    {value: item.full_name ? item.full_name.toString() : ''},
                    {value: item.address ? item.address.toString() : ''},
                    {value: item.username ? item.username.toString() : ''},
                    {value: item.telephone ? item.telephone.toString() : ''},
                    {value: item.type ? item.type.toString() : ''}
                ]

                console.log(data);
                 */
                return user;
            case XLSX_SHEET_NAMES.BARS:
                let addressTemp = parseMapCaretChars(item.address);

                let addrSplit = addressTemp.split(',');
                let address;
                let houseNmb;
                let city;
                let post;

                if (addrSplit.length > 1) {
                    for (let i = 0; i < addrSplit.length; i++) {
                        addrSplit[i] = addrSplit[i].trim();
                    }
                    if (addrSplit.length > 2) {
                        addrSplit[0] = addressTemp.substring(0, addressTemp.lastIndexOf(",") + 1);
                        addrSplit[0].trim();
                    }

                    houseNmb = addrSplit[0].split(' ');
                    houseNmb = houseNmb[houseNmb.length - 1]
                    if (Number(houseNmb) || houseNmb.length <= 4) {
                        if (houseNmb.includes(',')) {
                            houseNmb = houseNmb.substring(0, houseNmb.lastIndexOf(","));
                        }
                        address = addrSplit[0].substring(0, addrSplit[0].lastIndexOf(" ") + 1);
                    } else {
                        address = addrSplit[0];
                        houseNmb = '';
                    }
                    city = addrSplit[addrSplit.length - 1].substring(0, addrSplit[addrSplit.length - 1].lastIndexOf(" ") + 1);
                    post = addrSplit[addrSplit.length - 1].split(' ');
                    post = post[post.length - 1]
                }

                let caretaker = findItem("caretaker_id", item.caretaker_id, caretakers)
                let userCaretaker = findItem("user_id", caretaker.user_id, users)
                let barRep = findItem("user_id", item.user_id, users)
                let barOwner = findItem("user_id", item.bar_owner_id, users)

                let bar = {
                    external_id: item.external_id,
                    name: parseMapCaretChars(item.name),
                    address: address ? address : '',
                    houseNumber: houseNmb ? houseNmb : '',
                    post: post ? post : '',
                    city: city ? city : '',
                    country: 'SI',
                    tax_number: parseMapCaretChars(item.tax_number),
                    email_bar_representative: barRep ? barRep.username : '',
                    email_caretaker: caretaker ? userCaretaker.username : '',
                    email_owner: barOwner ? barOwner.username : ''
                };
                return bar;
            case XLSX_SHEET_NAMES.CATEGORIES:
                let category = {
                    name: parseMapCaretChars(item.name),
                    position: item.position,
                    eng_name: parseMapCaretChars(item.eng_name),
                    de_name: parseMapCaretChars(item.de_name),
                    it_name: parseMapCaretChars(item.it_name),
                    hr_name: parseMapCaretChars(item.hr_name),
                    own_brand: item.own_brand === true ? 'TRUE' : 'FALSE',
                };
                return category

                break;
            case XLSX_SHEET_NAMES.PRODUCTS:
                let product = {
                    external_id: item.external_id,
                    name: parseMapCaretChars(item.name),
                    slo_descr: parseMapCaretChars(item.slo_descr),
                    eng_descr: parseMapCaretChars(item.eng_descr),
                    packaging: parseMapCaretChars(item.packaging),
                    measure_unit: item.measure_unit,
                    allergenes: item.allergenes,
                    alcohol_percent: item.alcohol_percent,
                    combo_offer: item.combo_offer === true ? 'TRUE' : 'FALSE',
                    category_label: parseMapCaretChars(item.category_label),
                    eng_name: parseMapCaretChars(item.eng_name),
                    de_descr: parseMapCaretChars(item.de_descr),
                    it_name: parseMapCaretChars(item.it_name),
                    it_descr: parseMapCaretChars(item.it_descr),
                    hr_name: parseMapCaretChars(item.hr_name),
                    hr_descr: parseMapCaretChars(item.hr_descr),
                    own_brand: item.own_brand === true ? 'TRUE' : 'FALSE',
                };
                return product
        }
    }

    const onFormatExcelData = (sheetName) => {
        //console.log(row);
        //let multiDataSet
        let data = [];
        switch (sheetName) {
            case XLSX_SHEET_NAMES.USERS:
                data.push(
                    {
                        external_id: '100000001',
                        full_name: 'Gospod Admin Neznani',
                        address: 'Lastnikova ulica 3, 1000, Ljubljana',
                        username: '40301111',
                        telephone: 'lastnik@barcaffe.serv.si',
                        type: 'ADMIN'
                    }
                );
                data.push(
                    {
                        external_id: '*********',
                        full_name: '*********',
                        address: '*********',
                        username: '*********',
                        telephone: '*********',
                        type: '[VODJA, LASTNIK, POTNIK, ADMIN] <- ena izmed vrednosti'
                    }
                );
                for (let i = 0; i < users.length; i++) {
                    let item = users[i];
                    data.push(onFormatItemDataExcel(item, XLSX_SHEET_NAMES.USERS));
                    //console.log(item)
                }
                break;
            case XLSX_SHEET_NAMES.BARS:
                data.push(
                    {
                        external_id: '2397234086',
                        name: 'Bar Primer',
                        address: 'Primerna ulica',
                        houseNumber: '3A',
                        post: '1000',
                        city: 'Ljubljana',
                        country: 'SI',
                        tax_number: 'SI98973639',
                        email_bar_representative: 'vodja.lokala@primernadomena.si',
                        email_caretaker: 'potnik@primernadomena.si',
                        email_owner: 'lastnik@primernadomena.si'
                    }
                );
                data.push(
                    {
                        external_id: '*********',
                        name: '*********',
                        address: '*********',
                        houseNumber: '*********',
                        post: '*********',
                        city: '*********',
                        country: '*********',
                        tax_number: '*********',
                        email_bar_representative: '*********',
                        email_caretaker: '*********',
                        email_owner: '*********'
                    }
                );
                for (let i = 0; i < bars.length; i++) {

                    let item = bars[i];
                    if (!item) continue
                    //console.log(item)
                    data.push(onFormatItemDataExcel(item, XLSX_SHEET_NAMES.BARS));
                }
                break;
            case XLSX_SHEET_NAMES.CATEGORIES:
                data.push(
                    {
                        name: 'Piva',
                        position: '[1, 2, 3] <- ena izmed vrednosti',
                        eng_name: 'Ime ENG',
                        de_name: 'Ime DE',
                        it_name: 'Ime IT',
                        hr_name: 'Ime HR',
                        own_brand: 'FALSE',
                    }
                );
                data.push(
                    {
                        name: '*********',
                        position: '*********',
                        eng_name: '*********',
                        de_name: '*********',
                        it_name: '*********',
                        hr_name: '*********',
                        own_brand: '*********',
                    }
                );
                for (let i = 0; i < categories.length; i++) {
                    let item = categories[i];
                    data.push(onFormatItemDataExcel(item, XLSX_SHEET_NAMES.CATEGORIES));
                    //console.log(item)
                }
                break;
            case XLSX_SHEET_NAMES.PRODUCTS:
                data.push(
                    {
                        external_id: '1000',
                        name: 'Pivo Laško Golding',
                        category_label: 'Piva',
                        packaging: 'steklenica',
                        measure_unit: '0,5 L',
                        allergenes: '1, 4, 15, 16',
                        alcohol_percent: '4.7',
                        combo_offer: 'FALSE',
                        slo_descr: 'Opis izdelka...',
                        eng_name: 'Ime ENG',
                        eng_descr: 'Opis ENG...',
                        de_name: 'Ime DE',
                        de_descr: 'Opis DE...',
                        it_name: 'Ime IT',
                        it_descr: 'Opis IT...',
                        hr_name: 'Ime HR',
                        hr_descr: 'Opis HR...',
                        own_brand: 'FALSE',
                    }
                );
                data.push(
                    {
                        external_id: '*********',
                        name: '*********',
                        category_label: '*********',
                        packaging: '*********',
                        measure_unit: '*********',
                        allergenes: '*********',
                        alcohol_percent: '*********',
                        combo_offer: '*********',
                        slo_descr: '*********',
                        eng_name: '*********',
                        eng_descr: '*********',
                        de_name: '*********',
                        de_descr: '*********',
                        it_name: '*********',
                        it_descr: '*********',
                        hr_name: '*********',
                        hr_descr: '*********',
                        own_brand: '*********',
                    }
                );
                for (let i = 0; i < products.length; i++) {
                    let item = products[i];
                    data.push(onFormatItemDataExcel(item, XLSX_SHEET_NAMES.PRODUCTS));
                    //console.log(item)
                }


                break;
        }
        return data;
    };

    const renderDataExport = () => {
        if (!dataLoaded || dataState === 'import') {
            return <></>
        }

        let exportUsers = onFormatExcelData(XLSX_SHEET_NAMES.USERS);
        console.log("EXPORT USER", exportUsers);
        let exportBars = onFormatExcelData(XLSX_SHEET_NAMES.BARS);
        console.log("EXPORT BARS", exportBars);
        let exportCategories = onFormatExcelData(XLSX_SHEET_NAMES.CATEGORIES);
        console.log("EXPORT CATEGORIES", exportCategories);
        let exportProducts = onFormatExcelData(XLSX_SHEET_NAMES.PRODUCTS);
        console.log("EXPORT PRODUCTS", exportProducts);
        return (
            <ExcelFile element={<Card shadow style={{cursor: 'pointer', margin: '0 16px'}}
                                      onClick={() => {
                                      }} title={"IZVOZI PODATKE"}
                                      icon={<IconUpload className={'icon primary'} style={{transform: 'rotate(180deg)'}}
                                                        width={'85px'} height={'85px'}
                                                        title={'icon add'}/>}/>}>
                <ExcelSheet data={exportUsers} name={XLSX_SHEET_NAMES.USERS}>
                    <ExcelColumn label="External ID" value="external_id"/>
                    <ExcelColumn label="Polno ime" value="full_name"/>
                    <ExcelColumn label="Naslov" value="address"/>
                    <ExcelColumn label="Telefon" value="telephone"/>
                    <ExcelColumn label="Email" value="username"/>
                    <ExcelColumn label="Tip uporabnika" value="type"/>
                </ExcelSheet>
                <ExcelSheet data={exportBars} name={XLSX_SHEET_NAMES.BARS}>
                    <ExcelColumn label="External ID" value="external_id"/>
                    <ExcelColumn label="Ime lokala" value="name"/>
                    <ExcelColumn label="Naslov - ulica" value="address"/>
                    <ExcelColumn label="Naslov - hišna št." value="houseNumber"/>
                    <ExcelColumn label="Naslov - poštna št." value="post"/>
                    <ExcelColumn label="Naslov - pošta" value="city"/>
                    <ExcelColumn label="Država" value="country"/>
                    <ExcelColumn label="Davčna št." value="tax_number"/>
                    <ExcelColumn label="email - vodja lokala" value="email_bar_representative"/>
                    <ExcelColumn label="email - potnik" value="email_caretaker"/>
                    <ExcelColumn label="email - lastnik" value="email_owner"/>
                </ExcelSheet>
                <ExcelSheet data={exportCategories} name={XLSX_SHEET_NAMES.CATEGORIES}>
                    <ExcelColumn label="Kategorija" value="name"/>
                    <ExcelColumn label="Vrstni red" value="position"/>
                    <ExcelColumn label="Kategorija ENG" value="eng_name"/>
                    <ExcelColumn label="Kategorija DE" value="de_name"/>
                    <ExcelColumn label="Kategorija IT" value="it_name"/>
                    <ExcelColumn label="Kategorija HR" value="hr_name"/>
                    <ExcelColumn label="Lastna znamka" value="own_brand"/>
                </ExcelSheet>
                <ExcelSheet data={exportProducts} name={XLSX_SHEET_NAMES.PRODUCTS}>
                    <ExcelColumn label="External ID" value="external_id"/>
                    <ExcelColumn label="Ime" value="name"/>
                    <ExcelColumn label="Kategorija" value="category_label"/>
                    <ExcelColumn label="Pakiranje" value="packaging"/>
                    <ExcelColumn label="Enota" value="measure_unit"/>
                    <ExcelColumn label="Alergeni" value="allergenes"/>
                    <ExcelColumn label="Stopnja alkohola %" value="alcohol_percent"/>
                    <ExcelColumn label="Posebna ponudba" value="combo_offer"/>
                    <ExcelColumn label="Slovenski opis" value="slo_descr"/>
                    <ExcelColumn label="Ime ENG" value="eng_name"/>
                    <ExcelColumn label="Opis ENG..." value="eng_descr"/>
                    <ExcelColumn label="Ime DE" value="de_name"/>
                    <ExcelColumn label="Opis DE..." value="de_descr"/>
                    <ExcelColumn label="Ime IT" value="it_name"/>
                    <ExcelColumn label="Opis IT..." value="it_descr"/>
                    <ExcelColumn label="Ime HR" value="hr_name"/>
                    <ExcelColumn label="Opis HR..." value="hr_descr"/>
                    <ExcelColumn label="Lastna znamka" value="own_brand"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }

    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, []);

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div ref={ref}><Page centered fileUpload className={'main'} style={{height: `${documentSize.y + 10}px`}}
                            onToggleSidebar={(shown) => {
                                if (shown) {
                                    dispatch(setSidebarWidth(SIDEBAR_WIDTH))
                                } else {
                                    dispatch(setSidebarWidth(0))
                                }
                            }}>
            {/*<Sidebar/>*/}
            {/*<div className={'page centered file-upload'}>*/}
            <div className={'body'} style={{paddingLeft: `${sidebarWidth}px`}}>
                <Header left={`${sidebarWidth + 50}px`} title={'UVOZI DATOTEKO'}/>
                {fileImported &&
                <>
                    <IconUsersUpdated className={'icon'} width={'486px'} height={'256px'} title={'icon add users'}/>
                    <p className={'p-users-updated'}>PODATKI POSODOBLJENI</p>
                </>}
                {(!file || !fileImported) && <div style={{display: 'flex', flexDirection: 'row'}}>
                    {dataLoaded ? <>
                        <FileUpload button={'UVOZI DATOTEKO'} handleFile={(file) => {
                            setDataState('import');
                            console.log(file);
                            setFile(file)
                        }}/>
                        {renderDataExport()}
                    </> : <Loader
                        type="TailSpin"
                        color={colors.primary}
                        height={100}
                        width={100}
                        style={{paddingTop: '50px', paddingBottom: '50px'}}
                    />
                    }
                </div>
                }
                {fileImported && importErrors && importErrors.length > 0 && renderImportErrors(importErrors)}
            </div>
            {/*</div>*/}
        </Page>
        </div>
}

export default ImportUsers;