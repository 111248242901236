import React, {useState, useEffect, createRef, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import FileUpload from "../components/js/FileUpload";
import Input from "../components/js/Input";

import "../css/CreateComboOffer.css"
import * as CgIcons from "react-icons/cg";

import Modal from "../components/js/Modal";
import {
    createNewProduct,
    loadComboOffers,
    setCreateMenuActive,
    setSidebarWidth,
    updateProduct
} from "../store/actions/actions";
import Button from "../components/js/Button";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import {hasItem, isSessionValid, mapCaretChars, parseMapCaretChars} from "../helpers/helpers";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import countryDE from "../assets/img/country_de.png";
import countryIT from "../assets/img/country_it.png";
import countryHR from "../assets/img/country_hr.png";
import {useHistory} from "react-router-dom";

function CreateComboOffer(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let comboOffers = useSelector(state => state.reducer.comboOffers);
    const [name, setName] = useState(null);
    const [link, setLink] = useState(null);
    const [image, setImage] = useState(null);

    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    const [createTrigger, setCreateTrigger] = useState(false);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    useEffect(() => {
        dispatch(setCreateMenuActive(null));
    }, []);

    useEffect(() => {
        dispatch(loadComboOffers(null, () => {
            console.log("Combo Offers successfully loaded..");
        }, () => {
            console.warn("Error loading combo offers..")
        }));

    }, []);

    useEffect(() => {
        if (image) {
            if (image === 'REMOVE') {
                setImage(null);
            } else {
                setImage(image);
            }
        }
    }, [image]);

    const handleImageUpload = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.addEventListener("load", function () {
                //console.log("load");
                //console.log(reader.result);
                setImage(reader.result);
            }, false);
            reader.readAsDataURL(file);
        }
    };

    const previewImage = (base64Data) => {
        base64Data = base64Data === 'REMOVE' ? null : base64Data;
        return <div className={'container-img-upload'}>
            <img className={'img-upload'} src={base64Data} alt="banner" height={'111px'}/>
            <CgIcons.CgCloseO onClick={() => {
                setImage("REMOVE")
            }} className={'icon-close'}/>
        </div>
    };

    const renderFileUpload = (title) => {
        return <><p className={'p-title-small'}>{title}</p>
            <FileUpload comboOffer button={'Izberi in naloži sliko'}
                        handleFile={(file) => handleImageUpload(file)}/>
            {image && previewImage(image)}
        </>
    };


    useEffect(() => {
        console.log("Combo offers:", comboOffers);

        if (createTrigger && name) {
            const asyncEffect = async () => {
                let product = {
                    name: mapCaretChars(name),
                    initial_price: 0,
                    bar_id: null,
                    picture: image,
                    combo_offer: true,
                    link: link,
                };
                console.log(product.link, product)
                if (!hasItem('name', 'name', product.name, comboOffers)) {
                    await dispatch(createNewProduct(product, true, () => {
                        alert(`Vmesni oglas '${name}' uspešno dodan.`);

                        onNavigateTo(PAGES.ADS_LIST)

                    }, () => {
                        console.warn("Error creating combo offer!")
                        alert("Napaka pri dodajanju vmesnega oglasa...");
                        setCreateTrigger(false);

                    }));
                } else {
                    alert(`Vmesni oglas z imenom "${parseMapCaretChars(product.name)}" že obstaja v bazi!`);
                    setCreateTrigger(false);

                    onNavigateTo(PAGES.ADS_LIST)
                }
            }
            asyncEffect().then(() => console.log("Combo offer post done.."));
        }
    }, [createTrigger, comboOffers]);

    let inputStyle = {marginBottom: '48px'};
    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page relative className={'main'} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            {/*<Sidebar/>*/}
            {/*<div className={'page relative'}>*/}
            <div className={'body'} style={{paddingLeft: `${sidebarWidth + 50}px`}}>
                <Header left={`${sidebarWidth + 50}px`} title={'IZDELAVA VMESNEGA OGLASA'}/>

                <div className={'container-column-2'} style={{height: '100%'}}>
                    <div className={'column-2'}>
                        <Input white onChange={setName} placeholder={''} label={'Ime'}
                               style={{marginBottom: '12px'}}/>
                        <Input white onChange={setLink} placeholder={''} label={'Link (url)'}
                               style={{marginBottom: '0px'}}/>

                    </div>
                    <div className={'column-2'}>
                        {renderFileUpload('Slika izdelka')}
                        <div style={{height: '94px'}}/>
                    </div>
                </div>


                <Button text={'USTVARI VMESNI OGLAS'} confirm action={() => {
                    setCreateTrigger(true)
                }} style={{
                    margin: '36px 0', width: '350px'
                }}/>
            </div>
            {/*</div>*/}
        </Page>
        </div>
}

export default CreateComboOffer;