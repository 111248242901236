import React, {useEffect, useState} from "react";
import Masonry from "react-responsive-masonry"

import '../css/CreateMenuAddCategories.css';

import {useDispatch, useSelector} from "react-redux";

import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import Footer from "../components/js/Footer";
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import Button from "../components/js/Button";
import {loadCategories, setCreateMenuActive, setIsCreatingMenu, setSidebarWidth} from "../store/actions/actions";
import {PAGES} from "../App";
import {
    isSessionValid, isUserCaretaker,
    parseMapCaretChars,
    saveToLocalStorage,
    sortCategoriesByLockPosition,
    validateUserInput
} from "../helpers/helpers";
import Modal from "../components/js/Modal";
import {Redirect} from "react-router";

import Page, {SIDEBAR_WIDTH} from "../components/js/Page";

import colors from "../constants/colors";


const parseStoreCategories = (categories) => {
    let array = [];
    for (let i = 0; i < categories.length; i++) {
        let element = {category: categories[i], products: []}
        array.push(element);
    }
    return array;
};


function CreateMenuAddCategories(props) {
    const dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    const createMenuActive = useSelector(state => state.reducer.createMenuActive);

    let user = useSelector(state => state.reducer.user);
    let activeBar = useSelector(state => state.reducer.activeBar);
    let categories = useSelector(state => state.reducer.categories);

    const [categoriesPool, setCategoriesPool] = useState(parseStoreCategories(categories, createMenuActive.categories));
    const [categoriesFinal, setCategoriesFinal] = useState(createMenuActive.categories);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    const [draggedItem, setDraggedItem] = useState(null);

    const [modal, setModal] = useState(false);

    const [tabletMode, setTabletMode] = useState(true);

    useEffect(() => {
        if (categories && createMenuActive) {
            console.log("Categories pool:", categories);
            setCategoriesPool(parseStoreCategories(categories, createMenuActive.categories));
        }
    }, [categories, createMenuActive]);

    useEffect(() => {
        let done = false;
        const asyncEffect = async () => {
            if (user) {
                await dispatch(loadCategories(activeBar.bar_id, user, false, () => {
                    console.log("Categories successfully loaded..");
                    done = true;
                }, () => {
                    console.warn("Error loading categories..")
                }));
            }
        }
        asyncEffect().then(() => console.log(done && "done loading data."));
    }, [user]);


    useEffect(() => {
        console.log("Categories (re)loaded:", categories);

        /*
        if (categories) {
            let filtered = [...parseStoreCategories(categories, categoriesFinal)]
            for (let i = 0; i < categoriesFinal.length; i++) {
                filtered = filtered.filter(element => element.category.name !== categoriesFinal[i].category.name);
            }
            setArrayAction(categoriesPool, [...filtered]);
        }
         */


    }, [categories]);
    useEffect(() => {
        dispatch(setIsCreatingMenu(2));
    }, []);
    useEffect(() => {
        if (categoriesFinal && categoriesFinal.length > 0) {
            console.log("CATEGORIES FINAL:", categoriesFinal);
            onConfirmCurrentChanges();
            saveToLocalStorage(createMenuActive)
        }
    }, [categoriesFinal]);

    const onConfirmCurrentChanges = () => {
        const isUserInputValid = categoriesFinal && categoriesFinal.length > 0

        if (!isUserInputValid) {
            alert("Za nadaljevanje najprej izberite željene kategorije izdelkov..");
            return false;
        }
        createMenuActive.categories = categoriesFinal;
        console.log(createMenuActive);

        dispatch(setCreateMenuActive(createMenuActive));
        return true;
    };


    const handleDragStart = (isFinalCategorySelected, category) => {
        //console.log("drag started..");

        if (isFinalCategorySelected) {
            console.log(category);
            setDraggedItem(category);
        }
    };

    const handleDragEnd = () => {
        // console.log("drag ended..");
    };

    const handleDragLeave = event => {
        event.stopPropagation()
        event.preventDefault()

        // console.log("drag left drop zone!");
    };
    const handleDragOver = event => {
        event.stopPropagation()
        event.preventDefault()

        // console.log("drag over drop zone!");
    };
    const handleDragEnter = event => {
        event.stopPropagation()
        event.preventDefault()

        // console.log("drag entered drop zone!");
    };

    const handleDropCategorySort = (event, objDropZone, idxDropZone) => {
        event?.stopPropagation()
        event?.preventDefault()
//        console.log("drag dropped on name sort zone!");

        if (!createMenuActive.beer_cider_menu) {
            if ((objDropZone && objDropZone.category.position && Number(objDropZone.category.position)) || (draggedItem.category.position && Number(draggedItem.category.position))) {
                console.log("Can not sort a category with locked position! Only in normal menu creation. Beer & Cider menus exclude this rule.");
                return;
            }
        }

        console.log("DROP ON CATEGORY, SELECTED CATEGORIES:", selectedCategories);
        if (selectedCategories.length !== 0) {
            handleDrop(event);
            return;
        }

        let idx = -1;
        let updated = [...categoriesFinal];
        console.log("SEARCH:", draggedItem);
        for (let i = 0; i < updated.length; i++) {
            console.log(updated[i].category.name);
            if (updated[i].category.name === draggedItem.category.name) {
                idx = i;
                break;
            }
        }
        console.log(idx);
        console.log("CATEGORIES FINAL", categoriesFinal);


        updated.splice(idx, 1);
        console.log("UPDATED", updated);
        updated.splice(idxDropZone, 0, draggedItem);
        console.log("UPDATED", updated);
        setArrayAction(categoriesFinal, updated);

        setDraggedItem(null);
    };

    const handleDrop = event => {
        event?.stopPropagation()
        event?.preventDefault()
        console.log("drag dropped!");

        console.log("SELECTED", selectedCategories);
        console.log("FINAL", categoriesFinal);

        if (selectedCategories) {

            let alreadyExists = false
            let addCategories = [];
            let updated;

            if (!categoriesFinal || categoriesFinal.length === 0) {
                updated = [...selectedCategories];
                setArrayAction(categoriesFinal, updated)
            } else {

                for (let i = 0; i < selectedCategories.length; i++) {
                    for (let j = 0; j < categoriesFinal.length; j++) {
                        if (selectedCategories[i].category.name === categoriesFinal[j].category.name) {
                            alreadyExists = true;
                            break;
                        }
                    }
                    if (!alreadyExists) {
                        console.log("add", selectedCategories[i].category.name);
                        addCategories.push(selectedCategories[i]);
                    } else {
                        alreadyExists = false;
                    }
                }
                updated = [...categoriesFinal, ...addCategories];
                setArrayAction(categoriesFinal, updated)
            }

            console.log("UPDATED", updated);
            let sorted = sortCategoriesByLockPosition(updated);
            console.log("SORTED:", sorted);
            setArrayAction(categoriesFinal, sorted)

            // setArrayAction(categoriesFinal, [...selectedCategories])

            let filtered = [...categoriesPool]
            for (let i = 0; i < addCategories.length; i++) {
                filtered = filtered.filter(element => element.category.name !== selectedCategories[i].category.name);
            }
            setArrayAction(categoriesPool, [...filtered]);
            setArrayAction(selectedCategories, []);
        }
        setDraggedItem(null);
    };

    const handleDropOnCategoryPool = event => {
        event?.stopPropagation()
        event?.preventDefault()
        console.log("drag dropped on name pool zone!");

        removeFromCategoriesFinal(draggedItem);
        setDraggedItem(null);
    };


    const removeFromCategoriesFinal = (obj) => {
        try {
            //prevent dropping on itself
            if (selectedCategories.length !== 0) {
                return;
            }

            let finalUpdated = [];

            console.log('OBJ', obj);
            finalUpdated = removeCategory(obj, categoriesFinal);

            console.log("UPDATED ", finalUpdated);

            let filtered = [...parseStoreCategories(categories)]
            for (let i = 0; i < finalUpdated.length; i++) {
                filtered = filtered.filter(element => element.category.name !== finalUpdated[i].category.name);
            }
            console.log("POOL", [...filtered]);

            setArrayAction(categoriesPool, [...filtered]);
        } catch (e) {
            console.log(e, "probably dropped onto itself");
        }
    };

    const isCategorySelected = (selection, array) => {
        if (!array) {
            return false;
        }
        for (let i = 0; i < array.length; i++) {
            if (array[i].category.name === selection.category.name) {
                return true;
            }
        }
        return false;
    };


    const selectCategory = (obj, index, array) => {
        if (!isCategorySelected(obj, array)) {
            setArrayAction(array, [...array, obj])
        } else {
            removeCategory(obj, array);
        }
    };

    const removeCategory = (obj, array) => {
        let filtered = array.filter(element => element.category.name !== obj.category.name);
        console.log(array, filtered);
        setArrayAction(array, filtered)

        console.log("FILTERED", filtered);
        return [...filtered];
    };

    const setArrayAction = (array, content) => {
        switch (array) {
            case categoriesPool:
                setCategoriesPool(content);
                break;
            case selectedCategories:
                setSelectedCategories(content);
                break;
            case categoriesFinal:
                setCategoriesFinal(content);
                break;
        }
    };

    const clearSelection = (array) => {
        setArrayAction(array, []);
    };

    const renderModal = () => {
        return (
            modal && <Modal category actionClose={() => {
                console.log('display modal, false');
                setModal(false)
            }}/>
        );
    };

    const categoryItemName = 'create-menu-category-pool-item';
    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page relative editMenuAddProducts className={'main'} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            {/*<Sidebar/>*/}
            {/*<div className={'page relative edit-menu-add-products'}>*/}
            <Header left={`${sidebarWidth + 50}px`} title={'DOLOČI KATEGORIJE ZA CENIK'} step={'3. KORAK'}/>
            <div className={'body'} style={{paddingLeft: `${sidebarWidth + 50}px`}}>
                <div onClick={() => setTabletMode(!tabletMode)} className={'container-icon-selected'}
                     style={{paddingRight: 40}}>
                    <BsIcons.BsTablet size={20} fill={tabletMode ? colors.primary : ''} style={{cursor: 'pointer'}}/>
                </div>
                <div className={'container-column-2'}>
                    <div className={'column-2 drop-zone-category-pool'}
                         onDragOver={handleDragOver} onDragEnter={handleDragEnter}
                         onDragLeave={handleDragLeave} onDrop={handleDropOnCategoryPool}>
                        <p className={'p-title-small'}>{'S klikom izberite med prednastavljenimi kategorijami, ali pa ustvarite novo'}</p>
                        <Masonry columnsCount={2} className={'masonry-create-menu-categories-pool'} gutter="8px">
                            <Button className={'button-add-category'} text={'USTVARI UNIKATNO KATEGORIJO'}
                                    action={() => {
                                        setModal(true)
                                    }} style={{width: '100%', marginBottom: '12px'}}/>

                            {tabletMode ?
                                <div onClick={handleDrop} className={'container-icon-selected'}
                                     style={{paddingRight: 45, paddingTop: 35, height: '93px'}}>
                                    <BsIcons.BsCheckAll size={40}
                                                        fill={selectedCategories && selectedCategories.length > 0 ? colors.primary : '#707070'}
                                                        style={{cursor: 'pointer'}}/>
                                </div>
                                : <div style={{height: '56px', marginBottom: '12px'}}/>}
                            {categoriesPool.map((category, c) => (
                                <div key={`${c}_${category}`}
                                     className={isCategorySelected(category, selectedCategories) ? categoryItemName + ' selected' : categoryItemName}
                                     onClick={() => selectCategory(category, c, selectedCategories)}
                                     onDoubleClick={() => clearSelection(selectedCategories)}
                                     draggable="true"
                                     onDragStart={() => handleDragStart(false)}
                                     onDragEnd={handleDragEnd}>
                                    <p className={'p-title-product'}>{parseMapCaretChars(category.category.name)}</p>
                                    <div className={'container-icon-selected'}>
                                        <AiIcons.AiFillCheckCircle/>
                                    </div>
                                </div>
                            ))}
                        </Masonry>
                    </div>
                    <div className={'column-2 selected-category-drop-zone'}
                         onDragOver={handleDragOver} onDragEnter={handleDragEnter}
                         onDragLeave={handleDragLeave} onDrop={handleDrop}
                         draggable={!tabletMode}>
                        <p className={'p-title-small'}>{'Izbrane kategorije za vaš cenik'}</p>
                        {categoriesFinal.map((category, c) => (
                            <div key={`${c}_${category}`}
                                 style={{
                                     border: draggedItem?.category?.name === category.category.name ? '3px solid #D30263' : 'none',
                                     pointerEvents: category.category?.own_brand && !isUserCaretaker(user) ? 'none' : 'all',
                                     cursor: category.category?.own_brand && !isUserCaretaker(user) ? 'default' : 'pointer',
                                     backgroundColor: category.category?.own_brand && !isUserCaretaker(user) ? '#e3e3e3' : 'white'
                                 }}
                                 className={!createMenuActive.beer_cider_menu && category.category.position && Number(category.category.position) ? 'create-menu-category-item category locked' : 'create-menu-category-item category'}
                                 draggable={!tabletMode}
                                 onClick={(event) => {
                                     if (tabletMode) {
                                         if (event.target === event.currentTarget) {
                                             event.stopPropagation()
                                             console.log("stopped propagation");

                                             // exclude beer & cider from locked categories
                                             if (!createMenuActive.beer_cider_menu) {
                                                 if (category.category.position && Number(category.category.position)) {
                                                     return;
                                                 }
                                             }
                                             if (!draggedItem) {
                                                 handleDragStart(true, category);
                                             } else {
                                                 handleDropCategorySort(null, category, c);
                                             }
                                         }

                                     }
                                 }}
                                 onDragStart={() => handleDragStart(true, category)}
                                 onDragEnd={handleDragEnd}
                                 onDrop={(event) => handleDropCategorySort(event, category, c)}>
                                <p className={'p-title-product'}>{parseMapCaretChars(category.category ? category.category.name : '')}</p>
                                <div className={'container-icon-selected'}>
                                    {!createMenuActive.beer_cider_menu && category.category.position && Number(category.category.position) ?
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                            <BsIcons.BsLockFill style={{width: "25px", height: '25px'}}/>
                                            {tabletMode && <div onClick={() => {
                                                if (tabletMode) {
                                                    removeFromCategoriesFinal(category);
                                                    setDraggedItem(null);
                                                }
                                            }}><BsIcons.BsTrash
                                                style={{width: "25px", height: '25px', cursor: 'pointer'}}/></div>}
                                        </div> : tabletMode ?
                                            !draggedItem ? <div onClick={() => {
                                                    if (tabletMode) {
                                                        removeFromCategoriesFinal(category);
                                                        setDraggedItem(null);
                                                    }
                                                }}><BsIcons.BsTrash
                                                    style={{width: "25px", height: '25px', cursor: 'pointer'}}/></div> :
                                                <BsIcons.BsArrowsMove
                                                    style={{
                                                        width: "25px",
                                                        height: '25px',
                                                        cursor: 'pointer',
                                                        marginTop: '-3px'
                                                    }}/> :
                                            <BsIcons.BsArrowsMove style={{width: "25px", height: '25px'}}/>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer back={'NAZAJ'}
                    left={`${sidebarWidth}px`}
                    urlBack={PAGES.CREATE_MENU_CHOOSE_BACKGROUND}
                    next={'POTRDI IN NADALJUJ'}
                    urlNext={PAGES.CREATE_MENU_ADD_PRODUCTS}
                    onActionNext={() => {
                        return onConfirmCurrentChanges()
                    }}
                    progress={3}/>
            {/*</div>*/}
        </Page>
            {renderModal()}
        </div>

}

export default CreateMenuAddCategories;

