import React, {useState, useEffect, useCallback, useRef} from "react";
import Header from "../components/js/Header";
import Input from "../components/js/Input";

import "../css/ProductBarEdit.css"
import "../css/Category.css"
import * as CgIcons from "react-icons/cg";

import Modal from "../components/js/Modal";
import {useDispatch, useSelector} from "react-redux";
import {
    displayAlert,
    isSessionValid,
    isUserAdmin,
    isUserCaretaker,
    mapCaretChars,
    parseMapCaretChars
} from "../helpers/helpers";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import {
    setCreateMenuActive, setSidebarWidth, updateCategory
} from "../store/actions/actions";
import {useHistory} from "react-router-dom";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";

import countrySI from "../assets/img/country_si.png";
import countryEN from "../assets/img/country_en.png";
import countryDE from "../assets/img/country_de.png";
import countryIT from "../assets/img/country_it.png";
import countryHR from "../assets/img/country_hr.png";
import Collapsible from "react-collapsible";
import {L10N_FIELDS, L10N_MENU} from "../constants/enums";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";

import iconDrink from "../assets/img/barcaffe/i-drink.png";
import iconTea from "../assets/img/barcaffe/i-tea.png";
import iconWine from "../assets/img/barcaffe/i-wine.png";
import iconFood from "../assets/img/barcaffe/i-food.png";
import iconSnack from "../assets/img/barcaffe/i-snack.png";
import iconCake from "../assets/img/barcaffe/i-cake.png";

import iconDrinkB from "../assets/img/barcaffe/i-drink-b.png";
import iconTeaB from "../assets/img/barcaffe/i-tea-b.png";
import iconWineB from "../assets/img/barcaffe/i-wine-b.png";
import iconFoodB from "../assets/img/barcaffe/i-food-b.png";
import iconSnackB from "../assets/img/barcaffe/i-snack-b.png";
import iconCakeB from "../assets/img/barcaffe/i-cake-b.png";

import iconDrink00W from "../assets/img/barcaffe/icon-drink-00-w.png";
import iconDrink002W from "../assets/img/barcaffe/icon-drink-00-2-w.png";
import iconDrink003W from "../assets/img/barcaffe/icon-drink-00-3-w.png";
import iconDrink004W from "../assets/img/barcaffe/icon-drink-2-w.png";
import iconDrink005W from "../assets/img/barcaffe/icon-drink-beer-draft-w.png";
import iconDrink006W from "../assets/img/barcaffe/icon-drink-beer-glass-w.png";
import iconDrink007W from "../assets/img/barcaffe/icon-drink-coffee-1-w.png";
import iconDrink008W from "../assets/img/barcaffe/icon-drink-combo-w.png";
import iconDrink009W from "../assets/img/barcaffe/icon-drink-food-w.png";
import iconDrink0010W from "../assets/img/barcaffe/icon-drink-hot-w.png";
import iconDrink0011W from "../assets/img/barcaffe/icon-drink-pint-w.png";
import iconDrink0012W from "../assets/img/barcaffe/icon-drink-snacks-w.png";
import iconDrink0013W from "../assets/img/barcaffe/icon-drink-spirits-w.png";
import iconDrink0014W from "../assets/img/barcaffe/icon-drink-wine-w.png";

import iconDrink0015W from "../assets/img/barcaffe/BARCAFFEE IKONE – 31.png";
import iconDrink0016W from "../assets/img/barcaffe/BARCAFFEE IKONE – 32.png";
import iconDrink0017W from "../assets/img/barcaffe/BARCAFFEE IKONE – 33.png";
import iconDrink0018W from "../assets/img/barcaffe/BARCAFFEE IKONE – 34.png";
import iconDrink0019W from "../assets/img/barcaffe/BARCAFFEE IKONE – 35.png";
import iconDrink0020W from "../assets/img/barcaffe/BARCAFFEE IKONE – 36.png";
import iconDrink0021W from "../assets/img/barcaffe/BARCAFFEE IKONE – 37.png";
import iconDrink0022W from "../assets/img/barcaffe/BARCAFFEE IKONE – 38.png";
import iconDrink0023W from "../assets/img/barcaffe/BARCAFFEE IKONE – 39.png";
import iconDrink0024W from "../assets/img/barcaffe/BARCAFFEE IKONE – 40.png";
import iconDrink0025W from "../assets/img/barcaffe/BARCAFFEE IKONE – 41.png";
import iconDrink0026W from "../assets/img/barcaffe/BARCAFFEE IKONE – 42.png";
import iconDrink0027W from "../assets/img/barcaffe/BARCAFFEE IKONE – 43.png";
import iconDrink0028W from "../assets/img/barcaffe/BARCAFFEE IKONE – 44.png";

import iconDrink0030W from "../assets/img/barcaffe/BARCAFFEE IKONE – 45.png";
import iconDrink0031W from "../assets/img/barcaffe/BARCAFFEE IKONE – 46.png";
import iconDrink0032W from "../assets/img/barcaffe/BARCAFFEE IKONE – 48.png";
import iconDrink0033W from "../assets/img/barcaffe/BARCAFFEE IKONE – 49.png";
import iconDrink0034W from "../assets/img/barcaffe/BARCAFFEE IKONE – 50.png";
import iconDrink0035W from "../assets/img/barcaffe/BARCAFFEE IKONE – 51.png";
import iconDrink0036W from "../assets/img/barcaffe/BARCAFFEE IKONE – 52.png";
import iconDrink0037W from "../assets/img/barcaffe/BARCAFFEE IKONE – 53.png";
import iconDrink0038W from "../assets/img/barcaffe/BARCAFFEE IKONE – 54.png";
import iconDrink0039W from "../assets/img/barcaffe/BARCAFFEE IKONE – 55.png";
import iconDrink0040W from "../assets/img/barcaffe/BARCAFFEE IKONE – 56.png";
import iconDrink0041W from "../assets/img/barcaffe/BARCAFFEE IKONE – 57.png";
import iconDrink0042W from "../assets/img/barcaffe/BARCAFFEE IKONE – 58.png";
import iconDrink0043W from "../assets/img/barcaffe/BARCAFFEE IKONE – 59.png";
import iconDrink0044W from "../assets/img/barcaffe/BARCAFFEE IKONE – 60.png";


import iconDrink00 from "../assets/img/barcaffe/icon-drink-00.png";
import iconDrink002 from "../assets/img/barcaffe/icon-drink-00-2.png";
import iconDrink003 from "../assets/img/barcaffe/icon-drink-00-3.png";
import iconDrink004 from "../assets/img/barcaffe/icon-drink-2.png";
import iconDrink005 from "../assets/img/barcaffe/icon-drink-beer-draft.png";
import iconDrink006 from "../assets/img/barcaffe/icon-drink-beer-glass.png";
import iconDrink007 from "../assets/img/barcaffe/icon-drink-coffee-1.png";
import iconDrink008 from "../assets/img/barcaffe/icon-drink-combo.png";
import iconDrink009 from "../assets/img/barcaffe/icon-drink-food.png";
import iconDrink0010 from "../assets/img/barcaffe/icon-drink-hot.png";
import iconDrink0011 from "../assets/img/barcaffe/icon-drink-pint.png";
import iconDrink0012 from "../assets/img/barcaffe/icon-drink-snacks.png";
import iconDrink0013 from "../assets/img/barcaffe/icon-drink-spirits.png";
import iconDrink0014 from "../assets/img/barcaffe/icon-drink-wine.png";

import iconDrink0015 from "../assets/img/barcaffe/BARCAFFEE IKONE – 1.png";
import iconDrink0016 from "../assets/img/barcaffe/BARCAFFEE IKONE – 2.png";
import iconDrink0017 from "../assets/img/barcaffe/BARCAFFEE IKONE – 3.png";
import iconDrink0018 from "../assets/img/barcaffe/BARCAFFEE IKONE – 4.png";
import iconDrink0019 from "../assets/img/barcaffe/BARCAFFEE IKONE – 5.png";
import iconDrink0020 from "../assets/img/barcaffe/BARCAFFEE IKONE – 6.png";
import iconDrink0021 from "../assets/img/barcaffe/BARCAFFEE IKONE – 7.png";
import iconDrink0022 from "../assets/img/barcaffe/BARCAFFEE IKONE – 8.png";
import iconDrink0023 from "../assets/img/barcaffe/BARCAFFEE IKONE – 9.png";
import iconDrink0024 from "../assets/img/barcaffe/BARCAFFEE IKONE – 10.png";
import iconDrink0025 from "../assets/img/barcaffe/BARCAFFEE IKONE – 11.png";
import iconDrink0026 from "../assets/img/barcaffe/BARCAFFEE IKONE – 12.png";
import iconDrink0027 from "../assets/img/barcaffe/BARCAFFEE IKONE – 13.png";
import iconDrink0028 from "../assets/img/barcaffe/BARCAFFEE IKONE – 14.png";

import iconDrink0030 from "../assets/img/barcaffe/BARCAFFEE IKONE – 15.png";
import iconDrink0031 from "../assets/img/barcaffe/BARCAFFEE IKONE – 16.png";
import iconDrink0032 from "../assets/img/barcaffe/BARCAFFEE IKONE – 18.png";
import iconDrink0033 from "../assets/img/barcaffe/BARCAFFEE IKONE – 19.png";
import iconDrink0034 from "../assets/img/barcaffe/BARCAFFEE IKONE – 20.png";
import iconDrink0035 from "../assets/img/barcaffe/BARCAFFEE IKONE – 21.png";
import iconDrink0036 from "../assets/img/barcaffe/BARCAFFEE IKONE – 22.png";
import iconDrink0037 from "../assets/img/barcaffe/BARCAFFEE IKONE – 23.png";
import iconDrink0038 from "../assets/img/barcaffe/BARCAFFEE IKONE – 24.png";
import iconDrink0039 from "../assets/img/barcaffe/BARCAFFEE IKONE – 25.png";
import iconDrink0040 from "../assets/img/barcaffe/BARCAFFEE IKONE – 26.png";
import iconDrink0041 from "../assets/img/barcaffe/BARCAFFEE IKONE – 27.png";
import iconDrink0042 from "../assets/img/barcaffe/BARCAFFEE IKONE – 28.png";
import iconDrink0043 from "../assets/img/barcaffe/BARCAFFEE IKONE – 29.png";
import iconDrink0044 from "../assets/img/barcaffe/BARCAFFEE IKONE – 30.png";


export let ICONS = [iconDrink00W, iconDrink002W, iconDrink003W, iconDrink004W, iconDrink005W, iconDrink006W, iconDrink007W, iconDrink008W, iconDrink009W, iconDrink0010W, iconDrink0011W, iconDrink0012W, iconDrink0013W, iconDrink0014W, iconDrink0015W, iconDrink0016W, iconDrink0017W, iconDrink0018W, iconDrink0019W, iconDrink0020W, iconDrink0021W, iconDrink0022W, iconDrink0023W, iconDrink0024W, iconDrink0025W, iconDrink0026W, iconDrink0027W, iconDrink0028W, iconDrink0030W, iconDrink0031W, iconDrink0032W, iconDrink0033W, iconDrink0034W, iconDrink0035W, iconDrink0036W, iconDrink0037W, iconDrink0038W, iconDrink0039W, iconDrink0040W, iconDrink0041W, iconDrink0042W, iconDrink0043W, iconDrink0044W]
export let ICONS_BlACK = [iconDrink00, iconDrink002, iconDrink003, iconDrink004, iconDrink005, iconDrink006, iconDrink007, iconDrink008, iconDrink009, iconDrink0010, iconDrink0011, iconDrink0012, iconDrink0013, iconDrink0014, iconDrink0015, iconDrink0016, iconDrink0017, iconDrink0018, iconDrink0019, iconDrink0020, iconDrink0021, iconDrink0022, iconDrink0023, iconDrink0024, iconDrink0025, iconDrink0026, iconDrink0027, iconDrink0028, iconDrink0030, iconDrink0031, iconDrink0032, iconDrink0033, iconDrink0034, iconDrink0035, iconDrink0036, iconDrink0037, iconDrink0038, iconDrink0039, iconDrink0040, iconDrink0041, iconDrink0042, iconDrink0043, iconDrink0044]

function Category(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let selectedCategory = useSelector(state => state.reducer.selectedCategory);
    const user = useSelector(state => state.reducer.user);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    console.log("selectedCategory", selectedCategory);


    const [name, setName] = useState(null);
    const [nameEn, setNameEn] = useState(null);
    const [nameDe, setNameDe] = useState(null);
    const [nameIt, setNameIt] = useState(null);
    const [nameHr, setNameHr] = useState(null);
    const [areIconsOpen, setAreIconsOpen] = useState([]);
    const [selectedIconIdx, setSelectedIconIdx] = useState(selectedCategory?.icon ? Number(selectedCategory?.icon) : null);

    const [isOwnBrand, setIsOwnBrand] = useState(selectedCategory?.own_brand ? selectedCategory.own_brand : false);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);


    useEffect(() => {
        dispatch(setCreateMenuActive(null));
    }, []);

    const updateCategoryDispatch = (category) => {
        /*
                if (user.type !== 'ADMIN') {
                    product.category_label = selectedProduct.category_label
                }

         */

        dispatch(updateCategory(category, () => {
            console.log("Category successfully updated!");
        }, () => {
            console.warn("Error updating product..")
        }));
    }

    let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
    const [mainHeight, setMainHeight] = useState(documentSize.y);
    useEffect(() => {
        setTimeout(() => {
            let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
            setMainHeight(documentSize.y);
        }, 350)
    }, [])

    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, []);

    const onActionCollapse = (c, state) => {
        let updated = areIconsOpen;
        updated[c] = state
        console.log(updated)
        setAreIconsOpen(updated);
    }


    const displayCollapsibleArrow = (name, status) => {
        let arrowStyle = {width: '24px', height: '24px', color: '#fff'};
        return (
            <div className={'collapsible-header'} style={{
                paddingRight: '70px',
                cursor: 'pointer'
            }}>
                <p className={'icons-category'}>{parseMapCaretChars(name)}</p>
                <hr className={'online-menu-category-line'}/>
                {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
            </div>
        );
    }

    const renderOwnBrandSection = () => {
        if (!isUserCaretaker(user) || !isUserAdmin(user)) {
            return <></>
        }
        return <div className={'happy-hour-container'}>
            <p className={'p-title-small'}>Lastna znamka</p>
            {renderIsOwnBrandCheckbox()}
        </div>
    }

    const renderIsOwnBrandCheckbox = () => {
        return (
            <div className={'checkbox-container'} style={{marginBottom: '12px', marginTop: 0}}>
                <label>
                    <input type="checkbox"
                           checked={isOwnBrand}
                           onChange={() => {
                               if (selectedCategory) {
                                   selectedCategory.own_brand = !isOwnBrand;
                                   updateCategoryDispatch(selectedCategory);
                               }
                               setIsOwnBrand(!isOwnBrand)
                           }}
                    />
                    Kategorija je del lastne znamke
                </label>
            </div>
        );
    };

    const renderIcon = (icon, i) => {
        let arrowStyle = {width: '24px', height: '24px', color: '#fff'};
        let action = () => {
            let j = selectedIconIdx === i ? null : i;
            setSelectedIconIdx(j);
            selectedCategory.icon = j;
            updateCategoryDispatch(selectedCategory);
        };
        let className = `icon-category-container ${selectedIconIdx === i ? 'selected' : ''}`
        return (
            <div onClick={action}
                 className={className}
                 style={{alignSelf: 'center'}}>
                <img src={icon} alt="icon category" width={'30px'}/>
            </div>
        );
    }

    const renderIcons = (icons) => {
        return (
            <Collapsible className={'collapsible-menu-listing icon-categories'} transitionTime={400}
                         onClosing={() => onActionCollapse(0, false)}
                         onOpening={() => onActionCollapse(0, true)}
                         open={areIconsOpen}
                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                         trigger={displayCollapsibleArrow('IKONE', false)}
                         triggerWhenOpen={displayCollapsibleArrow('IKONE', true)}>
                <div className={'category-icons-container'}>
                    <div className={'category-icons'}>
                        {icons.map((icon, i) => (
                            renderIcon(icon, i)
                        ))}
                    </div>
                </div>
            </Collapsible>
        );
    };


    let inputStyle = {marginBottom: '48px'};
    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div ref={ref}><Page relative className={'main'} style={{height: `${mainHeight}px`}}
                                      onToggleSidebar={(shown) => {
                                          if (shown) {
                                              dispatch(setSidebarWidth(SIDEBAR_WIDTH))
                                          } else {
                                              dispatch(setSidebarWidth(0))
                                          }
                                      }}>
            <div className={'body'} style={{marginTop: '220px', paddingLeft: `${sidebarWidth + 50}px`}}>
                <Header left={`${sidebarWidth + 50}px`} title={`PREGLED KATEGORIJ / ${name}`}/>
                <div className={'container-column-2'}>
                    <div className={'column-2'}>
                        <div
                            className={'country-label-container'}
                            style={{alignSelf: 'self-end', marginBottom: '-20px'}}>
                            <img src={countrySI} alt="SI" width={'25px'}/>
                        </div>


                        <Input white shadow loadCategory name onChange={setName} placeholder={'Slovenski naziv kategorije'}
                               label={'Slovenski naziv kategorije'}
                               style={inputStyle}
                               onBlur={(e) => {
                                   console.log('Triggered because this input lost focus', e.target.value);
                                   if (selectedCategory && name) {
                                       selectedCategory.name = mapCaretChars(name);
                                       updateCategoryDispatch(selectedCategory);
                                   }
                               }}
                        />
                        <div
                            className={'country-label-container'}
                            style={{alignSelf: 'self-end', marginBottom: '-20px'}}>
                            <img src={countryEN} alt="EN" width={'25px'}/>
                        </div>
                        <Input white shadow loadCategory engName onChange={setNameEn}
                               placeholder={'Angleški naziv kategorije'}
                               label={'Angleški naziv kategorije'}
                               style={inputStyle}
                               onBlur={(e) => {
                                   console.log('Triggered because this input lost focus', e.target.value);
                                   if (selectedCategory && nameEn) {
                                       selectedCategory.eng_name = mapCaretChars(nameEn);
                                       updateCategoryDispatch(selectedCategory);
                                   }
                               }}
                        />
                        <div
                            className={'country-label-container'}
                            style={{alignSelf: 'self-end', marginBottom: '-20px'}}>
                            <img src={countryDE} alt="DE" width={'25px'}/>
                        </div>
                        <Input white shadow loadCategory deName onChange={setNameDe} placeholder={'Nemški naziv kategorije'}
                               label={'Nemški naziv kategorije'}
                               style={inputStyle}
                               onBlur={(e) => {
                                   console.log('Triggered because this input lost focus', e.target.value);
                                   if (selectedCategory && nameDe) {
                                       selectedCategory.de_name = mapCaretChars(nameDe);
                                       updateCategoryDispatch(selectedCategory);
                                   }
                               }}
                        />
                        <div
                            className={'country-label-container'}
                            style={{alignSelf: 'self-end', marginBottom: '-20px'}}>
                            <img src={countryIT} alt="IT" width={'25px'}/>
                        </div>
                        <Input white shadow loadCategory itName onChange={setNameIt}
                               placeholder={'Italijanski naziv kategorije'}
                               label={'Italijanski naziv kategorije'}
                               style={inputStyle}
                               onBlur={(e) => {
                                   console.log('Triggered because this input lost focus', e.target.value);
                                   if (selectedCategory && nameIt) {
                                       selectedCategory.it_name = mapCaretChars(nameIt);
                                       updateCategoryDispatch(selectedCategory);
                                   }
                               }}
                        />
                        <div
                            className={'country-label-container'}
                            style={{alignSelf: 'self-end', marginBottom: '-20px'}}>
                            <img src={countryHR} alt="HR" width={'25px'}/>
                        </div>
                        <Input white shadow loadCategory hrName onChange={setNameHr}
                               placeholder={'Hrvaški naziv kategorije'}
                               label={'Hrvaški naziv kategorije'}
                               style={inputStyle}
                               onBlur={(e) => {
                                   console.log('Triggered because this input lost focus', e.target.value);
                                   if (selectedCategory && nameHr) {
                                       selectedCategory.hr_name = mapCaretChars(nameHr);
                                       updateCategoryDispatch(selectedCategory);
                                   }
                               }}
                        />

                    </div>
                    <div className={'column-2'}>
                        {renderIcons(ICONS_BlACK)}
                        {renderOwnBrandSection()}
                    </div>

                </div>
            </div>
        </Page>
        </div>
}

export default Category;