import React, {useEffect, useReducer, useState} from "react";
import Masonry from "react-responsive-masonry"

import '../css/CreateMenuAddProducts.css';

import {useDispatch, useSelector} from "react-redux";

import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import Footer from "../components/js/Footer";
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import Button from "../components/js/Button";
import Input from "../components/js/Input";
import {
    loadProducts,
    loadProductsByCategory,
    setCreateMenuActive,
    setIsCreatingMenu,
    setProductsByCategory, setSidebarWidth
} from "../store/actions/actions";
import {PAGES} from "../App";
import Modal from "../components/js/Modal";
import {
    displayDecimalValue, getUniqueLocalId,
    isMenuValidPriceCheck,
    isSessionValid, isUserAdmin, isUserCaretaker, mapCaretChars,
    mapProductsFromStoreToDefaultCategories,
    parseMapCaretChars, parsePostDecimalValue, saveToLocalStorage, validatePriceUpdateAction
} from "../helpers/helpers";
import {Redirect} from "react-router";
import Dropdown from "../components/js/Dropdown";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import colors from "../constants/colors";

const TIMEOUT_INTERVAL = 1;
const SEARCH_BY_FIELDS = [
    'name',
    'external_id'
];


function CreateMenuAddProducts(props) {
    const dispatch = useDispatch();

    let products = useSelector(state => state.reducer.products);
    let user = useSelector(state => state.reducer.user);
    let token = useSelector(state => state.reducer.userToken);
    const createMenuActive = useSelector(state => state.reducer.createMenuActive);
    let activeBar = useSelector(state => state.reducer.activeBar);
    let productsByCategory = useSelector(state => state.reducer.productsByCategory);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    const [productsPoolStore, setProductsPoolStore] = useState(products ? mapProductsFromStoreToDefaultCategories(products, createMenuActive.beer_cider_menu) : []);
    const [mappedProductsByCat, setMappedProductsByCat] = useState(products ? mapProductsFromStoreToDefaultCategories(products, createMenuActive.beer_cider_menu) : []);
    const [productsPool, setProductsPool] = useState(products ? mapProductsFromStoreToDefaultCategories(products, createMenuActive.beer_cider_menu) : []);
    const [categoriesFinal, setCategoriesFinal] = useState(createMenuActive.categories);
    const [hiddenCategories, setHiddenCategories] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [saveTimeout, setSaveTimeout] = useState(null);

    const [draggedItem, setDraggedItem] = useState(null);
    const [draggedItemCategory, setDraggedItemCategory] = useState(null);

    const [modal, setModal] = useState(false);

    const [searchProductsByCategory, setSearchProductsByCategory] = useState(null);

    const [inputInvalid, setInputInvalid] = useState(false);

    const [tabletMode, setTabletMode] = useState(true);

    const [change, setChange] = useState(0);

    const [inputTimeout, setInputTimeout] = useState(null);

    const [nameInput, setNameInput] = useState(null);

    const [productStatuses, setProductStatus] = useState([]);
    const [currentStatusCategoryIndex, setCurrentStatusCategoryIndex] = useState(null);
    const [currentStatusProductIndex, setCurrentStatusProductIndex] = useState(null);
    const [currentProduct, setCurrentProduct] = useState(null);

    useEffect(() => {
        if (!!inputTimeout) {
            clearTimeout(inputTimeout);
            setInputTimeout(null);
        }
        setInputTimeout(setTimeout(() => {
            if (!products) {
                return;
            }
            if (!nameInput || nameInput === '') {
                setProductsPoolState(mapProductsFromStoreToDefaultCategories(products, createMenuActive.beer_cider_menu))
            } else {
                window.searchingByName = true;
                let filteredProducts = [];
                for (const product of products) {
                    for (const fieldName of SEARCH_BY_FIELDS) {
                        if (product.hasOwnProperty(fieldName) && !!product[fieldName] && parseMapCaretChars(product[fieldName]).toLowerCase().includes(nameInput.toLowerCase())) {
                            filteredProducts.push(product);
                            break;
                        }
                    }
                }
                setProductsPoolState(mapProductsFromStoreToDefaultCategories(filteredProducts, createMenuActive.beer_cider_menu))
                dispatch(setProductsByCategory(searchProductsByCategory));
            }
        }, TIMEOUT_INTERVAL));
    }, [nameInput]);

    /*
    useEffect(() => {
        let done = false;
        const asyncEffect = async () => {
            if (activeBar && searchByCategory) {
                await dispatch(loadProductsByCategory(activeBar.bar_id, searchByCategory, () => {
                    console.log("Products by category successfully loaded..");
                    done = true;
                }, () => {
                    console.warn("Error loading products by category..");
                }));
            }
        }
        asyncEffect().then(() => console.log(done && "done loading data."));
    }, [activeBar, searchByCategory]);
     */


    useEffect(() => {
        if (searchProductsByCategory) {
            dispatch(setProductsByCategory(searchProductsByCategory));

            let mappedProducts = mapProductsFromStoreToDefaultCategories(searchProductsByCategory, createMenuActive.beer_cider_menu);
            console.log("Product pool by category:", mappedProducts);
            setProductsPoolState(mappedProducts)
        }
    }, [searchProductsByCategory]);


    useEffect(() => {
        if (products) {
            let mappedProducts = mapProductsFromStoreToDefaultCategories(products, createMenuActive.beer_cider_menu);
            console.log("Product pool:", mappedProducts);
            setProductsPoolState(mappedProducts)
            setProductsPoolStore(mappedProducts);
        }
    }, [products]);

    useEffect(() => {
        dispatch(setIsCreatingMenu(3));
    }, []);

    useEffect(() => {
        let done = false;
        const asyncEffect = async () => {
            if (activeBar && createMenuActive) {
                await dispatch(loadProducts(activeBar.bar_id, user, false,(resData) => {
                    console.log("Products successfully loaded..");
                    done = true;

                    let mappedProducts = mapProductsFromStoreToDefaultCategories(resData, createMenuActive.beer_cider_menu);
                    console.log("Product pool:", mappedProducts);
                    setProductsPoolState(mappedProducts)
                    setProductsPoolStore(mappedProducts);


                }, () => {
                    console.warn("Error loading products..")
                }));
            }
        }
        asyncEffect().then(() => console.log(done && "done loading data."));
    }, [activeBar, createMenuActive]);

    useEffect(() => {
        console.log("SELECTED PRODUCTS:", selectedProducts);
    }, [selectedProducts]);
    useEffect(() => {
        console.log("PRODUCTS POOL", productsPool);
    }, [productsPool]);


    useEffect(() => {
        setLocalCreateMenuActive();
        saveToLocalStorage(createMenuActive)

        setChange(change + 1);

    }, [categoriesFinal]);

    useEffect(() => {
        console.log("update product status")
        //updateProductStatusOnMenu(currentStatusCategoryIndex, currentStatusProductIndex, currentProduct, mapCaretChars(value), categoriesFinal)
    }, [productStatuses]);

    const setProductsPoolState = (prods) => {
        setProductsPool(prods);
        setMappedProductsByCat(prods);
    };

    const setLocalCreateMenuActive = () => {
        createMenuActive.categories = categoriesFinal;
        dispatch(setCreateMenuActive(createMenuActive));
    }

    const onConfirmCurrentChanges = () => {
        const isUserInputValid = categoriesFinal && categoriesFinal.length > 0
        for(let i = 0; i <categoriesFinal.length; i++) {
           if (categoriesFinal[i].products.length <= 0) {
               alert("Za nadaljevanje najprej napolnite vse željene kategorije z izdelki..");
               return false;
           }
        }
        if (!isUserInputValid) {
            alert("Za nadaljevanje najprej izberite željene kategorije izdelkov..");
            return false;
        }
        const isMenuValid = isMenuValidPriceCheck(categoriesFinal);
        if (!isMenuValid) {
            setInputInvalid(true);
            alert("Za nadaljevanje najprej vnesite željene cene vsem izdelkom..");
            return false;
        }

        setLocalCreateMenuActive()
        setInputInvalid(false);
        return true;
    };


    const handleDragStart = (isReorderingProducts, product, category) => {
        console.log("drag started..");

        if (isReorderingProducts) {
            console.log(product);
            setDraggedItem(product);
            setDraggedItemCategory(category);
        }
    };

    const handleDragEnd = () => {
        console.log("drag ended..");
    };

    const handleDragLeave = event => {
        event.stopPropagation()
        event.preventDefault()

        //console.log("drag left drop zone!");
    };
    const handleDragOver = event => {
        event.stopPropagation()
        event.preventDefault()

        //console.log("drag over drop zone!");
    };
    const handleDragEnter = event => {
        event.stopPropagation()
        event.preventDefault()

        //console.log("drag entered drop zone!");
    };

    const handleDropCategorySort = (event, idxDropZone, idxCatDropZone) => {
        event?.stopPropagation()
        event?.preventDefault()
        console.log("drag dropped on name sort zone!");

        if (selectedProducts.length !== 0) {
            console.warn("Can not sort items on menu when items on right are selected! Deselect all the products on the left by double clicking on one of them..", selectedProducts);
            setDraggedItem(null);
            return;
        }
        if (!draggedItem) {
            console.warn("Can not add non-selected product to the menu. Please select it first and try again.");
            setDraggedItem(null);
            return;
        }

        let idx = -1;
        for (let i = 0; i < categoriesFinal.length; i++) {
            if (categoriesFinal[i].category.name === draggedItemCategory.name) {
                idx = i;
                break;
            }
        }
        let idxProduct = -1;
        for (let j = 0; j < categoriesFinal[idx].products.length; j++) {
            if (categoriesFinal[idx].products[j].product_id === draggedItem.product_id) {
                console.log(categoriesFinal[idx].products[j].product_id);
                idxProduct = j;
                break;
            }
        }
        if (idx === idxCatDropZone && idxProduct === idxDropZone) {
            console.log("Dropping on itself..")
            setDraggedItem(null);
            return;
        }

        console.log("CATEGORIES FINAL", categoriesFinal);

        let updated = JSON.parse(JSON.stringify([...categoriesFinal]));

        console.log(idx);
        console.log(idxProduct);
        updated[idx].products.splice(idxProduct, 1);

        updated[idxCatDropZone].products.splice(idxDropZone, 0, draggedItem);

        console.log("UPDATED", updated);
        setArrayAction(categoriesFinal, [...updated]);

        setDraggedItem(null);
    };

    const handleDropOnMenu = (event, category, idxCatDropZone) => {
        event?.stopPropagation()
        event?.preventDefault()
        console.log("drag dropped over name on menu or product to sort!");

        if (selectedProducts && selectedProducts.length !== 0) {
            let finalUpdated = JSON.parse(JSON.stringify([...categoriesFinal]));
            for (let i = 0; i < finalUpdated.length; i++) {
                if (finalUpdated[i].category.name === category.name) {
                    for (let j = 0; j < selectedProducts.length; j++) {
                        finalUpdated[i].products.push(selectedProducts[j]);
                    }
                    break;
                }
            }
            setArrayAction(categoriesFinal, [...finalUpdated])
            removeFromProductsPool(null, null, finalUpdated);
            setArrayAction(selectedProducts, []);
            // setProductsPoolState(productsPoolStore);
        } else {
            handleDropCategorySort(event, 0, idxCatDropZone);
        }
    };

    const setArrayAction = (array, content) => {
        switch (array) {
            case productsPool:
                setProductsPool(content);
                break;
            case selectedProducts:
                setSelectedProducts(content);
                break;
            case categoriesFinal:
                setCategoriesFinal(content);
                break;
        }
    };

    const clearSelection = (array) => {
        setArrayAction(array, []);
    };

    const isCategoryFinalEmpty = (c) => {
        return categoriesFinal[c] && categoriesFinal[c].products.length <= 0;
    };

    const isProductSelected = (selection, array) => {
        if (!array) {
            return false;
        }
        for (let p = 0; p < array.length; p++) {
            if (array[p].product_id === selection.product_id) {
                return true;
            }
        }
        return false;
    };

    const selectProduct = (obj, array) => {
        if (!isProductSelected(obj, array)) {
            setArrayAction(array, [...array, obj])
            console.log("PRODUCT", obj.product_id, 'selected');
        } else {
            console.log("PRODUCT", obj.product_id, 'remove');
            removeProduct(obj, array);
        }
    };

    const removeProduct = (obj, array) => {
        let filtered;
        if (array === selectedProducts) {
            filtered = array.filter(product => product.product_id !== obj.product_id);
        } else {
            filtered = array.map((category) => {
                return {
                    ...category,
                    products: category.products.filter((product) => product.product_id !== obj.product_id)
                }
            })
        }
        console.log(array, filtered);
        setArrayAction(array, filtered)

        console.log("FILTERED", filtered);
        return [...filtered];
    };

    const handleDropOnProductsPool = event => {
        event?.stopPropagation()
        event?.preventDefault()
        console.log("drag dropped on name pool zone!");

        if (!draggedItem) {
            return;
        }

        let updatedFinal = removeFromCategoriesFinal(draggedItemCategory, draggedItem);
        removeFromProductsPool(draggedItemCategory, draggedItem, updatedFinal);
    };

    const removeFromCategoriesFinal = (category, obj) => {
        let finalUpdated = [];

        console.log('CAT/OBJ', category, obj);
        finalUpdated = removeProduct(obj, categoriesFinal);

        console.log("UPDATED ", finalUpdated);
        return finalUpdated;
    };

    const removeFromProductsPool = (category, obj, finalUpdated) => {
        let filtered = JSON.parse(JSON.stringify([...mappedProductsByCat]));
        for (let i = 0; i < finalUpdated.length; i++) {
            for (let j = 0; j < finalUpdated[i].products.length; j++) {
                filtered = filtered.map((category) => {
                    return {
                        ...category,
                        products: category.products.filter((product) => product.product_id !== finalUpdated[i].products[j].product_id)
                    }
                })
            }
        }
        console.log("FILTERED POOL", filtered);
        setArrayAction(productsPool, [...filtered]);
    };

    const updateProductPriceOnMenu = (categoryIndex, productIndex, product, price, priceIndex, categories) => {
        if (saveTimeout) {
            clearTimeout(saveTimeout)
        }
        setSaveTimeout(setTimeout(() => {
            let menu = [...categories]
            switch (priceIndex) {
                case 0:
                    menu[categoryIndex].products[productIndex].initial_price = price;
                    break;
                case 1:
                    menu[categoryIndex].products[productIndex].initial_price_2 = price;
                    break;
                case 2:
                    menu[categoryIndex].products[productIndex].initial_price_3 = price;
                    break;
            }
            setCategoriesFinal(menu);
            console.log("MENU UPDATED:", menu);
        }, 100))
    }

    const updateProductStatusOnMenu = (categoryIndex, productIndex, product, status, categories) => {
        if (saveTimeout) {
            clearTimeout(saveTimeout)
        }
        setSaveTimeout(setTimeout(() => {
            console.log("NNO", categories, categoryIndex, productIndex, product, status)
            let menu = [...categories]
            menu[categoryIndex].products[productIndex].status = status;
            setCategoriesFinal(menu);
            console.log("MENU PRODUCT/STATUS UPDATED:", menu);
        }, 100))

    }

    const isPriceSet = (categoryIndex, productIndex, priceIndex, menu) => {
        switch (priceIndex) {
            case 0:
                return menu[categoryIndex].products[productIndex].initial_price;
            case 1:
                return menu[categoryIndex].products[productIndex].initial_price_2;
            case 2:
                return menu[categoryIndex].products[productIndex].initial_price_3;
        }
    }

    const renderAllProducts = () => {
        const categoryItemName = 'create-menu-product-pool-item';
        if (!productsPool) {
            return null;
        }

        const getMeasureUnit = (item) => {
            if (!item.beer_cider_type) {
                return item.measure_unit;
            }
            let m = '';
            if (item.measure_unit) {
                m += item.measure_unit;
            }
            if (item.measure_unit_2) {
                m += ', ';
                m += item.measure_unit_2;
            }
            if (item.measure_unit_3) {
                m += ', ';
                m += item.measure_unit_3;
            }
            return m;
        }
        return (
            productsPool.map((category, c) => (
                category.products.map((product, p) => (
                    <div key={`${p}_${product.name}`}
                         className={isProductSelected(product, selectedProducts) ? categoryItemName + ' selected' : categoryItemName}
                         onClick={() => selectProduct(product, selectedProducts)}
                         onDoubleClick={() => clearSelection(selectedProducts)}
                         draggable="true"
                         onDragStart={() => handleDragStart(false)}
                         onDragEnd={handleDragEnd}>
                        <div className={'create-menu-product-pool-item-left'}>
                            <p className={'p-title-product'}>{parseMapCaretChars(product.name)}</p>
                            <div className={'create-menu-product-pool-item-right'}>
                                <p className={'p-title-desc'}>{getMeasureUnit(product)}</p>
                                <p className={'p-title-desc'} style={{
                                    minWidth: '100px',
                                    textAlign: 'right'
                                }}>{parseMapCaretChars(product.packaging)}</p>
                            </div>

                        </div>

                        <div className={'container-icon-selected products-list-selection'}>
                            <AiIcons.AiFillCheckCircle/>
                        </div>
                    </div>
                ))
            ))
        );
    };
    const toggleCategory = (categoryName) => {
        let hiddenClone = [...hiddenCategories]
        if (hiddenClone.includes(categoryName)) {
            const index = hiddenClone.indexOf(categoryName);
            if (index > -1) {
                hiddenClone.splice(index, 1);
            }
        } else {
            hiddenClone.push(categoryName)
        }
        setHiddenCategories(hiddenClone)
    }

    const correctProductInputDisplayProperties = () => {
        for (let c = 0; c < categoriesFinal.length; c++) {
            for (let p = 0; p < categoriesFinal[c].products.length; p++) {
                let product = document.getElementsByClassName(`p_${c}_${p}`)[0];
                let productData = categoriesFinal[c].products[p];
                //console.log(product);
                //console.log("PARSE PRICES")
                try {
                    let query = product.querySelectorAll('.input-default');
                    let queryProductStatus = product.querySelectorAll('.select-default__single-value')
                    let queryProductStatusPlaceholder = product.querySelectorAll('.select-default__placeholder')

                    let statusPlaceholder = 'Status';
                    if (product && queryProductStatus[0]) {
                        let status = productData.status ? productData.status : statusPlaceholder;
                        queryProductStatus[0].innerHTML = mapCaretChars(status)
                        queryProductStatus[0].text = mapCaretChars(status)
                        queryProductStatus[0].value = mapCaretChars(status)
                    }
                    if (product && queryProductStatusPlaceholder[0]) {
                        let status = productData.status ? productData.status : statusPlaceholder;
                        queryProductStatusPlaceholder[0].innerHTML = mapCaretChars(status)
                        queryProductStatusPlaceholder[0].text = mapCaretChars(status)
                        queryProductStatusPlaceholder[0].value = mapCaretChars(status)
                    }

                    //console.log(query, query[2])
                    if (product && query[0]) {
                        query[0].placeholder = displayDecimalValue(categoriesFinal[c].products[p].initial_price);
                        query[0].value = displayDecimalValue(categoriesFinal[c].products[p].initial_price);
                    }
                    if (product && query[1]) {
                        query[1].placeholder = displayDecimalValue(categoriesFinal[c].products[p].initial_price_2);
                        query[1].value = displayDecimalValue(categoriesFinal[c].products[p].initial_price_2);
                    }
                    if (product && query[2]) {
                        query[2].placeholder = displayDecimalValue(categoriesFinal[c].products[p].initial_price_3);
                        query[2].value = displayDecimalValue(categoriesFinal[c].products[p].initial_price_3);

                    }
                } catch {
                }
            }
        }
    }

    useEffect(() => {
        if (categoriesFinal && categoriesFinal.length > 0) {
            setTimeout(correctProductInputDisplayProperties, 0);
        }
    });

    let categoryClassName = 'create-menu-category-item product';

    const renderMenu = () => {
        if (!categoriesFinal) {
            return <></>;
        }
        return (
            categoriesFinal.map((category, c) => (
                <div key={`${c}_${category}`}

                     className={isCategoryFinalEmpty(c) ? categoryClassName : categoryClassName + ' active'}
                     draggable="false"
                     onClick={(event) => {
                         if (tabletMode) {
                             if (event.target === event.currentTarget) {
                                 event.stopPropagation()
                                 console.log("stopped propagation");
                             }
                         }
                     }}
                     onDragOver={handleDragOver}
                     onDragEnter={handleDragEnter}
                     onDragLeave={handleDragLeave}
                     onDrop={(event) => {
                         handleDropOnMenu(event, category.category, c)
                     }}
                >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        <p className={'p-title-product category'}
                           style={{
                               margin: '20px 0',
                               padding: 0,
                               paddingLeft: '35px',
                           }}
                           onClick={() => {
                               toggleCategory(category.category.name)
                           }}
                        >{parseMapCaretChars(category.category.name)}
                        </p>

                        {selectedProducts?.length > 0 && tabletMode &&
                        <div onClick={(event) => {
                            if (tabletMode) {
                                if (!draggedItem) {
                                    handleDropOnMenu(event, category.category, c)
                                    setDraggedItem(null);
                                    setDraggedItemCategory(null);
                                }
                            }
                        }}>
                            <BsIcons.BsPlusCircle
                                style={{
                                    position: 'relative',
                                    width: "25px",
                                    height: '25px',
                                    cursor: 'pointer',
                                    marginRight: '20px',
                                    fill: colors.primary
                                }}/>
                        </div>
                        }
                        {draggedItem && tabletMode &&
                        <div onClick={(event) => {
                            if (tabletMode) {
                                if (draggedItem) {
                                    handleDropCategorySort(event, 0, c)
                                    setDraggedItem(null);
                                    setDraggedItemCategory(null);
                                }
                            }
                        }}>
                            <BsIcons.BsPlusCircle
                                style={{
                                    position: 'relative',
                                    width: "25px",
                                    height: '25px',
                                    cursor: 'pointer',
                                    marginRight: '20px',
                                    fill: colors.primary
                                }}/>
                        </div>
                        }
                    </div>

                    <div className={'create-menu-category-item-content'}
                         style={{display: hiddenCategories.includes(category.category.name) ? 'none' : 'block'}}>
                        {category.products.map((product, p) => (

                            <div key={`${p}_${product}`}
                                 style={{
                                     border: draggedItem?.product_id === product.product_id ? '3px solid #D30263' : 'none',
                                     borderRadius: '20px',
                                     display: !product?.combo_offer ? 'flex' : 'none',
                                     pointerEvents: product?.own_brand && !isUserCaretaker(user) ? 'none' : 'all',
                                     cursor: product?.own_brand && !isUserCaretaker(user) ? 'default' : 'pointer',
                                     backgroundColor: product?.own_brand && !isUserCaretaker(user) ? '#e3e3e3' : 'white'

                                 }}
                                 className={`create-menu-category-item-product ${product.beer_cider_type ? 'beer-cider' : ''}`}
                                 draggable={!tabletMode}
                                 onClick={(event) => {
                                     if (tabletMode) {
                                         if (event.target === event.currentTarget) {
                                             event.stopPropagation()
                                             console.log("stopped propagation");

                                             if (!draggedItem) {
                                                 handleDragStart(true, product, category.category)
                                             } else {
                                                 handleDropCategorySort(event, p, c)
                                                 setDraggedItem(null);
                                                 setDraggedItemCategory(null);
                                             }
                                         }

                                     }
                                 }}
                                 onDragStart={() => handleDragStart(true, product, category.category)}
                                 onDragEnd={handleDragEnd}
                                 onDrop={(event) => handleDropCategorySort(event, p, c)}>

                                <div className={'create-menu-category-item-product-left'}>
                                    <p className={'p-title-product black'}>{parseMapCaretChars(product.name)}</p>
                                    <div className={'create-menu-category-item-product-left-desc'}>
                                        <p className={'p-desc-product'}>{parseMapCaretChars(product.packaging)}</p>
                                        {
                                            !product.beer_cider_type ?
                                                <p className={'p-desc-product'}>{product.measure_unit}</p>
                                                : <></>
                                        }
                                    </div>
                                </div>
                                <div className={'create-menu-category-item-product-right'}>
                                    <div className={`p_${c}_${p}`}>
                                        {
                                            product.beer_cider_type ?
                                                <p className={'p-desc-product'}>{product.measure_unit}</p>
                                                : <></>
                                        }
                                        <Dropdown loadProductStatuses onChange={(value) => {
                                            console.log('Triggered because status changed', value);
                                            setProductStatus(value);
                                            setCurrentStatusProductIndex(p);
                                            setCurrentStatusCategoryIndex(c);
                                            setCurrentProduct(product);

                                            updateProductStatusOnMenu(c, p, product, mapCaretChars(value), categoriesFinal)
                                        }}
                                                  createMenu
                                                  placeholder={'Status'}/>
                                        <Input loadActiveMenuProduct product={product} p={Number(p)} c={Number(c)}
                                               onChange={(value) => {
                                                   console.log('Triggered because price changed', value);
                                                   // validatePriceUpdateAction(value, () => {
                                                   updateProductPriceOnMenu(c, p, product, parsePostDecimalValue(value), 0, categoriesFinal)
                                                   // }, true)
                                               }}
                                               onSubmit={() => {
                                               }}
                                            //placeholder={!product.initial_price || product.initial_price === 0 ? 'Vpiši ceno (v EUR)' : displayDecimalValue(categoriesFinal[c].products[p].initial_price)}
                                               style={{
                                                   pointerEvents: 'all',
                                                   border: inputInvalid && !isPriceSet(c, p, 0, categoriesFinal) ? "1px solid red" : "none"
                                               }}
                                               onBlur={(e) => {
                                                   // console.log('Triggered because this input lost focus', e.target.value);
                                                   validatePriceUpdateAction(e.target.value, () => {
                                                       //     updateProductPriceOnMenu(c, p, product, parsePostDecimalValue(e.target.value), categoriesFinal)
                                                   }, false)
                                               }}
                                        />
                                        {
                                            product.measure_unit_2 && product.measure_unit_2 !== '' ?
                                                <>
                                                    <p className={'p-desc-product'}>{product.measure_unit_2}</p>
                                                    <Input loadActiveMenuProduct price2 product={product} p={Number(p)}
                                                           c={Number(c)}
                                                           onChange={(value) => {
                                                               console.log('Triggered because price changed', value);
                                                               // validatePriceUpdateAction(value, () => {
                                                               updateProductPriceOnMenu(c, p, product, parsePostDecimalValue(value), 1, categoriesFinal)
                                                               // }, true)
                                                           }}
                                                           onSubmit={() => {
                                                           }}
                                                        //placeholder={!product.initial_price || product.initial_price === 0 ? 'Vpiši ceno (v EUR)' : displayDecimalValue(categoriesFinal[c].products[p].initial_price)}
                                                           style={{border: inputInvalid && !isPriceSet(c, p, 1, categoriesFinal) ? "1px solid red" : "none"}}
                                                           onBlur={(e) => {
                                                               // console.log('Triggered because this input lost focus', e.target.value);
                                                               validatePriceUpdateAction(e.target.value, () => {
                                                                   //     updateProductPriceOnMenu(c, p, product, parsePostDecimalValue(e.target.value), categoriesFinal)
                                                               }, false)
                                                           }}
                                                    />
                                                </>
                                                : <></>
                                        }
                                        {
                                            product.measure_unit_3 && product.measure_unit_3 !== '' ?
                                                <>
                                                    <p className={'p-desc-product'}>{product.measure_unit_3}</p>
                                                    <Input loadActiveMenuProduct price3 product={product} p={Number(p)}
                                                           c={Number(c)}
                                                           onChange={(value) => {
                                                               console.log('Triggered because price changed', value);
                                                               // validatePriceUpdateAction(value, () => {
                                                               updateProductPriceOnMenu(c, p, product, parsePostDecimalValue(value), 2, categoriesFinal)
                                                               // }, true)
                                                           }}
                                                           onSubmit={() => {
                                                           }}
                                                        //placeholder={!product.initial_price || product.initial_price === 0 ? 'Vpiši ceno (v EUR)' : displayDecimalValue(categoriesFinal[c].products[p].initial_price)}
                                                           style={{border: inputInvalid && !isPriceSet(c, p, 2, categoriesFinal) ? "1px solid red" : "none"}}
                                                           onBlur={(e) => {
                                                               // console.log('Triggered because this input lost focus', e.target.value);
                                                               validatePriceUpdateAction(e.target.value, () => {
                                                                   //     updateProductPriceOnMenu(c, p, product, parsePostDecimalValue(e.target.value), categoriesFinal)
                                                               }, false)
                                                           }}
                                                    />
                                                </>
                                                : <></>
                                        }
                                    </div>

                                    <div className={'container-icon-selected'}>
                                        {tabletMode ?
                                            !draggedItem ? <div onClick={() => {
                                                    if (tabletMode) {
                                                        removeFromCategoriesFinal(category, product);
                                                        setDraggedItem(null);
                                                        setDraggedItemCategory(null);
                                                        if (createMenuActive.beer_cider_menu) {
                                                            setProductsPoolState(productsPoolStore)
                                                        }
                                                    }
                                                }}> {product?.own_brand && !isUserCaretaker(user) ? <BsIcons.BsLock
                                                    style={{cursor: 'pointer', width: "25px", height: '25px'}}/> :
                                                <BsIcons.BsTrash
                                                    style={{cursor: 'pointer', width: "25px", height: '25px'}}/>}
                                                </div> :
                                                <BsIcons.BsArrowsMove style={{width: "25px", height: '25px'}}/> :
                                            <BsIcons.BsArrowsMove style={{width: "25px", height: '25px'}}/>}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))
        );
    };

    const renderModal = () => {
        return (
            modal && <Modal product actionClose={() => {
                console.log('display modal, false');
                setModal(false)
            }}/>
        );
    };

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page relative editMenuAddProducts className={'main'} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            {/*<Sidebar/>*/}
            {/*<div className={'page relative edit-menu-add-products'}>*/}
            <Header left={`${sidebarWidth + 50}px`} title={'IZBERI ARTIKLE ZA CENIK'} step={'3. KORAK'}/>
            <div className={'body'} style={{paddingLeft: `${sidebarWidth + 50}px`}}>
                <div onClick={() => setTabletMode(!tabletMode)} className={'container-icon-selected'}
                     style={{paddingRight: 40}}>
                    <BsIcons.BsTablet size={20} fill={tabletMode ? colors.primary : ''} style={{cursor: 'pointer'}}/>
                </div>
                <div className={'container-column-2'}>
                    <div className={'column-2 selected-product-drop-zone'}
                         onDragOver={handleDragOver} onDragEnter={handleDragEnter}
                         onDragLeave={handleDragLeave} onDrop={handleDropOnProductsPool}>
                        <p className={'p-title-small'}
                           style={{paddingBottom: '28px'}}>{'S klikom izberite med prednastavljenimi artikli, ki bi jih radi umestili na cenik.'}</p>
                        <div className={'search-row'}>
                            <Button className={'button-add-category'} text={'USTVARI UNIKATEN ARTIKEL'}
                                    action={() => {
                                        setModal(true);
                                    }} style={{
                                width: '50%',
                                marginBottom: '12px',
                                display: !createMenuActive.beer_cider_menu ? 'flex' : 'none'
                            }}/>
                        </div>
                        <Masonry columnsCount={1} className={'masonry-create-menu-categories-pool'} gutter="8px">
                            <div className={'add-products-masonry-header'}>
                                <Input onSubmit={() => {
                                }}
                                       onChange={setNameInput}
                                       placeholder={'Išči po artiklu'}
                                       white
                                />
                                <Dropdown loadSearchProductsCategory label={''} onChange={setSearchProductsByCategory}
                                          placeholder={'Išči po kategoriji'}/>
                            </div>

                            {renderAllProducts()}
                        </Masonry>
                    </div>
                    <div className={'column-2 drop-zone-product-pool'}>
                        <p className={'p-title-small'}>{'Izbrane artikle potegnite v željeno kategorijo.'}</p>
                        {renderMenu()}
                    </div>
                </div>
            </div>
            <Footer back={'NAZAJ'}
                    left={`${sidebarWidth}px`}
                    urlBack={PAGES.CREATE_MENU_CHOOSE_CATEGORIES}
                    next={'POTRDI IN NADALJUJ'}
                    urlNext={isUserCaretaker(user) ? createMenuActive.beer_cider_menu ? PAGES.CREATE_MENU_PREVIEW : PAGES.CREATE_MENU_CHOOSE_COMBO_OFFER : PAGES.CREATE_MENU_PREVIEW}
                    onAction={() => {
                        return onConfirmCurrentChanges()
                    }}
                    progress={3}/>
            {/*</div>*/}
        </Page>
            {renderModal()}
        </div>

}

export default CreateMenuAddProducts;

