import React, {useState, useCallback, useEffect} from "react";

import '../components/css/Tables.css'
import "../css/HappyHour.css";

import {useDispatch, useSelector} from "react-redux";

import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import {ReactComponent as IconPrinter} from "../assets/svg/Icon feather-printer.svg";
import {ReactComponent as IconEdit} from "../assets/svg/Icon feather-edit.svg";
import {useHistory} from "react-router-dom";
import {PAGES} from "../App";
import {
    loadComboOffers,
    loadProducts,
    setActiveProduct, setCreateMenuActive, setSessionProducts, setSidebarWidth
} from "../store/actions/actions";
import {findItem, isSessionValid, parseBeerCiderLabel, parseMapCaretChars, parseUserRole} from "../helpers/helpers";
import {Redirect} from "react-router";
import Modal from "../components/js/Modal";
import {USER_ROLES} from "../constants/enums";
import Input from "../components/js/Input";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import Button from "../components/js/Button";

const TIMEOUT_INTERVAL = 1;
const SEARCH_BY_FIELDS = [
    'name',
    'external_id'
];

function ProductsList(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let user = useSelector(state => state.reducer.user);
    let allProducts = useSelector(state => state.reducer.products);
    let comboOffers = useSelector(state => state.reducer.comboOffers);

    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    let activeBar = useSelector(state => state.reducer.activeBar);

    const [modal, setModal] = useState(false);
    const [beerCiderModal, setBeerCiderModal] = useState(false);
    const [toggleFilter, setToggleFilter] = useState(!!window.toggle_beer_cider);

    const [inputTimeout, setInputTimeout] = useState(null);

    const [nameInput, setNameInput] = useState(null);

    const [products, setProducts] = useState(allProducts ? allProducts : []);

    const onToggleFilter = (state) => {
        setToggleFilter(state);
        window.toggle_beer_cider = state;
    }

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    const navigateAndLoadSelectedProduct = (product) => {
        dispatch(setActiveProduct(product));
        onNavigateTo(PAGES.EDIT_PRODUCT);
    };

    useEffect(() => {
        dispatch(setCreateMenuActive(null));
        setToggleFilter(!!window.toggle_beer_cider);
    }, []);

    useEffect(() => {
        if (!!inputTimeout) {
            clearTimeout(inputTimeout);
            setInputTimeout(null);
        }
        setInputTimeout(setTimeout(() => {
            if (!nameInput || nameInput === '') {
                setProducts(allProducts ? allProducts : []);
            } else {
                let filteredProducts = [];
                for (const product of allProducts) {
                    for (const fieldName of SEARCH_BY_FIELDS) {
                        if (product.hasOwnProperty(fieldName) && !!product[fieldName] && parseMapCaretChars(product[fieldName]).toLowerCase().includes(nameInput.toLowerCase())) {
                            filteredProducts.push(product);
                            break;
                        }
                    }
                }
                setProducts(filteredProducts);
            }
        }, TIMEOUT_INTERVAL));
    }, [nameInput]);

    useEffect(() => {
            let done = false;
            const asyncEffect = async () => {
                if (user) {
                    let barId = null;
                    if (parseUserRole(user.type) !== USER_ROLES.ADMIN) {
                        if (activeBar) {
                            barId = activeBar.bar_id;
                        }
                    }

                    await dispatch(loadProducts(barId, user, true,(products) => {
                        //setSessionProducts(JSON.stringify(products));
                        let p = [];
                        if (parseUserRole(user.type) === USER_ROLES.OWNER || parseUserRole(user.type) === USER_ROLES.BAR_REPRESENTATIVE) {
                            for (let i = 0; i < products.length; i++) {
                                if (!products[i].bar_id) {
                                    continue;
                                }
                                p.push(products[i]);
                            }
                            setProducts(p);
                        } else {
                            setProducts(products);
                        }

                        console.log("Products successfully loaded..", p);
                    }, () => {
                        console.warn("Error loading products..")
                    }));

                    /*
                    await dispatch(loadComboOffers(barId, () => {
                        console.log("Combo Offers successfully loaded..");
                    }, () => {
                        console.warn("Error loading combo offers..")
                    }));
*/
                }
            }
            asyncEffect().then(() => console.log(done && "done loading data."));
        }, [user, activeBar]
    );

    const renderProductTypeFilter = () => {
        return <div className={'button-selection-filter'}>
            {/*<Button className={'button-selection'} text={'PIVSKA KARTA IZDELKI'} selected={toggleFilter}
                     action={() => onToggleFilter(!toggleFilter)}/>*/}
            <Button className={'button-selection'} text={'VSI IZDELKI'} selected={!toggleFilter}
                    action={() => onToggleFilter(!toggleFilter)}/>
        </div>;
    }

    function Item(props) {
        // console.log(props.value);
        const getMeasureUnit = (item) => {
            if (!item.beer_cider_type) {
                return item.measure_unit;
            }
            let m = '';
            if (item.measure_unit) {
                m += item.measure_unit;
            }
            if (item.measure_unit_2) {
                m += ', ';
                m += item.measure_unit_2;
            }
            if (item.measure_unit_3) {
                m += ', ';
                m += item.measure_unit_3;
            }
            return m;
        }
        const item = props.value;
        return (
            <li onClick={() => {
            }} className={'row'} style={{
                left: `${sidebarWidth + 50}px`,
                display: item.beer_cider_type && toggleFilter || !toggleFilter ? 'flex' : 'none'
            }}>
                <div className={'row-name'}>{parseMapCaretChars(item.name)}</div>
                {/*<div className={'row-beer-cider'}>{parseBeerCiderLabel(item.beer_cider_type)}</div>*/}
                <div className={'row-unit'}>{getMeasureUnit(item)}</div>
                <div className={'row-package'}>{parseMapCaretChars(item.packaging)}</div>
                <div className={'row-alergens'}>{item.allergenes}</div>
                <div onClick={() => navigateAndLoadSelectedProduct(item)} className={'row-edit'}><a
                    className={'a-icon'}>
                    <IconEdit
                        className={'icon'}
                        width={'24px'} height={'24px'}
                        title={'icon list'}/></a></div>
            </li>
        );
    }

    function renderTable(items) {
        const filters = () => {
            return (
                <div className={'search-filters products-list'} style={{left: `${sidebarWidth + 50}px`}}>
                    <Input onSubmit={() => {
                    }}
                           onChange={setNameInput}
                           placeholder={'Naziv ali šifra izdelka'}
                           white
                    />
                    {renderProductTypeFilter()}
                </div>
            );
        };
        const header = () => {
            return (
                <div className={'row-header row-header-has-filter'} style={{left: `${sidebarWidth + 50}px`}}>
                    <div className={'row-name'}>Naziv</div>
                    {/*<div className={'row-beer-cider'}>Tip</div>*/}
                    <div className={'row-unit'}>Enota</div>
                    <div className={'row-package'}>Pakiranje</div>
                    <div className={'row-alergens'}>Alergeni</div>
                    <div className={'row-edit'}>Uredi</div>
                </div>
            );
        };
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul className={'item-list'} style={{left: `${sidebarWidth + 50}px`}}>
                    {items.map((item, index) => <Item key={index} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {filters()}
                {header()}
                {list(items)}
            </>
        );
    }

    const renderModal = () => {
        return (
            modal && <Modal product actionClose={() => {
                setModal(false)
            }}/>
        );
    };
    const renderBandCModal = () => {
        return (
            beerCiderModal && <Modal product beer_cider_type actionClose={() => {
                setBeerCiderModal(false)
            }}/>
        );
    };
    let windowSize = {x: 0, y: document.documentElement.scrollHeight + 100}


    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page className={'main'} style={{height: `${windowSize.y}px`}} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            {/*<Sidebar/>*/}
            {/*<div className={'page'}>*/}
            <Header left={`${sidebarWidth + 50}px`} title={'PREGLED IZDELKOV'} interactive add button={'DODAJ NOV IZDELEK'}
                    action={() => {
                        setModal(true)
                    }}
                /*button2={'DODAJ NOV IZDELEK (PIVSKA KARTA)'} action2={() => {
            setBeerCiderModal(true);
        }}*//>
            <div className={'body body-has-filter'}>
                {renderTable([...products])}
            </div>
            {/*</div>*/}
        </Page>
            {renderModal()}
            {renderBandCModal()}
        </div>
}

export default ProductsList;

