import React, {useState, useEffect} from "react";

import {DatePicker, DatePickerInput} from 'carbon-components-react';
import "../css/DateInput.css"
import {parseDateForDateInput} from "../../helpers/helpers";
import {useSelector} from "react-redux";


function DateInput(props) {
    const [input, setInput] = useState(props.value);
    let activeBar = useSelector(state => state.reducer.activeBar);
    let createMenuActive = useSelector(state => state.reducer.createMenuActive);

    useEffect(() => {
        if (!!createMenuActive && !!activeBar) {
            setInput(createMenuActive?.hasOwnProperty('dateValidFrom') ? createMenuActive.dateValidFrom : createMenuActive?.valid_from);
        }
    }, [activeBar, createMenuActive]);

    const onSelection = (date) => {
        const {onChange} = props;

        let timestamp = new Date(date);
        console.log(date);
        setInput(date);
        onChange(timestamp);
    };

    return (
        <div style={props.style}>
            <p className={'p-title-small'}>{props.label}</p>
            <DatePicker
                locale={'sl'}
                datePickerType="single"
                dateFormat="d/m/Y"
                onChange={onSelection}>
                <DatePickerInput autoComplete="off" id={'date-picker-input'} labelText={''}
                                 placeholder={!input ? "Določi datum od katerega je cenik veljaven" : parseDateForDateInput(input)}/>


            </DatePicker>
        </div>
    );
}

export default DateInput;
