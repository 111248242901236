import React, {useState, useEffect} from "react";

import '../css/LiveDigitalMenu.css';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom'
import {
    loadBar,
    loadBarAds,
    loadBarL10NSupport,
    loadLiveMenu,
    updateAdClickedCounter,
    updateBarQrCodeScanned
} from "../store/actions/actions";
import {
    displayPrice,
    displayValidFromMenuFormat,
    getL10NAlergens,
    getL10NMenuPricesDisclaimer,
    getL10NMenuValidFrom,
    getL10NVat,
    hasItem,
    mapCaretChars,
    parseMapCaretChars,
} from "../helpers/helpers";

import '../assets/fonts/Poppins-Regular.ttf';

import logo from "../assets/img/logo.png";

import countrySI from "../assets/img/country_si.png";
import countryEN from "../assets/img/country_en.png";
import countryDE from "../assets/img/country_de.png";
import countryIT from "../assets/img/country_it.png";
import countryHR from "../assets/img/country_hr.png";

//import barcaffeBcg from "../assets/img/barcaffe/barcaffe-bcg.png";
import barcaffeBcg from "../assets/img/barcaffe/bcg.png";
import barcaffeBcgWhite from "../assets/img/barcaffe-white.jpg";
import barcaffeLogo from "../assets/img/barcaffe/barcaffe.png";
import barcaffeLogoWhite from "../assets/img/barcaffe/barcaffe.png";
import footerBcg from "../assets/img/barcaffe/footer.png";
import barcaffeLogoHorizontal from "../assets/img/barcaffe/barcaffe-horizontal.png";

import iconFood from "../assets/img/barcaffe/i-food.png";
import iconCake from "../assets/img/barcaffe/i-cake.png";
import iconWine from "../assets/img/barcaffe/i-wine.png";
import iconDrink from "../assets/img/barcaffe/i-drink.png";
import iconTea from "../assets/img/barcaffe/i-tea.png";
import iconSnack from "../assets/img/barcaffe/i-snack.png";


import Collapsible from "react-collapsible";
import {IoIosArrowUp, IoIosArrowDown} from 'react-icons/io';
import {LEADING_BRAND} from "../components/js/ButtonGroupSelection";
import {L10N_FIELDS, L10N_MENU} from "../constants/enums";
import {ICONS, ICONS_BlACK} from "./Category";

const DISPLAY_AD_DELAY = 5000;
const DISPLAY_AD_SHOWN = 7000;
const DISPLAY_AD_HIDE_AFTER = DISPLAY_AD_DELAY + DISPLAY_AD_SHOWN;

const shouldDisplayL10NOption = (l10nKey, availableLanguages, override) => {
    if (!override && !availableLanguages || availableLanguages?.length === 0 || (availableLanguages?.length === 1 && (!availableLanguages[0]?.l10n) || availableLanguages[0]?.l10n === L10N_MENU.si.value)) {
        console.log("SHOULD DISPLAY", false, l10nKey, availableLanguages)
        return false;
    }
    let shouldDisplay = l10nKey === L10N_MENU.si.value
    if (shouldDisplay) {
        return true;
    }
    shouldDisplay = !override ? !!hasItem('l10n', 'l10n', l10nKey, availableLanguages) : true;

    console.log("SHOULD DISPLAY", shouldDisplay, l10nKey, availableLanguages)
    return shouldDisplay;
}

const parseSelectedCountryLabel = (country, selectAction, onActionCollapseL10NOptions, availableLanguages, override) => {
    const action = () => {
        selectAction(country)
        onActionCollapseL10NOptions()
    }
    if (!shouldDisplayL10NOption(country, availableLanguages, override)) {
        return <></>
    }

    switch (country) {
        case L10N_MENU.si.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countrySI} alt="SI" width={'25px'}/>
            </div>
        case L10N_MENU.en.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryEN} alt="EN" width={'25px'}/>
            </div>

        case L10N_MENU.de.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryDE} alt="DE" width={'25px'}/>
            </div>
        case L10N_MENU.it.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryIT} alt="IT" width={'25px'}/>
            </div>
        case L10N_MENU.hr.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryHR} alt="HR" width={'25px'}/>
            </div>
    }
}

function LiveDigitalMenu(props) {
    let dispatch = useDispatch();

    const {id} = useParams();
    console.log(id);
    let menu = useSelector(state => state.reducer.onlinePriceList);
    let bar = useSelector(state => state.reducer.onlineBar);
    let adBanners = useSelector(state => state.reducer.onlineBarAds);

    //TESTING MAIN MENU BRAND STYLES

    /*
    if (menu) {
        menu.main_brand = 5;
    }

     */


    const [isL10NOptionsOpen, setIsL10NOptionsOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(L10N_MENU.si.value);
    const [availableLanguages, setAvailableLanguages] = useState(false);
    const [isCategoryOpen, setIsCategoryOpen] = useState([]);
    const [displayAdBanner, setDisplayAdBanner] = useState(false);

    const [barScanned, setBarScanned] = useState(false);

    const dispatchBarL10NSupport = (barId) => {
        dispatch(loadBarL10NSupport(barId, (l10n) => {
            console.log("Live menu l10n loaded..", l10n);
            setAvailableLanguages(l10n);
        }, () => console.warn("Error loading live menu l10n languages support...")));
    };

    const dispatchLoadLiveMenu = () => {
        dispatch(loadLiveMenu(id, () => {
            console.log("Live menu loaded..");
        }, () => console.warn("Error loading live menu...")));
    };

    const dispatchLoadBarData = (barId) => {
        dispatchBarL10NSupport(barId)
        dispatch(loadBar(barId, () => {
            console.log("Bar loaded..");
        }, () => console.warn("Error loading bar...")));
    };

    const dispatchLoadBarAds = (barId) => {
        dispatch(loadBarAds(barId, () => {
            console.log("Bar ads loaded..");
        }, () => console.warn("Error loading bar ads...")));
    };

    useEffect(() => {
        dispatchLoadLiveMenu();
    }, [])
    useEffect(() => {
        if (menu) {
            console.log("ONLINE MENU:", menu)
            dispatchLoadBarData(menu.bar_id);

            if (!barScanned) {
                setBarScanned(true);
                dispatch(updateBarQrCodeScanned(menu.bar_id, (res) => {
                    console.log("Bar qr code scanned updated counter..", res);
                }, () => console.warn("Bar qr code scan update counter failed..., res")));
            }
        }
    }, [menu])
    useEffect(() => {
        if (bar) {
            console.log("BAR:", bar)
            dispatchLoadBarAds(bar.bar_id);
        }
    }, [bar]);

    useEffect(() => {
        if (adBanners) {
            console.log("ADS:", adBanners)
            AdBannersPopupLogic();
        }
    }, [adBanners]);
    useEffect(() => {
        console.log("SELECTED LANGUAGE:", selectedLanguage)
    }, [selectedLanguage])

    const AdBannersPopupLogic = () => {
        setTimeout(() => setDisplayAdBanner(true), DISPLAY_AD_DELAY);
        setTimeout(() => setDisplayAdBanner(false), DISPLAY_AD_HIDE_AFTER);
    };

    const displayCollapsibleArrow = (name, c, status) => {
        let arrowStyle = {width: '24px', height: '24px', color: parseSecondaryColor(menu.main_brand)};
        return (
            <div className={'collapsible-header'}>
                <p className={'online-menu-title-category'}
                   style={{
                       letterSpacing: parseLetterSpacingRegular(menu.main_brand),
                       color: parseSecondaryColor(menu.main_brand),
                       fontFamily: parseFontTitle(menu.main_brand),
                   }}>{parseMapCaretChars(name)}</p>
                <hr className={'online-menu-category-line'}/>
                {/*status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>*/}
            </div>
        );
    }

    const onActionCollapse = (c, state) => {
        let updated = isCategoryOpen;
        updated[c] = state
        console.log(updated)
        setIsCategoryOpen(updated);
    }

    const displayCollapsibleL10NHeader = (status) => {
        let arrowStyle = {width: '24px', height: '24px', color: parseSecondaryColor(menu.main_brand)};
        return (
            <div className={'collapsible-header l10n-options'} style={{justifyContent: 'flex-end'}}>
                {parseSelectedCountryLabel(selectedLanguage, setSelectedLanguage, () => onActionCollapseL10NOptions(false), availableLanguages, true)}
                {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
            </div>
        );
    }

    const onActionCollapseL10NOptions = (state) => {
        setIsL10NOptionsOpen(state);
    }

    const openInNewTab = (url, closeAd) => {
        if (closeAd) {
            setDisplayAdBanner(false);
        }
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const clickOnAd = (product) => {
        openInNewTab(product.link, false)
        dispatch(updateAdClickedCounter(product.product_id, (res) => {
            console.log("Internal Ad counter updated..", res);
        }, () => console.warn("Internal Ad counter updated..., res")));
    }

    const renderAdBanner = () => {
        if (!adBanners || adBanners.length === 0) {
            return null;
        }

        return (
            <Collapsible classParentString={'collapsible-ad-banner'} transitionTime={400}
                         onClosing={() => {
                         }}
                         onOpening={() => {
                         }}
                         open={displayAdBanner}
                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}>

                <div className={'ad-container'} onClick={() => {
                }}>
                    <div onClick={() => openInNewTab(adBanners[0].link, true)}><img className={'banner'}
                                                                                    src={adBanners[0].image} alt="ad"
                                                                                    width={'100%'}/></div>
                </div>
            </Collapsible>
        );
    };

    const renderHeader = () => {
        return (
            <div className={'logo-row'} style={{alignSelf: 'center', height: 'fit-content', padding: 0}}>
                <img src={parseLogo(menu.main_brand)} style={{display: 'none'}} alt="logo"/>
                <div className={'logo-row-inner'}>
                    <img src={bar?.logo ? logo : logo} alt="bar logo"/>
                </div>
            </div>
        );
    };

    function scrollToDiv(div) {
        let id = 'c' + div;
        const divElement = document.getElementById(id);

        const yOffset = -40;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    }

    const renderCategories = () => {
        if (!menu?.categories) {
            return null;
        }
        return (
            <div className={'categories-horizontal'} style={{alignSelf: 'center', height: 'fit-content', padding: 0}}>
                {menu.categories.map((category, c) => (
                    <div key={`${c}_${category}`} id={`${category.category.menu_group_id}`}
                         className={'category-outer-container'}
                         onClick={() => scrollToDiv(category.category.menu_group_id)}>
                        <div className={'category-container'}>
                            <img className={'category-item'}
                                 src={parseCategoryIcon(category.category?.icon, menu.main_brand)}
                                 alt="logo"/>
                        </div>
                        <p className={'online-menu-title-product'} style={{
                            textAlign: 'center',
                            lineHeight: '13px',
                            padding: '8px',
                            letterSpacing: parseLetterSpacingRegular(menu.main_brand),
                            fontSize: parseFontSizeRegular(menu.main_brand),
                            fontFamily: parseFontTitleProd(menu.main_brand),
                            color: parsePrimaryColor(menu.main_brand)
                        }}>{parseProductField(selectedLanguage, L10N_FIELDS.name.value, category.category) ? parseMapCaretChars(parseProductField(selectedLanguage, L10N_FIELDS.name.value, category.category)) : 'MANJKA PREVOD'}</p>
                    </div>
                ))}
            </div>
        );
    };


    const renderFooter = () => {
        if (!bar) {
            return null;
        }
        return (
            <div className={'footer-digital-menu'}>
                <div className={'logo-row'}>
                    <img src={bar?.logo ? logo : logo} alt="bar logo"/>
                </div>
                <div>
                    <p className={'online-menu-text-footer alergens'}
                       style={{
                           fontFamily: parseFontLight(menu.main_brand),
                           color: '#fff'
                       }}>{getL10NAlergens(selectedLanguage)}</p>
                    <p className={'online-menu-text-footer bar-info'}
                       style={{fontFamily: parseFontLight(menu.main_brand)}}>{`${parseMapCaretChars(bar.name)} | ${parseMapCaretChars(bar.address)} | ${getL10NVat(selectedLanguage)} ${parseMapCaretChars(bar.tax_number)} | ${getL10NMenuPricesDisclaimer(selectedLanguage)} ${getL10NMenuValidFrom(selectedLanguage)} ${displayValidFromMenuFormat(menu.valid_from)}`}</p>
                </div>
            </div>
        );
    };

    const parseBackgroundColor = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
                return 'rgba(23, 34, 41, 0.95)'
            case LEADING_BRAND.BARCAFFE_WHITE:
                return '#FFF';
        }
    };
    const parsePrimaryColor = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE_WHITE:
                return '#333F48';
            case LEADING_BRAND.BARCAFFE:
                return '#fff';
        }
    };
    const parseSecondaryColor = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
                return '#fff';
            case LEADING_BRAND.BARCAFFE_WHITE:
                return '#333F48';
        }
    };
    const parseSecondaryFontWeight = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
                return 'normal';
            case LEADING_BRAND.BARCAFFE_WHITE:
                return '600';
        }
    };

    const parseBackground = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
                return barcaffeBcgWhite;
            case LEADING_BRAND.BARCAFFE_WHITE:
                return barcaffeBcgWhite;
        }
    };
    const parseLogo = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
                return barcaffeLogoWhite;
            case LEADING_BRAND.BARCAFFE_WHITE:
                return barcaffeLogo;
        }
    };

    const parseCategoryIcon = (i, menuType) => {
        const parseIconSet = (menuType) => {
            switch (Number(menuType)) {
                case LEADING_BRAND.BARCAFFE_WHITE:
                    return ICONS_BlACK
                case LEADING_BRAND.BARCAFFE:
                default:
                    return ICONS;
            }
        }
        if (!i && (i !== 0 || i !== '0')) {
            return null;
        } else {
            i = Number(i);
        }
        let icons = parseIconSet(menuType)
        return icons[i];
    };

    const parseFontTitle = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return 'Poppins-Bold';
        }
    };
    const parseFontTitleProd = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return 'Poppins';
        }
    };
    const parseFontLight = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return 'Poppins-Thin';
        }
    };
    const parseFontPrice = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return 'Poppins';
        }
    };
    const parseFontUnit = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return 'Poppins';
        }
    };
    const parseFontSizeRegular = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return '12px';
        }
    };
    const parseFontSizeInfo = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return '11px';
        }
    };
    const parseLetterSpacingRegular = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return '1.2px';
        }
    };
    const parseLetterSpacingTight = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return '0.85px';
        }
    };
    const parseLineHeight = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE:
            case LEADING_BRAND.BARCAFFE_WHITE:
                return '12px';
        }
    };

    const parseBodyPadding = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE_WHITE:
            case LEADING_BRAND.BARCAFFE:
                return '27px';
        }
    }

    const parseBackgroundStyle = (brand) => {
        switch (brand) {
            case LEADING_BRAND.BARCAFFE_WHITE:
                return {
                    //backgroundColor: parseBackgroundColor(brand),
                    backgroundImage: `url(${parseBackground(brand)})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'repeat-y',
                    backgroundPositionY: 'top',
                    backgroundPositionX: 'right'
                };
            case LEADING_BRAND.BARCAFFE:
                return {
                    // backgroundColor: parseBackgroundColor(brand),
                    backgroundImage: `url(${parseBackground(brand)})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'repeat-y',
                    backgroundPositionY: 'center',
                    backgroundPositionX: 'center'
                };
        }
    };

    const isProductFood = (name) => {
        let n = parseMapCaretChars(name);
        n = n.toLowerCase();
        if (n === 'hrana' || n === 'prigrizki' || n === 'sladice' || n === 'jedi') {
            console.log()
            return true;
        }
    }

    const parseProductField = (l10n, field, product) => {
        let l10nKey = l10n.toLowerCase() + '_' + field;
        if (l10n === L10N_MENU.si.value && field === L10N_FIELDS.name.value) {
            l10nKey = 'name';
        } else if (l10n === L10N_MENU.si.value && field === L10N_FIELDS.description.value) {
            l10nKey = 'slo' + '_' + field;
        } else if (l10n === L10N_MENU.en.value) {
            l10nKey = 'eng' + '_' + field;
        } else {
            console.warn("No l10n key found:", l10n, field, product)
            return null;
        }
        if (!product[l10nKey] || product[l10nKey] === '' || product[l10nKey] === ' ' || product[l10nKey] === '/') {
            return null;
        }
        return product[l10nKey];
    }

    const renderL10NOption = (l10nKey, imgData) => {
        if (!shouldDisplayL10NOption(l10nKey, availableLanguages, false)) {
            return <></>;
        }
        return (
            <div onClick={() => {
                onActionCollapseL10NOptions(false)
                setSelectedLanguage(l10nKey)
            }}
                 className={'country-label-container'}
                 style={{alignSelf: 'center'}}>
                <img src={imgData} alt={l10nKey} width={'25px'}/>
            </div>
        );
    }

    const renderL10NOptions = () => {
        if (!availableLanguages || (availableLanguages?.length === 1 && (!availableLanguages[0]?.l10n || availableLanguages[0]?.l10n === 'SI')) || availableLanguages.length === 2 && (availableLanguages[0]?.l10n === 'SI' && !availableLanguages[1]?.l10n)) {
            return <></>;
        }

        return (
            <Collapsible className={'collapsible-menu-listing l10-options'}
                         transitionTime={400}
                         onClosing={() => onActionCollapseL10NOptions(false)}
                         onOpening={() => onActionCollapseL10NOptions(true)}
                         open={isL10NOptionsOpen}
                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                         trigger={displayCollapsibleL10NHeader(false)}
                         triggerWhenOpen={displayCollapsibleL10NHeader(true)}>
                <div className={'l10n-options-content'}>
                    {renderL10NOption(L10N_MENU.si.value, countrySI)}
                    {renderL10NOption(L10N_MENU.en.value, countryEN)}
                    {renderL10NOption(L10N_MENU.de.value, countryDE)}
                    {renderL10NOption(L10N_MENU.it.value, countryIT)}
                    {renderL10NOption(L10N_MENU.hr.value, countryHR)}
                </div>
            </Collapsible>
        );
    }

    const renderProductRow = (menu, p, product, category) => {
        let startHours = product.happy_hour_time_start ? Number(product.happy_hour_time_start.split(':')[0]) : null;
        let startMinutes = product.happy_hour_time_start ? Number(product.happy_hour_time_start.split(':')[1]) : null;
        let endHours = product.happy_hour_time_end ? Number(product.happy_hour_time_end.split(':')[0]) : null;
        let endMinutes = product.happy_hour_time_end ? Number(product.happy_hour_time_end.split(':')[1]) : null;

        let shouldDisplayHappyHour = product.happy_hour;
        shouldDisplayHappyHour = shouldDisplayHappyHour && startHours <= new Date().getHours() && new Date().getHours() <= endHours;


        console.log(startHours, startMinutes, endHours, endMinutes, new Date().getHours(), new Date().getMinutes())

        if (shouldDisplayHappyHour && startHours === new Date().getHours()) {
            shouldDisplayHappyHour = startMinutes <= new Date().getMinutes();
        }
        if (shouldDisplayHappyHour && endHours === new Date().getHours()) {
            shouldDisplayHappyHour = endMinutes >= new Date().getMinutes();
        }

        const dayMap = [
            {
                day: product.happy_hour_sun,
                dayParsedMatch: 0
            }, {
                day: product.happy_hour_mon,
                dayParsedMatch: 1
            }, {
                day: product.happy_hour_tue,
                dayParsedMatch: 2
            }, {
                day: product.happy_hour_wed,
                dayParsedMatch: 3
            }, {
                day: product.happy_hour_thu,
                dayParsedMatch: 4
            }, {
                day: product.happy_hour_fri,
                dayParsedMatch: 5
            }, {
                day: product.happy_hour_sat,
                dayParsedMatch: 6
            }];

        console.log("DAY", new Date().getDay());
        const day = new Date().getDay();
        if (!dayMap[day].day) {
            shouldDisplayHappyHour = false;
        }


        if (product.happy_hour && !shouldDisplayHappyHour) {
            if (!product.happy_hour_show_always) {
                return <></>;
            }
        }

        let productPrice = product.initial_price;
        if (product.happy_hour) {
            if (shouldDisplayHappyHour) {
                productPrice = product.happy_hour_price;
            }
        }
        let className = '';
        if (product.happy_hour && shouldDisplayHappyHour) {
            className = `online-menu-item-product with-label`;
        }
        else {
            className = `online-menu-item-product ${product.status ? 'with-label' : 'no-label'}`
        }


        return <div key={`${p}_${product}`}
                    className={className}>
            <div className={'online-menu-item-product-container'}>
                <div className={'online-menu-item-product-left'}>
                    <div className={'online-menu-item-product-left-row'}>
                        <p className={'online-menu-title-product'} style={{
                            letterSpacing: parseLetterSpacingRegular(menu.main_brand),
                            fontSize: parseFontSizeRegular(menu.main_brand),
                            fontFamily: parseFontTitleProd(menu.main_brand),
                            color: parsePrimaryColor(menu.main_brand)
                        }}>{parseMapCaretChars(parseProductField(selectedLanguage, L10N_FIELDS.name.value, product) ? parseProductField(selectedLanguage, L10N_FIELDS.name.value, product) : 'MANJKA PREVOD')}</p>
                        <p className={'online-menu-alergens-product'} style={{
                            letterSpacing: parseLetterSpacingTight(menu.main_brand),
                            fontFamily: parseFontLight(menu.main_brand),
                            color: parsePrimaryColor(menu.main_brand),
                            fontWeight: parseSecondaryFontWeight(menu.main_brand)
                        }}>{product.allergenes}</p>
                        <p className={'online-menu-unit-product'} style={{
                            letterSpacing: parseLetterSpacingTight(menu.main_brand),
                            fontFamily: parseFontLight(menu.main_brand),
                            color: parsePrimaryColor(menu.main_brand),
                            fontWeight: parseSecondaryFontWeight(menu.main_brand)
                        }}>{!isProductFood(category.category.name) ? (product.measure_unit !== '' && product.measure_unit !== '/') ? product.measure_unit : '' : ''}</p>
                    </div>
                    <p className={'online-menu-unit-product'} style={{
                        letterSpacing: parseLetterSpacingTight(menu.main_brand),
                        lineHeight: parseLineHeight(menu.main_brand),
                        fontFamily: parseFontLight(menu.main_brand),
                        fontSize: parseFontSizeInfo(menu.main_brand),
                        color: parsePrimaryColor(menu.main_brand),
                        fontWeight: parseSecondaryFontWeight(menu.main_brand)
                    }}>{parseMapCaretChars(parseProductField(selectedLanguage, L10N_FIELDS.description.value, product))}</p>
                </div>
                <div className={'online-menu-item-product-right'}>
                    <p className={'online-menu-price-product'} style={{
                        letterSpacing: parseLetterSpacingRegular(menu.main_brand),
                        fontSize: parseFontSizeRegular(menu.main_brand),
                        fontFamily: parseFontPrice(menu.main_brand),
                        color: parsePrimaryColor(menu.main_brand)
                    }}>{displayPrice(productPrice)} €</p>
                </div>
            </div>
            {(product.status || (product.happy_hour && shouldDisplayHappyHour)) &&
            <div className={'online-menu-item-label'}>{product.happy_hour ? "HAPPY HOUR" : product.status}</div>}
        </div>
    }

    const renderComboOfferRow = (menu, p, product, category) => {
        return <div key={`${p}_${product}`}
                    className={'online-menu-item-product combo-offer no-label'}>
            <div onClick={() => clickOnAd(product)}
                 className={'country-label-container'}
                 style={{alignSelf: 'center', width: '100%'}}>
                <img src={product.picture} alt="SI" width={'100%'}/>
            </div>
        </div>
    }

    const renderMenu = () => {
        return (
            menu.categories.map((category, c) => (
                <Collapsible key={`${c}_${category}`} className={'collapsible-menu-listing'} transitionTime={400}
                             onClosing={() => onActionCollapse(c, false)}
                             onOpening={() => onActionCollapse(c, true)}
                             open={Number(c) < 3}
                             easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                             trigger={displayCollapsibleArrow(parseProductField(selectedLanguage, L10N_FIELDS.name.value, category.category) ? parseProductField(selectedLanguage, L10N_FIELDS.name.value, category.category) : 'MANJKA PREVOD', c, false)}
                             triggerWhenOpen={displayCollapsibleArrow(parseProductField(selectedLanguage, L10N_FIELDS.name.value, category.category) ? parseProductField(selectedLanguage, L10N_FIELDS.name.value, category.category) : 'MANJKA PREVOD', c, true)}>
                    <div id={`c${category.category.menu_group_id}`} className={'online-menu-category'}>

                        <div className={'online-menu-item-category'}>
                            {category.products.map((product, p) => (
                                !product?.combo_offer ? renderProductRow(menu, p, product, category) : renderComboOfferRow(menu, p, product, category)
                            ))}
                        </div>
                    </div>
                </Collapsible>
            ))
        );
    };
    if (!menu || !menu.categories) {
        return <></>;
    }

    let className = menu.main_brand === LEADING_BRAND.BARCAFFE ? 'template-0' : 'template-1'
    className = `${className}`
    return <div className={`main ${className}`}
                style={parseBackgroundStyle(menu.main_brand)}>
        <div className={`${className}`} style={parseBackgroundStyle(menu.main_brand)}/>
        <div className={'page digital-menu'} style={{height: 'fit-content'}}>
            <div className={'body'} style={{
                paddingLeft: parseBodyPadding(menu.main_brand),
                paddingRight: 0
            }}>
                {/*renderL10NOptions()*/}
                {renderAdBanner()}
                {renderHeader()}
                {renderCategories()}
                {renderMenu()}
                {renderFooter()}
            </div>
        </div>
    </div>
}

export default LiveDigitalMenu;